import React, { useEffect, useRef, useState } from "react";
import swal from "sweetalert";
// import swal from '@sweetalert/with-react';

import { Loader } from "@googlemaps/js-api-loader";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import axios from "axios";
import "../WriteMessage/WriteMessage.css";
import Alert from "react-bootstrap/Alert";

import send from "../../../Assets/Images/SEND.png";

import { makeStyles } from "@mui/styles";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";

const useStyles = makeStyles((theme) => ({
  phoneInputContainer: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    borderRadius: "4px",
    border: "1px solid #D2D2D2",
    "&:hover": {
      borderColor: "#2B2B2B",
    },
    "&.Mui-focused": {
      borderColor: "#2B2B2B",
      boxShadow: "0 0 0 2px rgba(43, 43, 43, 0.2)",
    },
  },
  phoneInput: {
    width: "100%",
    height: "40px",
    fontSize: "14px",
    padding: "28px 50px !important",
    fontWeight: 500,
    lineHeight: 1.2,
    fontSize: "15px !important",
    borderTop: 0 + "!important",
    borderBottom: 2 + "!important",
    borderBottom: "1px solid #8f9194 !important",
    borderRight: 0 + "!important",
    borderLeft: 0 + "!important",
    borderRadius: 0 + "important",
    color: "#3263b1 !important",
    backgroundColor: "#f7faff !important",
    border: "none",
    outline: "none",
    "&::placeholder": {
      color: "#9B9B9B",
      opacity: 1,
    },
  },
  countrySelect: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    background: "#f7faff !important",
    borderTop: 0 + "!important",
    borderBottom: "1px solid #8f9194 !important",
    borderRight: 0 + "!important",
    borderLeft: 0 + "!important",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f7faff !important",
    },
  },
}));

export default function WriteMessage() {
  const [map, setMap] = useState(null);
  const [MobileNo, setMobileNo] = useState("");
  const mapContainerRef = useRef(null);
  const [fullName, setfullName] = useState("");
  const [email, setEmail] = useState("");
  // const[mobileNo,setMbileNo]=useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [validEmail, setValidemail] = useState();
  const [mobileError, setMobileError] = useState("");
  const [defaultCountry, setDefaultCountry] = useState("");
  const [show, setShow] = useState(true);

  const classes = useStyles();
  const iframeRef = useRef(null);

  const handleMobileChange = (value) => {
    if (value.length === 0) {
      setMobileError("Mobile No. is required");
    } else {
      setMobileError("");
    }
    setMobileNo(value);
  };

  const submitConatctUs = () => {
    var reg = /\S+@\S+\.\S+/;
    if (!fullName) {
      setError(true);
    } else if (!email) {
      setError(true);
    } else if (!reg.test(email)) {
      setError(true);
    } else if (!MobileNo) {
      setError(true);
    } else if (!subject) {
      setError(true);
    } else if (!message) {
      setError(true);
    } else {
      const details = {
        email,
        mobileNo: MobileNo,
        fullName,
        subject,
        message,
      };
      console.log(details);
      axios
        .post(process.env.REACT_APP_SERVER_URL + "/api/contactUs", details)
        .then(async (res) => {
          var data = await res.data;
          if (data.statuscode == 200) {
            swal({
              text: "Contact-Us Saved Successfully",
              icon: "success",
              button: "ok",
            });
            setfullName("");
            setEmail("");
            setMobileNo("");
            setMessage("");
            setSubject("");
          }
        })
        .catch((error) => {
          console.log(error);
          swal("Error");
        });
    }
  };

  useEffect(() => {
    // const iframe = iframeRef.current;
    // iframe.addEventListener('load', function() {
    //   const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    //   iframeDoc.body.style.backgroundColor = '#fff';
    // });

    const loader = new Loader({
      apiKey: "AIzaSyB3vI4IQqzxRU1449lbIHThUb2gCeDW47w",
      version: "weekly",
    });

    loader.load().then(() => {
      const google = window.google;
      const map = new google.maps.Map(mapContainerRef.current, {
        center: { lat: 37.7749, lng: -122.4194 },
        zoom: 8,
      });

      setMap(map);
    });
    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => setDefaultCountry(data.country_code))
      .catch((error) => console.log(error));
  }, []);

  console.log(defaultCountry);
  return (
    <div className="contactus bg-white">
      <Container>
        <Row>
          <div className="headerText row mt-5 ">
            <h3>Get In Touch</h3>
            <span className="text-center my-5 ">
              We would love to hear from you! If you have any questions, or
              feedback, or simply want to get in touch,<br></br> please feel
              free to reach out to us using the contact information provided
              below. Our team is here to assist you.
            </span>
          </div>

          <Col className="d-flex row justify-content-between mb-5 pt-2">
            <Col className="contactcard d-flex col-lg-6 col-md-6 col-sm-12 col-12 py-3">
              <Col className="col-2 py-3 contactChild">
                <div className="icongrid d-flex flex-column pt-5 mt-5">
                  <i className="fa fa-map-marker markerContact text-white pb-5"></i>
                  <i className="fa fa-envelope markerContact text-white pb-5"></i>
                  <i className="fa fa-phone markerContact text-white pb-4"></i>
                  <i className="fa fa-mobile-phone markerContact text-white pt-4"></i>
                </div>
              </Col>
              <Col className="col-10 py-3 BackgroundInfo">
                <h2 className="text-start text-white ps-4 pt-3">SYDNEY</h2>
                <div className="pt-2 mt-3 pe-3">
                  <h4 className="text-start text-white ps-4 pt-3">
                    Suite 1105 Level 11 370 Pitt Street, Sydney, NSW, 2000
                  </h4>
                  <h4 className="text-start text-white ps-4 pt-4">
                    support@focusvisa.com
                  </h4>
                  <h4 className="text-start text-white ps-4 mt-5 pt-1">
                    0272516252
                  </h4>
                  <h4 className="text-start text-white mt-5 pt-1 ps-4">
                    1800005342
                  </h4>
                </div>
              </Col>
            </Col>

            <Col className="contactcard d-flex col-lg-6 col-md-6 col-sm-12 col-12 py-3">
              <Col className="col-2 py-3 contactChild">
                <div className="icongrid d-flex flex-column pt-5 mt-5">
                  <i className="fa fa-map-marker markerContact text-white pb-5"></i>
                  <i className="fa fa-envelope markerContact text-white pb-5"></i>
                  <i className="fa fa-phone markerContact text-white pb-4"></i>
                  <i className="fa fa-mobile-phone markerContact text-white pt-4"></i>
                </div>
              </Col>
              <Col className="col-10 py-3 BackgroundInfo">
                <h2 className="text-start text-white ps-4 pt-3">CANBERRA</h2>
                <div className="description2 pt-2 mt-3 pe-3">
                  <h4 className="text-start text-white ps-4 pt-3">
                    87 Anthony Rolfe Ave, Gungahlin, ACT, 2912
                  </h4>
                  <h4 className="text-start text-white ps-4 pt-4">
                    support@focusvisa.com
                  </h4>
                  <h4 className="text-start text-white mt-5 pt-1 ps-4">
                    0261112905
                  </h4>
                  <h4 className="text-start text-white mt-5 pt-1 ps-4">
                    1800005342
                  </h4>
                </div>
              </Col>
            </Col>
          </Col>

          {/* <Col className="d-flex row align-items-center my-5 pt-2">
            <Alert variant="info" className="py-4" onClose={() => setShow(false)} dismissible>
              <Alert.Heading className="py-3">Disclaimer</Alert.Heading>
              <p className="justifyText d-flex">
                <span className="tick pe-2">✓</span> This email and any
                attachments transmitted with it are confidential and intended
                solely for the use of the individual or entity to whom they are
                addressed. If you have received this email in error, please
                notify the sender immediately and delete the message from your
                system. Any unauthorized dissemination, distribution, or copying
                of this communication is strictly prohibited.
              </p>
              <p className="justifyText d-flex">
                <span className="tick pe-2">✓</span> The information contained
                in this email and its attachments is for general informational
                purposes only and does not constitute legal, financial, or
                professional advice. While every effort has been made to ensure
                the accuracy and completeness of the information provided, we
                make no warranties or representations, express or implied,
                regarding the accuracy, reliability, or suitability of the
                contents.
              </p>
              <p className="justifyText d-flex">
                <span className="tick pe-2">✓</span> The views and opinions
                expressed in this email are those of the sender and do not
                necessarily reflect the official policy or position of our
                organization. Any actions taken based on the information
                provided in this email are at your own risk.
              </p>
              <p className="justifyText d-flex">
                <span className="tick pe-2">✓</span> We assume no liability for
                any loss or damage arising from the use of this email or its
                attachments, including but not limited to, computer viruses,
                data corruption, or any other consequential or incidental
                damages.
              </p>
              <p className="justifyText d-flex">
                <span className="tick pe-2">✓</span> Please be aware that email
                communication may not be secure, and we do not guarantee the
                confidentiality or security of any information transmitted
                through email. We recommend that you exercise caution and use
                appropriate measures to protect sensitive or confidential
                information.
              </p>
              <p className="justifyText d-flex">
                <span className="tick pe-2">✓</span> If you require specific
                advice or assistance, please consult with a qualified
                professional or contact the appropriate department within our
                organization.
              </p>
              <hr />
              <p className="text-center bolder d-flex justify-content-center mb-0">
 Thank you for your
                understanding and cooperation.
              </p>
            </Alert>
          </Col> */}

          <Col className="col-lg-12 my-5">
            <div className="headerText pb-5">
              <h2>Write A Message</h2>
            </div>
            <iframe
              ref={iframeRef}
              className="nirav"
              src="https://focusvisa.agentcisapp.com/online-form/website-enquiries"
              height="1150"
              frameBorder="0"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
