import React from 'react'
import Navbar from '../Components/Layout/NavBar/NavBar'
import Footer from '../Components/Layout/Footer/Footer'
import BookAnAppointment from '../Components/BookAnAppointmentPage/BookAnAppointmentPage'
import LifeInAustraliaBanner from '../Components/LifeInAustraliaPage/LifeInAustraliaBanner/LifeInAustraliaBanner'
import LifeInAustraliaPage from '../Components/LifeInAustraliaPage/LifeInAustraliaPage'
import FooterVideo from '../Components/Layout/FooterVideo/FooterVideo'

export default function LifeInAustralia() {
  return (
    <>
      <Navbar/>
      <LifeInAustraliaBanner/>
      <LifeInAustraliaPage/>
      <FooterVideo/>

      <Footer/>
    </>
  )
}
