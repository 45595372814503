import React from "react";
import { Container, Row,Card,Col,Image } from "react-bootstrap";
import vision from '../../../Assets/Images/vision.jpg'
import Mission from '../../../Assets/Images/Mission.jpg'
import objective from '../../../Assets/Images/objective.png'
import News1 from '../../../Assets/Images/lifeInAustralia.png'
import News2 from '../../../Assets/Images/au2.png'
import News3 from '../../../Assets/Images/News-3.png'
import { useHistory } from "react-router-dom";
import './BlogsDetailsMessage.css'
import LifeInAustraliaPage from "../../LifeInAustraliaPage/LifeInAustraliaPage";

const BlogsDetailsMessage = () => {
  let history = useHistory();

  const BlogsDetailsPage = () => {
    history.push("/blogsdetails");
    window.scrollTo(0, 0);
  };

  const BlogsDetailsPageExpense = () => {
    history.push("/blogsdetailsexpense");
    window.scrollTo(0, 0);
  };
  return (
    <div className="bg-white">
        <Container className="pb-5 pt-5">
        <Row className="mt-5 responsivePadding">
            <Col className='col-lg-8 '>
                <Card className='border-0 mt-0 '>
                        <Image src={News1}></Image>
                        <div className="innerCardBlogsa py-4">
                        <span className='innerClipColor text-start pb-2 mt-3 d-flex responsivePadding'>
                            <small className=''><small className=' innerClipColor'><i className="far fa-comments innerClipColor pe-2"/>  Comments</small></small>
                            <small className='px-1'><small className='  ps-2 innerClipColor'><i className="far fa-user innerClipColor pe-2"/>  Admin</small></small>
                            <small className='px-1'><small className='  ps-2 innerClipColor'><i className="fa fa-calendar  innerClipColor pe-1"/>  12 Apr 2023</small></small>

                        </span>
                        <span>
                        
                        </span>
                    <Card.Title className='bolder text-start py-3 pb-0 mt-3 responsivePadding' >

                    <h4>Life in Australia</h4> 
                    </Card.Title>
                    <Card.Text className='textColorDefault text-start pb-3'>
                      <hr></hr>
                      <LifeInAustraliaPage></LifeInAustraliaPage>


                    </Card.Text>
                        </div>
                 
                </Card>
            
            </Col>
            <Col className="col-lg-4">
              <h4 className="text-start pb-5 mb-0"> Recent Blogs</h4>
              <div className="innerDetailsSec">
                  <div className="igSec d-flex align-items-start">
                    <img src={News1} height="150" width="200"/>
                    <div className="d-flex px-3 flex-column justify-content-start align-items-start pointer"  onClick={BlogsDetailsPage}>
                    <small className='mt-3 '><small className='innerClipColor'><i className="fa fa-calendar  innerClipColor pe-1"/>  12 Apr 2023</small></small>
                            <span className="text-black text-start font-weight-bold pt-3">
                            Life in Australia
                            </span>

                    </div>
                  </div>
              </div>
              <div className="innerDetailsSec mt-4">
                  <div className="igSec d-flex align-items-start">
                    <img src={News2} height="162" width="200"/>
                    <div className="d-flex px-3 flex-column justify-content-start align-items-start pointer" onClick={BlogsDetailsPageExpense}>
                    <small className='mt-3 '><small className='innerClipColor'><i className="fa fa-calendar  innerClipColor pe-1"/>  12 Apr 2023</small></small>
                            <span className="text-black text-start font-weight-bold pt-3">
                            Living Expanses in Australia
                            </span>

                    </div>
                  </div>
              </div>
              {/* <div className="innerDetailsSec mt-4">
                  <div className="igSec d-flex align-items-start">
                    <img src={News1} height="150" width="200"/>
                    <div className="d-flex px-3 flex-column justify-content-start align-items-start">
                    <small className='mt-3 '><small className='innerClipColor'><i className="fa fa-calendar  innerClipColor pe-1"/>  12 Apr 2023</small></small>
                            <span className="text-black text-start font-weight-bold pt-3">
                            Employment Insurance for Foreign Nationals
                            </span>

                    </div>
                  </div>
              </div>
              <div className="innerDetailsSec mt-4">
                  <div className="igSec d-flex align-items-start">
                    <img src={News3} height="150" width="200"/>
                    <div className="d-flex px-3 flex-column justify-content-start align-items-start">
                    <small className='mt-3 '><small className='innerClipColor'><i className="fa fa-calendar  innerClipColor pe-1"/>  12 Apr 2023</small></small>
                            <span className="text-black text-start font-weight-bold pt-3">
                            You Will Become As lor Small And Your and Desire.
                            </span>

                    </div>
                  </div>
              </div> */}
            </Col>
        </Row>

     
      </Container>
    </div>
  );
};

export default BlogsDetailsMessage;
