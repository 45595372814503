import React from "react";
import Navbar from "../Components/Layout/NavBar/NavBar";
import Footer from "../Components/Layout/Footer/Footer";
import Banner from "../Components/AboutUsPage/AboutUs_Banner/Banner";
import AboutUs_Msg from "../Components/AboutUsPage/AboutUs_Msg/AboutUs_Msg";
import FAQBanner from "../Components/FAQPage/FAQBanner/FAQBanner";
import FAQMessage from "../Components/FAQPage/FAQMessage/FAQMessage";
import FooterVideo from "../Components/Layout/FooterVideo/FooterVideo";
import PointsCalculatorBannerMessage from "../Components/PointsCalculatorPage/PointsCalculatorBannerMessage/PointsCalculatorBannerMessage";
import PointsCalculatorBanner from "../Components/PointsCalculatorPage/PointsCalculatorBanner/PointsCalculatorBanner";

const  PointsCalculator = () => {
  return (
    <div>
      <Navbar></Navbar>
      <PointsCalculatorBanner></PointsCalculatorBanner>
      <PointsCalculatorBannerMessage></PointsCalculatorBannerMessage>
      <FooterVideo/>
      <Footer></Footer>
    </div>
  );
};

export default PointsCalculator;
