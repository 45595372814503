import React from 'react'
import './BlogsBanner.css'
import AboutusBanner from '../../../Assets/Images/blogsBanner.jpg'

import { Card, Col, Container, Row } from 'react-bootstrap'

export default function BlogsBanner() {
  return (
    <section className='servicesbanner'>
      
      <img src={AboutusBanner} className='img-fluid banner_img'/>
      
      <Container fluid className='banner_container'>
        <h1 className='calcPoint'>BLOGS </h1>
      </Container>
    </section>
  )
}
