import React from 'react';
import './Footer.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Focusvisalogo from '../../../Assets/Images/Focus_Visa_footer_logo.png'
import { Image } from 'react-bootstrap';
import facebook from '../../../Assets/Images/Footer-Facebook-icon.png'
import tweeter from '../../../Assets/Images/Footer-Twitter-icon.png'
import google from '../../../Assets/Images/Footer-Google+-icon.png'
import legalremit from '../../../Assets/Images/Footer-lr-icon.png'
import tiktok from '../../../Assets/Images/Footer-Tiktok-icon.png'
import insta from '../../../Assets/Images/instagram.png'
import youtube from '../../../Assets/Images/Youtube.png'
import linkedin from '../../../Assets/Images/Linked In.png'

import { useHistory } from "react-router-dom";


export default function Footer({ children }) {

  let history = useHistory();


  const ServicePage = () => {
    history.push("/services");
    window.scrollTo(0, 0)
  }

  const FAQPage = () => {
    history.push("/FAQ");
    window.scrollTo(0, 0)

    console.log('object',    window.scrollTo(0, 0));

  }

  const AboutUsPage = () => {
    history.push("/aboutus");
    window.scrollTo(0, 0)
  }
  const openFacebook = () => {
    window.open("https://www.facebook.com/FocusVisaEducation")
    // props.FirstStepData(agent)
    // props.handleNext()
  }

  const openGlobalVisa = () => {
    history.push("/globalvisaprocessingtime");
    window.scrollTo(0, 0)
    // window.open("https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-processing-times/global-visa-processing-times")
    // props.FirstStepData(agent)
    // props.handleNext()
  }
  const openOnlineForms = () => {
    window.open("https://immi.homeaffairs.gov.au/help-support/departmental-forms/pdf-forms")
    // props.FirstStepData(agent)
    // props.handleNext()
  }

  const openVisaFees = () => {
    history.push("/visapricingestimator");
    window.scrollTo(0, 0)

    // window.open("https://immi.homeaffairs.gov.au/visas/visa-pricing-estimator")
    // props.FirstStepData(agent)
    // props.handleNext()
  }

  const ContactUsPage = () => {
    history.push("/ContactUs");
    window.scrollTo(0, 0)
  }

  const PointsCalculatorPage = () => {
    history.push("/pointscalculator");
    window.scrollTo(0, 0)
  }

  const BlogsPage = () => {
    history.push("/blogs");
    window.scrollTo(0, 0)
  }


  const home = () => {
    history.push("/");
    window.scrollTo(0, 0)
  }


  const VisaProcess = () => {
    history.push("/visaprocess");
    window.scrollTo(0, 0)
  }

  const MigrationProcess = () => {
    history.push("/migrationprocess");
    window.scrollTo(0, 0)
  }

  const CareerConsultation = () => {
    history.push("/careerconsultation");
    window.scrollTo(0, 0)
  }





  return (
    <>
      <div className='footer BackgroundInfo'>
        <footer>
          <Container>
            <Row className="m-0 flexColumn ">
              <Col className="col-lg-6 my-5 ">
                <img src={Focusvisalogo} onClick={home} className="FooterLogo img-fluid me-auto me-1 d-block pointer" alt="focus visa logo"></img>
                <p className='footerdescription my-3 text-white justifyText'>Unlock your dreams and swift visa approval with the best education and migration service in town. Your Trusted Gateway to Global Success! Focus Visa</p>
                {/* <p className='w-50 my-3 text-white text-start'>Your Trusted Partner in Education, Visa and Migration Services</p> */}

                <div className='text-start me-3'>
                  <Image src={facebook} className='me-4 pointer' onClick={openFacebook}></Image>
                  <Image src={tweeter} className='me-4'></Image>
                  <Image src={tiktok} className='me-4'></Image>
                  <Image src={insta} className='me-4'></Image>
                  <Image src={youtube} className='me-4'></Image>
                  <Image src={linkedin} className='me-4'></Image>

                  {/* <Image src={google} className='me-4'></Image>
                        <Image src={legalremit} className='me-4'></Image>
                        <Image src={tiktok} className='me-4'></Image> */}
                </div>
              </Col>
              <Col className="col-lg-6 my-5 text-start d-flex flexColumn justify-content-betweena footerright">

                <div className='thiner text-white text-white footerlinks1'>
                  <h4 className='mb-3'>Useful Links</h4>
                  {/* <i className="fa-solid fa-caret-right me-3"></i>Common Occupation <br/> */}
                  {/* <i className="fa-solid fa-caret-right me-3"></i>Skilled Occupation List <br/> */}
                  <div onClick={PointsCalculatorPage} className='pointer'><i className="fa-solid fa-caret-right me-3"></i>Points Calculator<br /></div>
                  <div onClick={openVisaFees} className='pointer'><i className="fa-solid fa-caret-right me-3"></i>Visa Pricing Estimator<br /></div>

                  {/* <div onClick={openGlobalVisa} className='pointer'><i className="fa-solid fa-caret-right me-3"></i>Global Visa Processing Time<br /></div> */}

                  <div onClick={VisaProcess} className='pointer'><i className="fa-solid fa-caret-right me-3 pointer" ></i>Visa process<br /></div>
                  <div onClick={MigrationProcess} className='pointer'><i className="fa-solid fa-caret-right me-3 pointer" ></i>Migration process<br /></div>

                </div>

                <div className='thiner text-white text-white footerlinks2'>
                <h4 className='mb-3 invisible'>Useful Links</h4>
                  <div onClick={CareerConsultation} className='pointer'><i className="fa-solid fa-caret-right me-3 pointer" ></i>Career Consultation<br /></div>
                  <div onClick={openOnlineForms} className='pointer'><i className="fa-solid fa-caret-right me-3"></i>Online Forms<br /></div>
                  <div onClick={openGlobalVisa} className='pointer'><i className="fa-solid fa-caret-right me-3"></i>Global Visa Processing Time<br /></div>

                </div>

                {/* <div className='thiner text-white footerlinks2'>
                
                  <div onClick={CareerConsultation} className='pointer'><i className="fa-solid fa-caret-right me-3 pointer" ></i>Career Consultation<br /></div>
                  <div onClick={openOnlineForms} className='pointer'><i className="fa-solid fa-caret-right me-3"></i>Online Forms<br /></div>
                  <div onClick={openGlobalVisa} className='pointer'><i className="fa-solid fa-caret-right me-3"></i>Global Visa processing Time<br /></div>


                </div> */}
              </Col>
            </Row>
            <hr className='text-white m-0'></hr>
            <Row className='m-0 flexColumn '>
              <Col className='col-lg-6 text-start py-3 footernav d-flex flex-wrap footerCenterResponsive'>
                <small className='footerNav me-3 pointer' onClick={home}>
                  Home
                </small>

                <small className='footerNav me-3 pointer' onClick={AboutUsPage}>
                  About Us
                </small>

                <small className='footerNav me-3 pointer' onClick={ContactUsPage}>
                  Contact Us
                </small>
                <small className='footerNav me-3 pointer' onClick={ServicePage}>
                  Services
                </small>

                <small className='footerNav me-3 pointer' onClick={BlogsPage}>
                  Blogs
                </small>

                <small className='footerNav me-3 pointer' onClick={FAQPage}>
                  FAQ
                </small>

              </Col>

              <Col className='col-lg-6 footertextalign  py-3 centerText  footerCenterResponsive'>
                <small className='footerNav centerText'><small><i className="fa fa-copyright"></i>&nbsp; Copyright 2023  <a className=" mx-0 linkFocus" onClick={home}>FocusVisa.</a> All Rights Reserved.</small></small>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </>
  )
}

