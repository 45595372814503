import React from 'react';
import Navbar from '../Components/Layout/NavBar/NavBar'
import Footer from '../Components/Layout/Footer/Footer'
import Banner from '../Components/ContactUsPage/ContactUs_Banner/Banner';
import WriteMessage from '../Components/ContactUsPage/WriteMessage/WriteMessage';


export default function ContactUs({ children }) {
    return (
        <>
            <Navbar Page={"ContactUs"}></Navbar>
            <Banner></Banner>
            <WriteMessage></WriteMessage>
            <Footer></Footer>
        </>
    )
}

