import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useEffect } from "react";
import appBook from "../../../Assets/Images/calanderApp.png";

export default function BookAnAppointmentSteps4(props) {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  useEffect(() => {
    console.log("4th step: ", props.Details);
  }, []);
  function convertToAmPm(time24) {
    const [hour, minute] = time24.split(":");
    const isMorning = hour < 12;
    const hour12 = hour > 12 ? hour - 12 : parseInt(hour);
    const amPm = isMorning ? "am" : "pm";
    return `${hour12}:${minute} ${amPm}`;
  }

  return (
    <section className=" pb-5 mb-5 mt-4">
      <Container>
        <div className="headerText">
          <h3>Confirmation</h3>
          <span className="text-center w-25 my-5">
            To see the available appointment times in your local time zone, select your time zone and then choose a date and time from the list provided below. <br></br> You can schedule appointments online or in-person.
          </span>
        </div>

        <Row className="justify-content-center align-items-center">
          <Col className="col-lg-12 mt-5 d-flex m-auto justify-content-center">
            <table className="col-lg-8 customTable">
              <tr className="py-3 customRowTable">
                <td className="customTDTable">Agent</td>
                <td className="customTDTable">{props.Details.consolerName}</td>
              </tr>
              <tr className="py-3">
                <td className="customTDTable">Date & Time</td>
                <td className="customTDTable">
                  {/* {" "}
                  {props.Details.date
                    ? new Date(props.Details.date).toLocaleString("en-US", {
                        weekday: "short",
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })
                    : "-"}{" "}
                  at{" "} */}
                  {props.A_Date ? props.A_Date : "-"}
                </td>
              </tr>
              <tr className="py-3">
                <td className="customTDTable">Booking ID</td>
                <td className="customTDTable">{props.Details.appointmentId}</td>
              </tr>
            </table>

            {/* <Form className="container px-5">
              <div className="innerStep4">
                <div className="row">
                <div className="innerTable d-flex  py-3 justify-content-evenly">
                    <h5 className="bolder mb-0">Agent</h5>
                    <h5 className="normal text-gray mb-0">Jonathan honda (Australia)</h5>
                  </div>
                  <div className="innerTable d-flex  py-3 justify-content-evenly">
                    <h5 className="bolder mb-0">Date & Time</h5>
                    <h5 className="normal text-gray mb-0">Wed 19 Apr 2023 at 5:45 am (IST)</h5>
                  </div>
                  <div className="innerTable d-flex py-3 justify-content-evenly">
                    <h5 className="bolder  mb-0 ">Booking ID</h5>
                    <h5 className="normal text-gray mb-0">VN56WQ</h5>
                  </div>
                </div>
              </div>
            </Form> */}
          </Col>
          <Row className="d-flex justify-content-center align-items-center ">
            <Col className="col-lg-5 mt-4">
              <div className="">
                <Button
                  className="btn btn-default btnInfo w-100 mt-4 py-3"
                  onClick={() => props.handleReset()}
                >
                  <img src={appBook} className="img-fluid pe-2" /> BOOK ANOTHER
                  APPOINTMENT
                </Button>
              </div>
            </Col>
          </Row>
        </Row>
      </Container>
    </section>
  );
}
