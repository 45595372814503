// // import { Callbacks } from 'jquery';
// // import React, { useEffect } from 'react'

// // export default function AuthGoogle() { 

// //     const handlecallbackResponce=(responce)=>{
// //          console.log("Encoded jwt code"+ responce.credential)
// //     }

// //     useEffect(() => {
// //         /*global Google */
// //         Google.accounts.id.initialize({
// //             client_id: " 638847258567-j2uencnoci5te9ujqohqo5h3bkso71c5.apps.googleusercontent.com",
// //             callbacks: handlecallbackResponce
// //         });

// //         Google.accounts.id.renderButton(
// //             document.getElementById("googlauth_btn"),
// //             {theme:"outline", size:"large"}
// //         );
// //     }, []);
     
// //   return (
// //     <div>
// //       <div className='googlauth_btn'></div>
// //     </div>
// //   )
// // }
// import React, { useEffect, useState } from 'react'
// import './auth.css'
// import { GoogleOAuthProvider } from '@react-oauth/google';
// import { GoogleLogin } from '@react-oauth/google';
// import { useGoogleOneTapLogin } from '@react-oauth/google';
// import jwt_decode from "jwt-decode";
// import Google from '../../Assets/Images/google.svg'
// import { Button, Image } from 'react-bootstrap';
// import { useGoogleLogin } from '@react-oauth/google';
// import { MyCustomButton } from '@react-oauth/google';
// import {LoginSocialGoogle} from 'reactjs-social-login';
// import {LoginSocialFacebook} from 'reactjs-social-login';
// import { CommonConstants } from './../../Constants/common.constants';
// import axios  from 'axios';
// import { GoogleLoginButton } from "react-social-login-buttons";
// import {FacebookLoginButton} from 'react-social-login-buttons';
// export default function AuthGoogle() {
//   // const login=(credentialResponse)=>{
     
//   //             console.log(credentialResponse.credential);
//   //             var decoded = jwt_decode(credentialResponse.credential);
//   //             console.log(decoded)
//   //       }

//   // const loginError=()=>{
//   // console.log('Login Failed');
//   // }

//   // const igoogle=(credentialResponse)=>{
//   //     console.log(credentialResponse.credential);
//   //     var decoded = jwt_decode(credentialResponse.credential);
//   //     console.log("Name :"+ decoded.name)
//   //     console.log("email :"+ decoded.email)
//   // }
//   // const igoogleE=()=>{
//   //   console.log('Login Failed');

//   // }
//   const[email,setemail]=useState()
//   const[name,setname]=useState()
  

//   const facebookoauth=async(email,name)=>{
//       try{
//         const udata={
//           fullName:name,
//           email:email
//         }
//         const response = await axios.post(CommonConstants.BASE_URL + "/api/auth/loginSocialMedia", udata);
//         console.log(response)
//         if(response.data.status==="success"){
//           console.log(response.data.status)
//             // setSuccesshandle(response.data.meassage)
//             // handleSuccessShow()
//             // if(checked===true){
//             //     localStorage.setItem('EmailorMobilenumber', email);
//             // }
//           }
//           else if(response.data.status==="error"){
//             console.log(response)
//           }
//     }
//     catch(err){
//         console.log(err)
//     }
//   }

 
  
//   return (
//     <div>
//           <h2>hello</h2>
          
//         <GoogleOAuthProvider 
//           clientId="638847258567-j2uencnoci5te9ujqohqo5h3bkso71c5.apps.googleusercontent.com"
//           onSuccess={credentialResponse => {
//             console.log(credentialResponse.credential);
//             var decoded = jwt_decode(credentialResponse.credential);
//             console.log("Name :"+ decoded.name)
//             console.log("email :"+ decoded.email)
//         }}
//         onError={() => {
//             console.log('Login Failed');
//         }}
//         >
//           <GoogleLoginButton/>
          
          
//         </GoogleOAuthProvider>
//         {/* <GoogleLogin
//             type='icon'
//             onSuccess={credentialResponse => {
//                 console.log(credentialResponse.credential);
//                 var decoded = jwt_decode(credentialResponse.credential);
//                 console.log("Name :"+ decoded.name)
//                 console.log("email :"+ decoded.email)
//             }}
//             onError={() => {
//                 console.log('Login Failed');
//             }}
            
//             useOneTap
//           /> */}
//         <LoginSocialFacebook 
//           appId='582231846681664'
//           onResolve={(responce) => {
//             console.log(responce)
//             setemail(responce.data.email)
//             setname(responce.data.name)
//             facebookoauth(responce.data.email,responce.data.name)
//           }}
          
//           onReject={(error) => {
//               console.log(error);
//             }}
//           >
//           <FacebookLoginButton onClick={facebookoauth}/>
//         </LoginSocialFacebook>
//         <LoginSocialGoogle 
//           clientId="638847258567-j2uencnoci5te9ujqohqo5h3bkso71c5.apps.googleusercontent.com"
//           onResolve={(responce) => {
//             console.log(responce)
//             console.log(responce.data.email)
//             console.log(responce.data.name)
            
//           }}
          
//           onReject={(error) => {
//               console.log(error);
//             }}
//           >
//           <GoogleLoginButton onClick={facebookoauth}/>
//         </LoginSocialGoogle>
        
//     </div>
//   )
// }
// // remaining 