import React from "react";
import { Container, Row } from "react-bootstrap";
import vision from '../../../Assets/Images/vision.jpg'
import Mission from '../../../Assets/Images/Mission.jpg'
import objective from '../../../Assets/Images/objective.png'
import about_eye from '../../../Assets/Images/about_eye.png'
import sym_mission from '../../../Assets/Images/sym_mission.png'
import sym_objective from '../../../Assets/Images/sym_objective.png'
import Carousel from "react-bootstrap/Carousel";


import './AboutUs_Msg.css'


const AboutUs_Msg = () => {
  return (
    <div className="aboutus bg-white">
      <Container>
        <Row>
          <div className="headerText mt-5 pt-5 pb-3">
            <h3>Focus Visa at a glance</h3>
          </div>
        </Row>
        <Row>
          <span className="justifyText  px-5">
            At Focus Visa and Education Services, we believe in creating endless possibilities for our clients by connecting them with the world through our top-notch education, visa, and migration services. As an emerging company in the industry, we are committed to empowering individuals to pursue their dreams, unlock their potential, and experience life beyond borders. With years of experience and a team of dedicated professionals, we take pride in our ability to provide personalised and comprehensive solutions that cater to each client's unique needs. Our experts are well-versed in the latest trends and best practices, ensuring that our clients receive exceptional guidance throughout their journey.
          </span>

        </Row>
        <Row className="mt-1">

          <span className="justifyText mt-3 px-5">
            Our mission is to make the complex process of international education and migration accessible and seamless for our clients. We understand that every individual has unique aspirations and goals, which is why we offer a wide range of services, including professional course selection, admission guidance, visa processing, and pre-and post-arrival support. We work closely with our clients to understand their needs and aspirations, helping them make informed decisions about their future. Our strong partnerships with reputable educational institutions and immigration authorities across Australia ensure that our clients have access to the best opportunities and resources to achieve their dreams.
          </span>

        </Row>

        <Row className="mt-1">

          <span className="justifyText mt-3 px-5">
            At Focus Visa and Education Services, we are dedicated to building lasting relationships with our clients, providing them with unwavering support and guidance at every stage of their journey. We value transparency, honesty, and professionalism, which is why we are committed to maintaining the highest ethical standards in all our interactions. Our team of experts undergoes regular training and development to stay up-to-date with the latest industry developments, enabling us to provide our clients with the most accurate and relevant information. We go the extra mile to ensure that our clients have a smooth and hassle-free experience as they embark on their journey to explore new horizons.
          </span>

        </Row>

        <Row className="mt-1">

          <span className="justifyText mt-3 px-5">
            In today's increasingly interconnected world, we recognise the importance of having a global perspective and a strong foundation in education. At Focus Visa and Education Services, we are passionate about helping our clients achieve their full potential and make their mark on the world stage. We are constantly evolving and adapting to the ever-changing landscape of international education and migration, ensuring that we continue to provide our clients with unparalleled service and support. Whether you are looking to further your education, start a new career, or build a life in Australia, you can trust Focus Visa and Education Services to help you turn your dreams into reality. Together, let's create a future filled with endless possibilities and boundless opportunities.
          </span>


        </Row>
        <Row className="mt-5 px-2">
          <div className="mt-5 card-img">
            <div className="imgoverlay d-flex overflow-hidden justify-content-center   ">
              <img src={vision} alt="vision" className="img-fluidx" />
            </div>
            <div class="card-overlay mt-2 d-flex">
              <img src={about_eye} alt="eye" className="img-fluid" />
              <h3 class="card-title">Vision</h3>
              <div className="divider"></div>
              <p class="card-description2 justifyText col-xl-6 col-lg-8 col-md-7 col-sm-8 col-10">At Focus Visa and Education Services, our vision is to become the world's most trusted and sought-after provider of education, visa, and migration services, transforming lives by bridging cultural divides and creating a global community of empowered individuals.</p>
            </div>


          </div>
        </Row>


        <Row className="mt-5 px-2">
          <div className="mt-5 card-img">
            <div className="imgoverlay d-flex overflow-hidden justify-content-center">
              <img src={Mission} alt="mission" className="img-fluidx" />
            </div>
            <div class="card-overlay mt-2 d-flex">
              <img src={sym_mission} alt="sym" className="img-fluid" />
              <h3 class="card-title">Mission</h3>
              <div className="divider"></div>
              <p class="card-description2 justifyText col-xl-6 col-lg-8 col-md-7 col-sm-8 col-10">Our mission is to provide unparalleled, personalized solutions in education, visa, and migration services, enabling our clients to pursue their dreams, achieve their goals, and experience life beyond borders. We are committed to delivering exceptional customer service, maintaining the highest ethical standards, and fostering long-lasting partnerships with leading institutions and organizations worldwide.</p>
            </div>


          </div>
        </Row>
        <Row className="mt-5 px-2">
          <div className="mt-5 card-img mb-5">
            <div className="imgoverlay mt-2 d-flex overflow-hidden justify-content-center">
              <img src={objective} alt="vision" className="img-fluidx" />
            </div>
            <div className="card-overlay d-flex pb-5">
              <img src={sym_objective} alt="eye" className="img-fluid" />
              <h3 className="card-title">Objective</h3>
              <div className="divider"></div>
              <Carousel controls={false} class="">
                <Carousel.Item>
                  <p className="card-description2 justifyText col-xl-6 col-lg-8 col-md-7 col-sm-8 col-10 mx-auto">

                    To offer comprehensive, tailored services that cater to the unique needs and goals of each client, ensuring a seamless and stress-free journey towards their desired destination.
                  </p>
                </Carousel.Item>
                <Carousel.Item>
                  <p className="card-description2 justifyText col-xl-6 col-lg-8 col-md-7 col-sm-8 col-10 mx-auto ">

                    To stay updated with the latest trends, best practices, and regulatory changes in the education, visa, and migration sectors, enabling us to provide accurate and up-to-date guidance to our clients.
                  </p>
                </Carousel.Item>
                <Carousel.Item>
                  <p className="card-description2 justifyText col-xl-6 col-lg-8 col-md-7 col-sm-8 col-10 mx-auto ">

                    To expand our network of partnerships with prestigious educational institutions, government agencies, and industry organizations, providing our clients with a vast array of opportunities to choose from.
                  </p>
                </Carousel.Item>
                <Carousel.Item>
                  <p className="card-description2 justifyText col-xl-6 col-lg-8 col-md-7 col-sm-8 col-10 mx-auto ">

                    To invest in the professional development of our team, ensuring that our experts are well-equipped with the knowledge and skills required to deliver outstanding service.
                  </p>
                </Carousel.Item>
                <Carousel.Item>
                  <p className="card-description2 justifyText col-xl-6 col-lg-8 col-md-7 col-sm-8 col-10 mx-auto ">

                    To continuously improve our processes and services, incorporating feedback from our clients and partners to enhance their experience and satisfaction.
                  </p>
                </Carousel.Item>
                <Carousel.Item>
                  <p className="card-description2 justifyText col-xl-6 col-lg-8 col-md-7 col-sm-8 col-10 mx-auto ">
                    To uphold our values of integrity, transparency, and excellence in every aspect of our work, fostering trust and confidence among our clients and partners.
                  </p>
                </Carousel.Item>
                <Carousel.Item>
                  <p className="card-description2 justifyText col-xl-6 col-lg-8 col-md-7 col-sm-8 col-10 mx-auto ">
                    To promote cultural understanding, diversity, and inclusion through our services, contributing to the creation of a more connected and compassionate global community.
                  </p>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default AboutUs_Msg;
