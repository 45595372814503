import React from "react";
import "./HomeBanner.css";
import Slider from "react-slick";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Bannerbackground from "../../../Assets/Images/banner_background.jpg";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export default function HomeBanner({ children }) {
  let history = useHistory();

  const AppointementPage = () => {
    history.push("/bookanappointment");
    window.scrollTo(0, 0);
  };

  const scrollDown = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="conatiner hero">
        <div className="row">
          <div className="col-lg-12">
            <Carousel className="d-flex position relative" controls={false} interval={null}>
              <Carousel.Item indicators="true">
                <img
                  className="d-block w-100"
                  src={Bannerbackground}
                  alt="First slide"
                />

                <div className="container sliderContainer">
                  <Carousel.Caption >
                    <h2 className="bannerbody justify capitalize text-start h1set">
                    Unlock your global possibilities <br></br> with the focus visa
                    </h2>

                    {/* <p className="text-start w-50 normal mt-4 Pset">
                    Swift Visa Approvals & Unmatched Education Solutions: Your Trusted Gateway to Global Success!
                    </p> */}
                    <div className="row mt-5">
                      <div className="col-lg-12 d-flex">
                        <div className="">
                          <Button
                            className="border-0 d-flex bg-white btn textColorInfo font-weight-bold"
                            variant="default"
                            onClick={AppointementPage}
                          >
                            <h4 className="mainBolder mb-0 font-weight-bold">
                              BOOK AN APPOINTMENT
                            </h4>
                          </Button>
                        </div>
                     
                      </div>
                    </div>
                  </Carousel.Caption>
                </div>
              </Carousel.Item>
              {/* <Carousel.Item indicators="false">
                <img
                  className="d-block w-100"
                  src={Bannerbackground}
                  alt="Second slide"
                />
                <div className="container sliderContainer">
                  <Carousel.Caption>
                    <h2 className="bannerbody justify capitalize text-start h1set">
                      Unlock Your Aussie Dreams:<br></br> Education & Migration
                      Made Easy!
                    </h2>

                    <p className="text-start w-50 normal mt-4 Pset">
                      Achieve Your Great Aussie Adventure: Expert Education &
                      Migration Guidance!
                    </p>
                    <div className="row mt-5">
                      <div className="col-lg-12 d-flex">
                        <div className="">
                          <Button
                            className="border-0 d-flex bg-white btn textColorInfo font-weight-bold"
                            variant="default"
                            onClick={AppointementPage}
                          >
                            <h4 className="mainBolder mb-0 font-weight-bold">
                              BOOK AN APPOINTMENT
                            </h4>
                          </Button>
                        </div>
                 
                      </div>
                    </div>
                  </Carousel.Caption>
                </div>
              </Carousel.Item> */}
              {/* <Carousel.Item indicators="false">
                <img
                  className="d-block w-100"
                  src={Bannerbackground}
                  alt="Third slide"
                />
                <div className="container sliderContainer">
                  <Carousel.Caption>
                    <h2 className="bannerbody justify capitalize text-start h1set">
                      Australia Awaits Your Trusted <br></br>  Pathway to <br></br>
                      Education & Migration Success!
                    </h2>

                    <p className="text-start w-50 normal mt-4 Pset">
                      Swift Visa Approvals & Unmatched Education Solutions: Your
                      Trusted Gateway to Global Success!
                    </p>
                    <div className="row mt-5">
                      <div className="col-lg-12 d-flex appZind">
                        <div className="">
                          <Button
                            className="border-0 d-flex bg-white btn textColorInfo font-weight-bold mt-3"
                            variant="default"
                            onClick={AppointementPage}
                          >
                            <h4 className="mainBolder mb-0 font-weight-bold">
                              BOOK AN APPOINTMENT
                            </h4>
                          </Button>
                        </div>
                      
                      </div>
                    </div>
                  </Carousel.Caption>
                </div>
              </Carousel.Item> */}

              {/* <Carousel.Item indicators="false">
                <img
                  className="d-block w-100"
                  src={Bannerbackground}
                  alt="Third slide"
                />

                <div className="container sliderContainer">
                  <Carousel.Caption>
                    <h2 className="bannerbody justify capitalize text-start h1set">
                      Empower Your Future: Australian <br></br> Education &
                      Migration, Simplified!
                    </h2>

                    <p className="text-start w-50 normal mt-4 Pset">
                      Chart Your Course Down Under Learn, Live, Love Australia!
                    </p>
                    <div className="row mt-5">
                      <div className="col-lg-12 d-flex appZind">
                        <div className="">
                          <Button
                            className="border-0 d-flex bg-white btn textColorInfo font-weight-bold mt-3"
                            variant="default"
                            onClick={AppointementPage}
                          >
                            <h4 className="mainBolder mb-0 font-weight-bold">
                              BOOK AN APPOINTMENT
                            </h4>
                          </Button>
                        </div>
              
                      </div>
                    </div>
                  </Carousel.Caption>
                </div>
              </Carousel.Item> */}
            </Carousel>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5 downArow">
          <Button
            className="border-0 bg-transparent"
            variant="default"
            onClick={scrollDown}
          >
            <i className="fas fa-chevron-down fa-3x text-white"></i>
          </Button>
        </div>
      </div>
    </>
  );
}
