import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./WhyChooseUs.css";
import overlayModel from "../../../Assets/Images/overlay_why_choose_us.png";
import modalWhy from "../../../Assets/Images/model_why_choose_us.png";

export default function WhyChooseUs() {
  return (
    <section className="why  position-relative py-2">
      <div className="d-flex">
        <img className="img-fluid overlayWhy" src={overlayModel} alt="why" />
        <img className="modalWhy" height="950"  src={modalWhy} alt="model" />
      </div>
      <Container >
        <Row className="mt-5 pt-1">
            <Col className="col-lg-6 d-flex justify-content-start flex-column text-start">
                <span className="headingAfter my-4 ">Why Choose Focus Visa and Education Services</span>
                <span className="text-white mainHeading mt-1">We have wide range of solutions</span>
                <b className="text-white mt-1 pHeading bolder">or your next foreign trip</b>
                <ul className="ms-2 my-5 whyUl">
                    <li><h4 className="text-white liBullet">Expert Guidance</h4><p className="textP liBullet pb-2">Our team of qualified professionals have a wealth of knowledge and experience in education, visa, and migration services, ensuring our clients receive the best advice possible.</p></li>
                    <li><h4 className="text-white liBullet">Personalized Solutions</h4><p className="textP liBullet pb-2"> We believe in providing tailored solutions that cater to the individual needs and goals of each client, resulting in a higher success rate. </p></li>
                    <li><h4 className="text-white liBullet">Trusted Partnerships</h4><p className="textP liBullet pb-2">We have forged strong relationships with leading institutions and organizations worldwide, giving our clients access to an extensive network of opportunities.</p></li>
                    <li><h4 className="text-white liBullet">Transparent and Ethical Practices</h4><p className="textP liBullet pb-2"> Our commitment to transparency and ethical practices means that clients can trust us to always act in their best interest.</p></li>
                    <li><h4 className="text-white liBullet">Exceptional Customer Service</h4><p className="textP liBullet pb-2">At Focus Visa, we prioritize our client's satisfaction and go above and beyond to provide exceptional support throughout their journey.</p></li>

                    
                </ul>
            </Col>
        </Row>
      </Container>
    </section>
  );
}
