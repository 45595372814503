import React from "react";
import "./ExpancesInAustraliaPage.css";
import { Container, Row, Col, Button } from "react-bootstrap";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import AusBg from "../../Assets/Images/australiaBG.png";
import expanceBG from "../../Assets/Images/expanceBG.png";
import climateBG1 from "../../Assets/Images/climateBG2.png";
// import climateBG2 from "../../Assets/Images/bg3.svg";

import MigrationBG1 from "../../Assets/Images/migrationChild.png";
import bullet from "../../Assets/Images/beforeBullet.png";
import bookApp from "../../Assets/Images/calanderApp.png";
import chatApp from "../../Assets/Images/chatEnquire.png";

import { useHistory } from "react-router-dom";


export default function ExpancesInAustraliaPage() {

  let history = useHistory();

  const AppointementPage=()=> {
    history.push("/bookanappointment");
    window.scrollTo(0, 0)
  }


  const ContactUsPage=()=> {
    history.push("/ContactUs");
    window.scrollTo(0, 0)
  }

  return (
    <>
      <section className="bg-white">
 
          <Row className="py-5 pt-0">
            <Col className="col-lg-12 mb-5">
              <div className="first">
                {/* <div className="imgBG pt-3 d-flex ">
                  <img src={expanceBG} className="w-100" />
                </div> */}

                <div className="col-lg-12">
                  <div className="innerSecondSection d-flex ">
                 
                  </div>
                  <p className="justifyText mt-0">
                  <h4 className="mb-3 bolder">Introduction</h4>
                  Australia is a diverse and vibrant country that offers a wide range of experiences for its residents. However, living expenses can vary significantly depending on the state you choose to call home. In this blog post, we'll provide a detailed breakdown of the living expenses in each Australian state, covering aspects like average income, rental costs, and other day-to-day expenses
                  </p>
                </div>

                <div className="col-lg-12">
                  <p className="  justifyText mt-0">
                  Living expenses in Australia vary significantly between states, with factors such as housing costs, transportation, and lifestyle playing a significant role. While major cities like Sydney and Melbourne offer higher incomes, they also come with a higher cost of living. Regional areas and smaller capital cities like Adelaide and Hobart provide more affordable living options, making them attractive alternatives for those seeking a lower cost of living. Ultimately, the best place to live in Australia will depend on your individual preferences, lifestyle, and budget.
                  </p>
                </div>

                <div className="col-lg-12">
                  <div className="innerSecondSection mt-3 d-flex ">
                    <h2 className="text-start">
                    New South Wales (NSW)
                    </h2>
                  </div>
                  <table className="ausTable  mt-2">
                    <tr>
                      <th className="ausTH">Expenses</th>
                      <th className="ausTH">Amount in AUD</th>
                    </tr>
                    <tr>
                      <td className="ausTD">Average Income</td>
                      <td className="ausTD">AUD 68,000 per annum</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Average Rental Cost</td>
                      <td className="ausTD">AUD 2,200 per month (Sydney) </td>
                    </tr>
                    <tr>
                      <td className="ausTD">Other Expenses: Utilities</td>
                      <td className="ausTD">AUD 200 per month</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Transportation</td>
                      <td className="ausTD">AUD 160 per month</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Groceries</td>
                      <td className="ausTD">AUD 400 per month</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Dining Out</td>
                      <td className="ausTD">AUD 250 per month</td>
                    </tr>
                  </table>
                </div>

                <p className="  justifyText mt-4">
                New South Wales, with Sydney as its capital, is Australia's most populous state. Sydney has a high cost of living, driven by housing and rental prices. However, NSW offers higher average incomes and employment opportunities to offset these costs. Outside Sydney, living expenses are generally more affordable.
                  </p>

                  <div className="col-lg-12">
                  <div className="innerSecondSection mt-3 d-flex ">
                    <h2 className="text-start">
                    Victoria (VIC)
                    </h2>
                  </div>
                  <table className="ausTable  mt-2">
                    <tr>
                      <th className="ausTH">Expenses</th>
                      <th className="ausTH">Amount in AUD</th>
                    </tr>
                    <tr>
                      <td className="ausTD">Average Income</td>
                      <td className="ausTD">AUD 65,000 per annum </td>
                    </tr>
                    <tr>
                      <td className="ausTD">Average Rental Cost</td>
                      <td className="ausTD">AUD 1,800 per month (Melbourne) </td>
                    </tr>
                    <tr>
                      <td className="ausTD">Other Expenses: Utilities</td>
                      <td className="ausTD">AUD 190 per month</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Transportation</td>
                      <td className="ausTD">AUD 150 per month</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Groceries</td>
                      <td className="ausTD">AUD 380 per month</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Dining Out</td>
                      <td className="ausTD">AUD 220 per month</td>
                    </tr>
                  </table>
                </div>

                <p className="  justifyText mt-4">
                Victoria, home to the bustling city of Melbourne, offers a balanced cost of living with slightly lower average incomes than NSW. Melbourne is known for its vibrant arts scene, excellent public transportation, and a more relaxed lifestyle. Regional areas in Victoria offer lower living expenses than the capital city.
                  </p>

                  <div className="col-lg-12">
                  <div className="innerSecondSection mt-3 d-flex ">
                    <h2 className="text-start">
                    Queensland (QLD)
                    </h2>
                  </div>
                  <table className="ausTable  mt-2">
                    <tr>
                      <th className="ausTH">Expenses</th>
                      <th className="ausTH">Amount in AUD</th>
                    </tr>
                    <tr>
                      <td className="ausTD">Average Income</td>
                      <td className="ausTD">AUD 63,000 per annum</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Average Rental Cost</td>
                      <td className="ausTD">AUD 1,500 per month (Brisbane)  </td>
                    </tr>
                    <tr>
                      <td className="ausTD">Other Expenses: Utilities</td>
                      <td className="ausTD">AUD 180 per month</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Transportation</td>
                      <td className="ausTD">AUD 140 per month</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Groceries</td>
                      <td className="ausTD">AUD 370 per month</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Dining Out</td>
                      <td className="ausTD">AUD 200 per month</td>
                    </tr>
                  </table>
                </div>

                <p className="  justifyText mt-4">
                Queensland is famous for its beautiful beaches, warm climate, and thriving tourism industry. Brisbane, the capital, has a relatively lower cost of living compared to Sydney and Melbourne. In regional Queensland, living expenses are even more affordable, making it an attractive destination for many Australians.
                  </p>

                  <div className="col-lg-12">
                  <div className="innerSecondSection mt-3 d-flex ">
                    <h2 className="text-start">
                    South Australia (SA)

                    </h2>
                  </div>
                  <table className="ausTable  mt-2">
                    <tr>
                      <th className="ausTH">Expenses</th>
                      <th className="ausTH">Amount in AUD</th>
                    </tr>
                    <tr>
                      <td className="ausTD">Average Income</td>
                      <td className="ausTD">AUD 60,000 per annum</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Average Rental Cost</td>
                      <td className="ausTD">AUD 1,200 per month (Perth) </td>
                    </tr>
                    <tr>
                      <td className="ausTD">Other Expenses: Utilities</td>
                      <td className="ausTD">Utilities (AUD 170 per month)</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Transportation</td>
                      <td className="ausTD">AUD 130 per month</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Groceries</td>
                      <td className="ausTD">AUD 350 per month</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Dining Out</td>
                      <td className="ausTD">AUD 180 per month</td>
                    </tr>
                  </table>
                </div>



                <p className="  justifyText mt-4">
                South Australia offers a more affordable lifestyle, with Adelaide boasting the lowest rental costs among major Australian cities. The slower pace of life, beautiful wine regions, and lower living expenses make South Australia an appealing option for those looking to escape the hustle and bustle of larger cities.
                  </p>

                  <div className="col-lg-12">
                  <div className="innerSecondSection mt-3 d-flex ">
                    <h2 className="text-start">
                    Western Australia (WA)

                    </h2>
                  </div>
                  <table className="ausTable  mt-2">
                    <tr>
                      <th className="ausTH">Expenses</th>
                      <th className="ausTH">Amount in AUD</th>
                    </tr>
                    <tr>
                      <td className="ausTD">Average Income</td>
                      <td className="ausTD">AUD 62,000 per annum</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Average Rental Cost</td>
                      <td className="ausTD">AUD 1,600 per month (Perth) </td>
                    </tr>
                    <tr>
                      <td className="ausTD">Other Expenses: Utilities</td>
                      <td className="ausTD">Utilities (AUD 175 per month)</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Transportation</td>
                      <td className="ausTD">AUD 135 per month</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Groceries</td>
                      <td className="ausTD">AUD 360 per month</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Dining Out</td>
                      <td className="ausTD">AUD 190 per month</td>
                    </tr>
                  </table>
                </div>

                <p className="  justifyText mt-4">
                Western Australia, with its vast landscapes and booming mining industry, offers a unique lifestyle. Perth, the capital, has a moderate cost of living, with housing prices lower than in Sydney and Melbourne. Regional WA, particularly in mining towns, may have higher living costs due to the remote nature of these locations.
                  </p>

                  <div className="col-lg-12">
                  <div className="innerSecondSection mt-3 d-flex ">
                    <h2 className="text-start">
                    Tasmania (TAS)


                    </h2>
                  </div>
                  <table className="ausTable  mt-2">
                    <tr>
                      <th className="ausTH">Expenses</th>
                      <th className="ausTH">Amount in AUD</th>
                    </tr>
                    <tr>
                      <td className="ausTD">Average Income</td>
                      <td className="ausTD">AUD 58,000 per annum</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Average Rental Cost</td>
                      <td className="ausTD">AUD 1,100 per month (Perth) </td>
                    </tr>
                    <tr>
                      <td className="ausTD">Other Expenses: Utilities</td>
                      <td className="ausTD">Utilities (AUD 160 per month)</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Transportation</td>
                      <td className="ausTD">AUD 125 per month</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Groceries</td>
                      <td className="ausTD">AUD 340 per month</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Dining Out</td>
                      <td className="ausTD">AUD 170 per month</td>
                    </tr>
                  </table>
                </div>

                <p className="  justifyText mt-4">
                Tasmania, known for its stunning natural beauty and slower pace of life, has the lowest average income among Australian states. However, living expenses, particularly housing and rental costs, are also lower in Tasmania. Hobart, the capital, offers a charming, small-town feel with a lower cost of living than mainland capital cities.
                  </p>

                  <div className="col-lg-12">
                  <div className="innerSecondSection mt-3 d-flex ">
                    <h2 className="text-start">
                    Australian Capital Territory (ACT)


                    </h2>
                  </div>
                  <table className="ausTable  mt-2">
                    <tr>
                      <th className="ausTH">Expenses</th>
                      <th className="ausTH">Amount in AUD</th>
                    </tr>
                    <tr>
                      <td className="ausTD">Average Income</td>
                      <td className="ausTD">AUD 70,000 per annum</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Average Rental Cost</td>
                      <td className="ausTD">AUD 2,200 per month (Perth) </td>
                    </tr>
                    <tr>
                      <td className="ausTD">Other Expenses: Utilities</td>
                      <td className="ausTD">Utilities (AUD 185 per month)</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Transportation</td>
                      <td className="ausTD">AUD 145 per month</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Groceries</td>
                      <td className="ausTD">AUD 365 per month</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Dining Out</td>
                      <td className="ausTD">AUD 210 per month</td>
                    </tr>
                  </table>
                </div>

                <p className="  justifyText mt-4">
                The Australian Capital Territory, home to the nation's capital, Canberra, boasts the highest average income in the country. Canberra offers a mix of city living with suburban comfort, with a cost of living slightly lower than Sydney and Melbourne. The public sector drives the local economy, offering stable employment opportunities.

                  </p>

                  <div className="col-lg-12">
                  <div className="innerSecondSection mt-3 d-flex ">
                    <h2 className="text-start">
                    Northern Territory (NT)



                    </h2>
                  </div>
                  <table className="ausTable  mt-2">
                    <tr>
                      <th className="ausTH">Expenses</th>
                      <th className="ausTH">Amount in AUD</th>
                    </tr>
                    <tr>
                      <td className="ausTD">Average Income</td>
                      <td className="ausTD">AUD 61,000 per annum</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Average Rental Cost</td>
                      <td className="ausTD">AUD 1,400 per month (Perth) </td>
                    </tr>
                    <tr>
                      <td className="ausTD">Other Expenses: Utilities</td>
                      <td className="ausTD">Utilities (AUD 200 per month)</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Transportation</td>
                      <td className="ausTD">AUD 150 per month</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Groceries</td>
                      <td className="ausTD">AUD 380 per month</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Dining Out</td>
                      <td className="ausTD">AUD 205 per month</td>
                    </tr>
                  </table>
                </div>

                <p className="  justifyText mt-4">
                The Northern Territory, characterized by its vast and remote landscapes, offers a unique living experience. Darwin, the capital, has a higher cost of living than other regional cities due to its isolation. However, the cost of living is still lower than in major capital cities on the mainland. Employment opportunities in the NT often include additional benefits, such as housing and travel allowances, to compensate for the remote location.

                  </p>
                

                {/* <div className="col-lg-12">
                  <div className="innerSecondSection mt-3 d-flex ">
                    <h2 className="text-start">Cost of House in Australia</h2>
                  </div>
                  <p className="  justifyText mt-4">
                    Accommodation costs vary depending on the region and type of
                    location chosen by the student. More rural places in western
                    and northern Australia are less expensive, but modern areas
                    such as Sydney are more expensive. Furthermore, students'
                    cost of living in Australia varies depending on whether they
                    reside on-campus or off-campus. The former would cost a
                    student AUD 440 - 1,100 per month on average, whilst a
                    shared rental would cost AUD 380 - 850 per month.
                  </p>
                </div>

                <div className="col-lg-12">
                  <div className="innerSecondSection mt-3 d-flex ">
                  
                  </div>
                  <table className="ausTable  mt-2">
                    <tr>
                      <th className="ausTH">Type of accommodation</th>
                      <th className="ausTH">Average monthly rent (AUD)</th>
                    </tr>
                    <tr>
                      <td className="ausTD">Homestays</td>
                      <td className="ausTD">$100 – $300</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Halls of residence</td>
                      <td className="ausTD">$140 – $280</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Apartment</td>
                      <td className="ausTD">$10– $20</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Shared apartment</td>
                      <td className="ausTD">$30– $60</td>
                    </tr>
                  </table>
                </div>

                <div className="col-lg-12">
                  <div className="innerSecondSection mt-3 d-flex ">
                    <h2 className="text-start">
                      Transportation Cost in Australia
                    </h2>
                  </div>
                  <table className="ausTable  mt-2">
                    <tr>
                      <th className="ausTH">Type of Expense</th>
                      <th className="ausTH">Cost (AUD)</th>
                    </tr>
                    <tr>
                      <td className="ausTD">One-way local transport ticket</td>
                      <td className="ausTD">$100 – $300</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Taxi Tariff</td>
                      <td className="ausTD">$140 – $280</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Gasoline (1 litre)</td>
                      <td className="ausTD">$10– $20</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Taxi 1 Km</td>
                      <td className="ausTD">$30– $60</td>
                    </tr>
                    <tr>
                      <td className="ausTD">Monthly Pass</td>
                      <td className="ausTD">$30– $60</td>
                    </tr>
                  </table>
                </div> */}
              </div>

              {/* <div className="col-lg-12 d-flex m-auto justify-content-center mt-5 pt-5">
                <div className="col-lg-4">
                  <Button className="btnInfo w-100" onClick={AppointementPage}>
                    {" "}
                    <img src={bookApp} className="img-fluid pe-2" /> BOOK AN
                    APPOINTEMENT
                  </Button>
                  <Button className="mt-3 lightAlice border-0 textColorInfo w-100" onClick={ContactUsPage}>
                    <img src={chatApp} className="img-fluid pe-2" /> ENQUIRE NOW
                  </Button>
                </div>
              </div> */}
            </Col>
          </Row>
       
      </section>
    </>
  );
}
