import React from 'react'
import Navbar from '../Components/Layout/NavBar/NavBar'
import Footer from '../Components/Layout/Footer/Footer'
import BookAnAppointment from '../Components/BookAnAppointmentPage/BookAnAppointmentPage'
import MigrationProcesssBanner from '../Components/MigrationProcessPage/MigrationProcessBanner/MigrationProcessBanner'
import MigrationProcessPage from '../Components/MigrationProcessPage/MigrationProcessPage'
import FooterVideo from '../Components/Layout/FooterVideo/FooterVideo'

export default function MigrationProcess() {
  return (
    <>
      <Navbar/>
      <MigrationProcesssBanner/>
      <MigrationProcessPage/>
      <FooterVideo/>

      <Footer/>
    </>
  )
}
