import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import guide1 from "../../../Assets/Images/avtarGuide1.jpg";
import guide2 from "../../../Assets/Images/avtarGuide2.jpg";
import guide3 from "../../../Assets/Images/avtarGuide3.jpg";
import guide4 from "../../../Assets/Images/avtarGuide4.jpg";
import guide5 from "../../../Assets/Images/avtarGuide5.jpg";
import guide6 from "../../../Assets/Images/avtarGuide6.jpg";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import SendIcon from "@mui/icons-material/Send";
import Loading from "../../Layout/loading/Loading";
import "./BookAppointmentSteps.css";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#FFFFFF",
  border: "0",
  boxShadow: 24,
  p: 4,
};

export default function BookAnAppointmentSteps1(props) {
  const [open, setOpen] = React.useState(false);
  // const [open, setOpen] = React.useState(false);
  const [ConsolerTimeZone, setConsolerTimeZone] = useState("");

  const [loading, setloading] = React.useState(true);
  const handleOpen = (data) => {
    setagent({
      id: data.id,
      fullName: data.fullName,
      image: data.image,
      description: data.description,
      country: data.country,
    });
    setOpen(true);
    setConsolerTimeZone(data.timeZone.timezone);
    // console.log(data.timeZone?.timezone,"data.timeZone.timeZone")
  };
  const handleClose = () => setOpen(false);

  const [Consolers, setConsolers] = useState([]);
  const [agent, setagent] = useState({
    id: "",
    fullName: "",
    email: "",
    image: "",
    mobileNo: "",
    description: "",
    country:""
  });
  const getConsolers = () => {
    setloading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/admin/get-allconsoler`)
      .then((res) => {
        if (res.data.statuscode == 200) {
          // console.log(res.data.data,"res.data.data")
          setConsolers(res.data.data);
          setloading(false);
        }
      });
  };
  const nextStep = () => {
    // window.open("https://ketan-gvmtechnologies.zohobookings.com/#/customer/4503000000000035016")
    props.FirstStepData(agent);
    props.handleConsoler(ConsolerTimeZone);
    props.handleNext();
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getConsolers();
  }, []);

  return (
    <>
      <section className="bookanappointment h-100 pb-5 mb-5 mt-4">
        <Container>
          <div className="headerText">
            <h3>Choose Your Guide</h3>
            <span className="text-center w-25 my-5">
              To see the available appointment times in your local time zone,
              select your time zone and then choose a date and time from the
              list provided below. <br></br> You can schedule appointments
              online or in-person.
            </span>
          </div>

          <div className="innerBoxGuide mt-5">
            <Row>
              <Col className="allbox col-lg-12">
                {loading ? (
                  <Loading />
                ) : (
                  Consolers.map((consoler) => {
                    return (
                      <div
                        className="innerBoxChild d-flex align-items-center my-5 "
                        key={consoler.id}
                      >
                        <img
                          src={consoler.image ? consoler.image : guide1}
                          className=""
                          height="130"
                          width="130"
                        />
                        <div className="innerBoxChildText d-flex flex-column justify-content-center pt-3 mx-5 px-3">
                          <h4 className="innerBoxP text-start">
                            {consoler.fullName}
                            {' '}  
                            {consoler.country ? `(${consoler.country})` : ""}
                          </h4>
                          <p className="innerBoxP text-start">
                            {consoler.description.substr(0, 100)}{" "}
                            <a
                              className="seeMore ms-2 pointer"
                              onClick={() => handleOpen(consoler)}
                            >
                              See More
                            </a>
                          </p>
                        </div>
                        <div className="left-arrow d-flex justify-content-end ms-auto pe-5">
                          <i className="fa fa-angle-right text-white rightArrow" />
                        </div>
                      </div>
                    );
                  })
                )}
              </Col>
            </Row>
          </div>
        </Container>

        <Modal
          className="pt-0"
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className="innerModalbody">
                <div className="bg-transparent innerBoxChild d-flex align-items-start my-3">
                  <div className="left-arrow pointer" onClick={handleClose}>
                    <i className="fa fa-angle-left fa-3x text-black pe-4" />
                  </div>
                  <img
                    src={agent.image ? agent.image : guide1}
                    className=""
                    height="140"
                    width="140"
                  />
                  <div className="innerBoxChildText d-flex flex-column justify-content-center mx-5 px-3">
                    <h4 className="text-black text-start">
                      {agent.fullName} ({agent.country})
                    </h4>
                    <p className="text-black">
                      First Free 30 min Face to Face. <br />
                      30 mins·$0
                    </p>
                  </div>
                </div>
                {/* <p className="text-black py-3 medium">
                  {" "}
                  If you visiting for the first time please fill out our inquiry
                  form in the link below prior to your consultation
                </p>
                <a className="text-info py-3 medium">
                  https://provisa.agentcisapp.com/online-form
                </a> */}

                <p className="text-black py-3 medium">{agent.description}</p>
              </div>
            </Typography>

            <Button
              variant="contained"
              onClick={nextStep}
              className="modalbutton d-flex justify-content-center m-auto btnInfo py-3 mt-4"
              startIcon={<CalendarMonthRoundedIcon />}
            >
              Book an appointment
            </Button>
          </Box>
        </Modal>
      </section>
    </>
  );
}
