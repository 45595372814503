import React from 'react';
import Navbar from '../Components/Layout/NavBar/NavBar'
import Footer from '../Components/Layout/Footer/Footer'
import BlogsMessage from '../Components/BlogsPage/BlogsMessage/BlogsMessage';
import BlogsBanner from '../Components/BlogsPage/BlogsBanner/BlogsBanner';
import FooterVideo from '../Components/Layout/FooterVideo/FooterVideo';


export default function Blogs({ children }) {
    return (
        <>
            <Navbar></Navbar>
            <BlogsBanner></BlogsBanner>
            <BlogsMessage></BlogsMessage>
      <FooterVideo/>

            <Footer></Footer>
        </>
    )
}

