import React from 'react'
import './InsuranceBanner.css'
import ServicesBanner from '../../../Assets/Images/insuranceBanner.jpg'



import { Card, Col, Container, Row } from 'react-bootstrap'

export default function InsuranceBanner() {
  return (
    <section className='servicesbanner'>
      
      <img src={ServicesBanner} className='img-fluid banner_img'/>
      
      <Container fluid className='banner_container'>
        <h1 className='calcPoint'>INSURANCE</h1>
        {/* <span className='text-white bolder innerServiceBannerText'>SERVICES · Insurance</span> */}
      </Container>
    </section>
  )
}
