import React from 'react'
import './Banner.css'
import Contactusbanner from '../../../Assets/Images/ContactusBanner.jpeg'



import { Card, Col, Container, Row } from 'react-bootstrap'

export default function Banner() {
  return (
    <section className='servicesbanner overflow-hidden'>
      
      <img src={Contactusbanner} className='img-fluidx banner_img'/>
      
      <Container fluid className='banner_container'>
        <h1>CONTACT US </h1>
      </Container>
    </section>
  )
}
