import React from 'react';
import './FooterVideo.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Focusvisalogo from '../../../Assets/Images/footerVideo.png'
import { Image } from 'react-bootstrap';
import facebook from '../../../Assets/Images/Footer-Facebook-icon.png'
import tweeter from '../../../Assets/Images/Footer-Twitter-icon.png'
import google from '../../../Assets/Images/Footer-Google+-icon.png'
import legalremit from '../../../Assets/Images/Footer-lr-icon.png'
import tiktok from '../../../Assets/Images/Footer-Tiktok-icon.png'


export default function FooterVideo({ children }) {
    return (
        <>
        <section className='bg-white'>     
        <div className='footervideo overflow-hidden'>
            <footer>
            <Container fluid className='pe-0 ps-0 ms-0 me-0 pointer'>
            <Row className="m-0">
                <Col className="col-lg-12 pe-0 ps-0 ms-0 me-0 d-flex justify-content-center">
                    <img src={Focusvisalogo} className="w-100" alt="focus visa logo"></img>
                </Col>
            </Row>
            </Container>
            </footer>
        </div>
        </section>
        </>
    )
}

