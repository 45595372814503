import React from "react";
import "./VisaProcessPage.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import VisaBG from "../../Assets/Images/visaprocessBg.jpg";
import VisaBG1 from "../../Assets/Images/servocechild.png";
import bullet from "../../Assets/Images/beforeBullet.png";
import bookApp from "../../Assets/Images/calanderApp.png";
import chatApp from "../../Assets/Images/chatEnquire.png";

import { useHistory } from "react-router-dom";

export default function VisaProcessPage() {
  const [multbanner, setmultbanner] = useState("FamilyVisa");
  const [expandedAccordion, setExpandedAccordion] = useState(null);

  const handleAccordionChange = (accordionId) => {
    if (expandedAccordion === accordionId) {
      // If the clicked accordion is already expanded, close it
      setExpandedAccordion(null);
    } else {
      // Expand the clicked accordion
      setExpandedAccordion(accordionId);
    }
  };

  let history = useHistory();

  const AppointementPage = () => {
    history.push("/bookanappointment");
    window.scrollTo(0, 0);
  };

  const ContactUsPage = () => {
    history.push("/ContactUs");
    window.scrollTo(0, 0);
    console.log('object',    window.scrollTo(0, 0));
  };

  const Migration = () => {
    history.push("/migrationprocess");
    window.scrollTo(0, 0);
  };

  return (
    <>
      <section className="bg-white">
        <Container>
          <Row className="py-5">
            <Col className="col-lg-12 d-flex align-items-start flexColumn">
              <Col className="col-lg-3 responsivePadding">
                <div>
                  <Accordion
                    className="mt-3 rounded-1 customAccordion"
                    expanded={expandedAccordion === "familyAndPartner"}
                    onChange={() => handleAccordionChange("familyAndPartner")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div
                        className=""
                        onClick={() =>
                          handleAccordionChange("familyAndPartner")
                        }
                      >
                        <Typography
                          className="normal text-start"
                          onClick={() => setmultbanner("FamilyAndPartner")}
                        >
                          The Family And Partner Visa
                        </Typography>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <a onClick={() => setmultbanner("PartnerVisa820")}>
                          <p className="textColorInfo text-start normal">
                            The Partner Visa (Provisional and Migrant) Visa
                          </p>
                        </a>
                        <a onClick={() => setmultbanner("PartnerVisa820")}>
                          <p className="textColorInfo text-start normal">
                            Partner Visa (apply in Australia - Subclass 820 and
                            801)
                          </p>
                        </a>
                        <a
                          onClick={() =>
                            setmultbanner("ProspectiveMarrigeVisa")
                          }
                        >
                          <p className="textColorInfo text-start normal">
                            The Prospective Marriage Visa
                          </p>
                        </a>
                        <a onClick={() => setmultbanner("ParentVisa")}>
                          <p className="textColorInfo text-start normal">
                            The Parent Visa
                          </p>
                        </a>

                        <a onClick={() => setmultbanner("NewZealandCitizen")}>
                          <p className="textColorInfo text-start normal">
                            The New Zealand Citizen Family Relationship Visa
                          </p>
                        </a>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="mt-3 rounded-1 customAccordion"
                    expanded={expandedAccordion === "studentVisas"}
                    onChange={() => handleAccordionChange("studentVisas")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div
                        className=""
                        onClick={() => handleAccordionChange("studentVisas")}
                      >
                        <Typography className="normal text-start">
                          The Student Visas
                        </Typography>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <a onClick={() => setmultbanner("Subclass500")}>
                          <p className="textColorInfo text-start normal">
                            The Student Visa (Subclass 500)
                          </p>
                        </a>

                        <a onClick={() => setmultbanner("Subclass590")}>
                          <p className="textColorInfo text-start normal">
                            The Student Gaurdian Visa (Subclass 590)
                          </p>
                        </a>

                        <a onClick={() => setmultbanner("Subclass407")}>
                          <p className="textColorInfo text-start normal">
                            The Training Visa (Subclass 407)
                          </p>
                        </a>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="mt-3 rounded-1 customAccordion"
                    expanded={expandedAccordion === "workingandskilled"}
                    onChange={() => handleAccordionChange("workingandskilled")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div
                        className=""
                        onClick={() => handleAccordionChange("workingVisa")}
                      >
                        <Typography
                          className="normal text-start"
                          onClick={() => setmultbanner("WorkingVisa")}
                        >
                          The Working and Skilled Visas
                        </Typography>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <a onClick={() => setmultbanner("Subclass189")}>
                          <p className="textColorInfo text-start normal">
                            The Skilled Independent Visa (Subclass 189)
                          </p>
                        </a>

                        <a onClick={() => setmultbanner("Subclass190")}>
                          <p className="textColorInfo text-start normal">
                            The Skilled Nominated Visa (Subclass 190)
                          </p>
                        </a>

                        <a onClick={() => setmultbanner("Subclass491")}>
                          <p className="textColorInfo text-start normal">
                            The Skilled Regional (Provisional) Visa (Subclass
                            491)
                          </p>
                        </a>

                        <a onClick={() => setmultbanner("Subclass191")}>
                          <p className="textColorInfo text-start normal">
                            The Premanent Residence (Skilled Regional) Visa
                            (Subclass 191)
                          </p>
                        </a>

                        <a onClick={() => setmultbanner("Subclass888")}>
                          <p className="textColorInfo text-start normal">
                            The Business Innovation and Investment (Permanent)
                            Visa (Subclass 888)
                          </p>
                        </a>

                        <a onClick={() => setmultbanner("Subclass188")}>
                          <p className="textColorInfo text-start normal">
                            The Business Innovation and Investment (Provisional)
                            Visa (Subclass 188)
                          </p>
                        </a>

                        <a onClick={() => setmultbanner("Subclass485")}>
                          <p className="textColorInfo text-start normal">
                            The Temporary Graduate visa (Subclass 485)
                          </p>
                        </a>

                        <a onClick={() => setmultbanner("Subclass494")}>
                          <p className="textColorInfo text-start normal">
                            The Skilled Employer Sponsered Regional
                            (Provisional) Visa (Subclass 494)
                          </p>
                        </a>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="mt-3 rounded-1 customAccordion"
                    expanded={expandedAccordion === "subclass600"}
                    onChange={() => handleAccordionChange("subclass600")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="normal text-start">
                        The Visitor Visa
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        className=""
                        onClick={() => handleAccordionChange("workingVisa")}
                      >
                        <Typography>
                          <a onClick={() => setmultbanner("Subclass600")}>
                            <p className="textColorInfo text-start normal">
                              The Visitor Visa Subclass 600
                            </p>
                          </a>
                        </Typography>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="mt-3 rounded-1 customAccordion"
                    expanded={expandedAccordion === "othervisa"}
                    onChange={() => handleAccordionChange("othervisa")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div
                        className=""
                        onChange={() => handleAccordionChange("othervisa")}
                      >
                        <Typography className="normal text-start">
                          The Other Visas
                        </Typography>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <a onClick={() => setmultbanner("WorkingHolidayVisa")}>
                          <p className="textColorInfo text-start normal">
                            The Working Holiday Visa
                          </p>
                        </a>

                        <a onClick={() => setmultbanner("BridingVisa")}>
                          <p className="textColorInfo text-start normal">
                            The Bridging Visa
                          </p>
                        </a>

                        <a onClick={() => setmultbanner("BridingVisa1")}>
                          <p className="textColorInfo text-start normal">
                            The Employer Nomination scheme (Subclass 186)
                          </p>
                        </a>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Col>
              <Col className="col-lg-9 responsivePadding">
                {multbanner === "FamilyAndPartner" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className=" bolder text-start">
                            The Family and Partner Visas
                          </h2>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-4">
                          Family and Partner visas are designed to allow
                          Australian citizens, permanent residents, or eligible
                          New Zealand citizens to sponsor their family members
                          or partners to join them in Australia. These visas aim
                          to facilitate family reunification and allow people to
                          live with their loved ones in Australia.
                        </p>
                        <p className="textColorDefault normal  justifyText mt-4">
                          The requirements for Family and Partner visas may vary
                          depending on the type of visa, the applicant's
                          relationship with the sponsor, and other factors.
                          Generally, applicants must meet health and character
                          requirements and provide evidence of their
                          relationships with the sponsor, such as marriage or
                          birth certificates, photographs, and supporting
                          statements from friends and family.
                        </p>
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3 mb-3">
                          <h4 className="text-start">
                            Family and Partner visas typically allow successful
                            applicants to:
                          </h4>
                        </div>

                        <ol className="">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Live, work, and study in Australia either
                              temporarily or permanently, depending on the visa
                              type.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Enroll in Medicare, Australia's public healthcare
                              system (for permanent visas or some provisional
                              visas leading to permanent residency).
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Travel to and from Australia while the visa is
                              valid.
                            </div>
                            <li className="mt-3">
                              <div className=" d-flex me-auto ps-2 justifyText">
                                Sponsor eligible family members for permanent
                                residency.
                              </div>
                            </li>
                            <li className="mt-3">
                              <div className=" d-flex me-auto ps-2 justifyText">
                                Apply for Australian citizenship after meeting
                                the residency requirements (for permanent
                                visas).
                              </div>
                            </li>
                          </li>
                        </ol>
                      </div>

                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>

                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "FamilyVisa" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="textColorDefault normal text-start">
                            The Visa Process
                          </h2>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-4">
                          The Australian visa process is designed to ensure that
                          applicants meet the eligibility requirements for their
                          specific visa type. As a country with diverse
                          opportunities for work, study, travel, and migration,
                          Australia attracts a wide range of applicants with
                          varying needs and circumstances. The visa process
                          accommodates these diverse needs by offering different
                          types of visas, each with its unique set of
                          requirements and procedures.
                        </p>
                        <p className="textColorDefault normal  justifyText mt-4">
                          Depending on the visa type, applicants may need to
                          meet specific criteria related to their purpose of
                          travel, financial stability, health, character, and
                          language proficiency, among other factors. While the
                          application process can vary between visa types,
                          Australia's Department of Home Affairs has implemented
                          a streamlined and user-friendly system to make the
                          process as efficient and transparent as possible.
                        </p>
                        <p className="textColorDefault normal  justifyText mt-4">
                          One key aspect of the Australian visa process is its
                          focus on ensuring that applicants are genuine
                          temporary entrants or, in the case of permanent
                          residency visas, meet the necessary criteria for
                          long-term settlement in Australia. This helps maintain
                          the integrity of the Australian migration system and
                          ensures that individuals entering the country have a
                          legitimate reason to do so.
                        </p>

                        <p className="textColorDefault normal  justifyText mt-4">
                          In addition to these general requirements, the
                          Australian visa process also caters to specific
                          categories of applicants, such as skilled workers,
                          students, family members, businesses, and refugees.
                          Each of these categories has its unique set of
                          requirements and procedures, ensuring that applicants
                          are thoroughly assessed based on their individual
                          circumstances and the purpose of their visit to
                          Australia.
                        </p>
                        <p className="textColorDefault normal  justifyText mt-4">
                          It is worth noting that the Australian visa process is
                          subject to change, as the government may introduce new
                          visa types or update existing regulations and
                          requirements in response to changing circumstances or
                          policy priorities. Therefore, it is essential for
                          applicants to consult the official Australian
                          Department of Home Affairs website or seek advice from
                          an authorized migration agent to ensure that they are
                          aware of the most up-to-date information and
                          requirements for their specific visa type.
                        </p>
                      </div>

                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>

                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "ProvisionalandMigrant" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="bolder text-start">
                            The Partner Visa (Provisional and Migrant) Visa
                          </h2>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-4">
                          The Partner Visa (Provisional and Migrant) is designed
                          for the spouse or de facto partner of an Australian
                          citizen, permanent resident, or eligible New Zealand
                          citizen to live, work, and study in Australia. There
                          are two subclasses under this visa category:
                        </p>
                        <ol className="">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Partner (Provisional) Visa (Subclass 309): This is
                              a temporary visa for applicants applying from
                              outside Australia. It allows the partner of an
                              Australian citizen, permanent resident, or
                              eligible New Zealand citizen to enter and live in
                              Australia while their permanent Partner Visa
                              (Subclass 100) is being processed.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Partner (Migrant) Visa (Subclass 100): This is a
                              permanent visa for applicants who have already
                              been granted a Partner (Provisional) Visa
                              (Subclass 309) and have met the specific
                              requirements for obtaining the permanent visa.
                            </div>
                          </li>
                        </ol>
                        <h4 className="bolder text-black  text-start mt-5">
                          Requirements
                        </h4>

                        <ol className=" mt-3">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Relationship: Applicants must be married or in a
                              de facto relationship with an Australian citizen,
                              permanent resident, or eligible New Zealand
                              citizen. De facto relationships must have existed
                              for at least 12 months before applying unless
                              there are compelling reasons for waiving this
                              requirement.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Sponsorship:- The Australian partner must act as
                              the visa sponsor and be willing to support the
                              applicant and any dependent family members
                              included in the application.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Relationship evidence:- Applicants must provide
                              evidence of a genuine and ongoing relationship,
                              including financial, social, and emotional
                              aspects. This can include joint bank accounts,
                              photographs, shared living arrangements, and
                              supporting statements from friends and family.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Health and character requirements:- Applicants
                              must meet specific health and character
                              requirements, including undergoing a medical
                              examination and obtaining police clearance
                              certificates from countries they have lived in for
                              more than 12 months.
                            </div>
                          </li>
                        </ol>
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3 mb-3">
                          <h4 className="text-start">
                            Once the Partner (Provisional) Visa (Subclass 309)
                            is granted, the applicant can:
                          </h4>
                        </div>

                        <ol className="">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Enter and live in Australia until a decision is
                              made on their permanent Partner (Migrant) Visa
                              (Subclass 100).
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Work and study in Australia.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Enroll in Medicare, Australia's public healthcare
                              system.
                            </div>
                          </li>
                        </ol>
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3 mb-3">
                          <h4 className="text-start">
                            Upon the grant of the Partner (Migrant) Visa
                            (Subclass 100), the applicant can:
                          </h4>
                        </div>

                        <ol className="">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Live, work, and study in Australia permanently.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Sponsor eligible relatives for permanent
                              residency.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Apply for Australian citizenship after meeting the
                              residency requirements.
                            </div>
                          </li>
                        </ol>
                      </div>

                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "ProspectiveMarrigeVisa" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="text-start">
                            The Prospective Marriage Visa
                          </h2>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-4">
                          The Prospective Marriage Visa (Subclass 300) is a
                          temporary visa designed for individuals who are
                          engaged to an Australian citizen, permanent resident,
                          or eligible New Zealand citizen and plan to marry
                          their partner in Australia. This visa allows the
                          applicant to enter Australia and marry their fiancé(e)
                          within the visa's validity period. After marrying
                          their Australian fiancé(e), the visa holder must apply
                          for a Partner Visa (Subclass 820/801) to continue
                          living in Australia. The Partner Visa application
                          should be submitted before the Prospective Marriage
                          Visa (Subclass 300) expires.
                        </p>

                        <h4 className="bolder text-black  text-start my-5 mb-4">
                          Requirements
                        </h4>

                        <ol className="">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Sponsorship: The Australian fiancé(e) must act as
                              the visa sponsor and be willing to support the
                              applicant during their stay in Australia.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Intention to marry: Applicants must genuinely
                              intend to marry their Australian fiancé(e) within
                              nine months of the visa grant date. They must
                              provide evidence of their intention, such as a
                              signed and dated notice of intended marriage,
                              wedding invitations, or other supporting
                              documents.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Relationship: Applicants must be in a genuine and
                              ongoing relationship with their Australian
                              fiancé(e) and provide evidence of their
                              commitment, such as photographs, correspondence,
                              and supporting statements from friends and family.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Age requirement: Both the applicant and the
                              Australian fiancé(e) must be at least 18 years
                              old.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Health and character requirements: Applicants must
                              meet specific health and character requirements,
                              including undergoing a medical examination and
                              obtaining police clearance certificates from
                              countries they have lived in for more than 12
                              months.
                            </div>
                          </li>
                        </ol>
                        <h4 className="bolder text-black  text-start mt-5 mb-4">
                          Once the Prospective Marriage Visa (Subclass 300) is
                          granted, the applicant can:
                        </h4>

                        <ol className=" mt-3">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Enter Australia and marry their fiancé(e) within
                              the visa's nine-month validity period.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Work and study in Australia during the visa's
                              validity period.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Travel to and from Australia as many times as they
                              wish while the visa is valid.
                            </div>
                          </li>
                        </ol>
                      </div>

                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "ParentVisa" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="text-start">The Parent Visa</h2>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-4">
                          The Parent Visa category allows parents of Australian
                          citizens, permanent residents, or eligible New Zealand
                          citizens to live in Australia either temporarily or
                          permanently. There are several subclasses of Parent
                          Visas with varying eligibility criteria and processing
                          times.
                        </p>
                      </div>

                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "NewZealandCitizen" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="text-start">
                            The New Zealand Citizen Family Relationship Visa
                          </h2>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-4">
                          The New Zealand Citizen Family Relationship Visa
                          (Subclass 461) is a temporary visa designed for
                          non-New Zealand family members of New Zealand citizens
                          who live and work in Australia on a Special Category
                          Visa (Subclass 444). This visa allows eligible family
                          members to accompany their New Zealand citizens
                          relative to Australia and live, work, and study in the
                          country.
                        </p>
                        <p className="textColorDefault normal  justifyText mt-4">
                          The New Zealand Citizen Family Relationship Visa
                          (Subclass 461) is granted for up to five years and can
                          be renewed as long as the applicant continues to meet
                          the eligibility criteria.
                        </p>
                      </div>

                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "Subclass500" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="text-start">
                            The Student Visa (Subclass 500)
                          </h2>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-4">
                          The Student Visa Subclass 500 is a specialized visa
                          category designed for non-Australian citizens who wish
                          to pursue full-time education in Australia. This visa
                          enables international students to study a wide range
                          of courses at various educational institutions,
                          including universities, vocational education and
                          training providers, and English language schools. The
                          Subclass 500 visa also encompasses those participating
                          in student exchange programs or pursuing a full-time,
                          registered course of study in Australia.
                        </p>
                        {/* <p className="textColorDefault normal  justifyText mt-4">
                          This visa allows the guardian to stay in Australia for
                          the duration of the student's course or until the
                          student turns 18.
                        </p> */}
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h4 className="text-start">
                            Eligibility requirements:
                          </h4>
                        </div>

                        <ol className="">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Meet age requirement: There is no specific age
                              requirement to be an applicant for the student
                              visa, however, the applicant should be above 18
                              years old to be an independent applicant. If the
                              applicant is below 18 years old, they should have
                              welfare arrangements in place.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Academic Documents: Have all relevant academic
                              documents documented.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              English language proficiency: You must demonstrate
                              sufficient English language skills to undertake
                              your chosen course. This can be proven through
                              various tests, including the International English
                              Language Testing System (IELTS), Test of English
                              as a Foreign Language (TOEFL), Pearson Test of
                              English (PTE), and Cambridge Advanced English
                              (CAE).
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Offer Letter: This is an initial step for the
                              Enrollment where the university/college would
                              accept you as a student on the nominated course
                              you wish to study.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Financial capacity: You must provide evidence that
                              you have enough funds to cover your tuition fees,
                              living expenses, and travel costs for the duration
                              of your stay in Australia.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Genuine Temporary Entrant (GTE) requirement: You
                              must demonstrate that your main purpose for coming
                              to Australia is to study and that you intend to
                              return to your home country after your studies.
                              The Department of Home Affairs considers factors
                              such as your immigration history, circumstances in
                              your home country, potential employment
                              opportunities, and your plans after completing the
                              course.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Confirmation of Enrollment (CoE): You must provide
                              evidence of Enrolllment in a full-time course
                              registered with the Commonwealth Register of
                              Institutions and Courses for Overseas Students
                              (CRICOS). The institution will issue a
                              Confirmation of Enrollment after you have accepted
                              their offer and paid the required fees.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Health insurance: You must obtain Overseas Student
                              Health Cover (OSHC) for the duration of your stay
                              in Australia, which provides basic medical and
                              hospital coverage.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Duration and conditions: The Subclass 500 visa is
                              typically granted for the entire duration of the
                              chosen course, plus an additional one or two
                              months to allow for travel time. Visa holders must
                              maintain full-time Enrollment, adhere to their
                              educational institution's attendance policies, and
                              make satisfactory progress in their course of
                              study.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Work rights: The Subclass 500 visa allows students
                              to work up to 48 hours per fortnight during the
                              academic session and unlimited hours during
                              scheduled vacations. These work rights commence
                              once the course has started.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Family members: Applicants can include their
                              spouse or partner and dependent children in their
                              visa application as secondary applicants. These
                              family members must meet the same health,
                              character, and financial requirements as the
                              primary applicant.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Post-study work opportunities: After completing
                              their studies, international students may be
                              eligible to apply for the Temporary Graduate Visa
                              (Subclass 485) or other skilled migration visas,
                              allowing them to stay and work in Australia
                              temporarily and gain valuable work experience.
                            </div>
                          </li>
                        </ol>
                      </div>
                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "PartnerVisa820" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="text-start">
                            The Partner Visa (apply in Australia - Subclass 820
                            and 801)
                          </h2>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-4">
                          The Partner Visa (Subclass 820 and 801) is designed
                          for individuals who are in a genuine relationship with
                          an Australian citizen, permanent resident, or eligible
                          New Zealand citizen and wish to live in Australia. The
                          Partner Visa is a two-stage process, starting with the
                          temporary Subclass 820 visa, followed by the permanent
                          Subclass 801 visa.
                        </p>
                        {/* <p className="textColorDefault normal  justifyText mt-4">
                          This visa allows the guardian to stay in Australia for
                          the duration of the student's course or until the
                          student turns 18.
                        </p> */}
                      </div>

                      <div className="col-lg-12">
                        {/* <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h4 className="text-start">
                            Eligibility requirements:
                          </h4>
                        </div> */}

                        <ol className=" mt-3">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Partner Visa (Temporary) - Subclass 820: This is a
                              temporary visa that allows the applicant to live
                              and work in Australia while their permanent
                              Partner Visa application is being processed. The
                              application for both the Subclass 820 and Subclass
                              801 visas is submitted simultaneously, but the
                              Subclass 820 visa is granted first.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Partner Visa (Permanent) - Subclass 801: This is
                              the permanent stage of the Partner Visa process.
                              The permanent Subclass 801 visa is usually
                              assessed two years after the initial application,
                              allowing the Department of Home Affairs to
                              evaluate the ongoing genuineness of the
                              relationship.
                            </div>
                          </li>
                        </ol>
                      </div>
                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "Subclass590" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="text-start">
                            The Student Visa Subclass 590
                          </h2>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-4">
                          The Student Guardian Visa (Subclass 590) is a type of
                          visa designed for individuals who need to accompany
                          and provide care for a student studying in Australia
                          under the age of 18 or, in exceptional circumstances,
                          for students over 18 who need special assistance.This
                          visa allows the guardian to stay in Australia for the
                          duration of the student's course or until the student
                          turns 18.
                        </p>
                      </div>

                      {/* <div className="col-lg-12">
                <div className="innerSecondSection mt-5 d-flex  mb-3">
                  <h4 className="text-start">Upon the grant of the Partner (Migrant) Visa (Subclass 100), the applicant can:</h4>
                </div>
                <p className="bolder  text-start mt-4">
                Enter and live in Australia until a decision is made on their permanent Partner (Migrant) Visa (Subclass 100). 
                </p>
                <ol className="">
                  <li>
                    <div className="textColorGray d-flex me-auto ps-2 justifyText">
                    Work and study in Australia. 
                    </div>
                  </li>
                  <li className="mt-3">
                    <div className="textColorGray d-flex me-auto ps-2 justifyText">
                    Enroll in Medicare, Australia's public healthcare system.
                    </div>
                  </li>
                </ol> 
                <h4 className="bolder text-black  text-start mt-5">
                Requirements
                </h4>

                <ol className=" mt-3">
                  <li>
                    <div className=" d-flex me-auto ps-2 justifyText">
                    Relationship:- Applicants must be married or in a de facto relationship with an Australian citizen, permanent resident, or eligible New Zealand citizen. De facto relationships must have existed for at least 12 months before applying unless there are compelling reasons for waiving this requirement. 
                    </div>
                  </li>
                  <li className="mt-3">
                    <div className=" d-flex me-auto ps-2 justifyText">
                    Sponsorship:- The Australian partner must act as the visa sponsor and be willing to support the applicant and any dependent family members included in the application. 
                    </div>
                  </li>
                  <li className="mt-3">
                    <div className=" d-flex me-auto ps-2 justifyText">
                    Relationship evidence:- Applicants must provide evidence of a genuine and ongoing relationship, including financial, social, and emotional aspects. This can include joint bank accounts, photographs, shared living arrangements, and supporting statements from friends and family. 
                    </div>
                  </li>
                  <li className="mt-3">
                    <div className=" d-flex me-auto ps-2 justifyText">
                    Health and character requirements:- Applicants must meet specific health and character requirements, including undergoing a medical examination and obtaining police clearance certificates from countries they have lived in for more than 12 months.
                    </div>
                  </li>
                </ol> 
              </div>

              <div className="col-lg-12">
                <div className="innerSecondSection mt-5 d-flex  mb-3">
                  <h2 className="text-start">FAQ about family visa</h2>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <div className="col-lg-6">
                    <img
                      src={VisaBG1}
                      className="img-fluid pt-3 d-flex "
                    />
                  </div>
                  <div className="col-lg-6">
                    <p className="textColorGray  text-start mt-2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since the 1500s,
                      when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book. It has
                      survived not only five centuries, but also the leap
                      into electronic typesetting, remaining essentially
                      unchanged
                    </p>
                    <p className="textColorGray  text-start mt-2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since the 1500s,
                      when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="innerHowMuchSection pt-3 d-flex  mt-4">
                  <Row>
                    <div className="d-flex align-items-center">
                      <img
                        src={bullet}
                        width="20"
                        height="20"
                        className="mb-2"
                      />
                      <h4 className="ms-3">
                        How Much This Journey Will Cost You?
                      </h4>
                    </div>
                    <p className="textColorGray text-start mt-2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                      industry. It has survived not only five centuries,
                      but also the leap into electronic typesetting,
                      remaining essentially unchanged.
                    </p>

                    <div className="d-flex align-items-center mt-4">
                      <img
                        src={bullet}
                        width="20"
                        height="20"
                        className="mb-2"
                      />
                      <h4 className="ms-3">
                        How to take time in the procesing of registration?
                      </h4>
                    </div>
                    <p className="textColorGray text-start mt-2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                      industry. It has survived not only five centuries,
                      but also the leap into electronic typesetting,
                      remaining essentially unchanged.
                    </p>
                  </Row>
                </div>
              </div> */}

                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "Subclass407" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="text-start">
                            The Training Visa (Subclass 407)
                          </h2>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-4">
                          The Training Visa (Subclass 407) is designed for
                          non-Australian citizens who wish to participate in
                          workplace-based occupational training or professional
                          development activities in Australia. The primary
                          purpose of this visa is to allow individuals to
                          improve their professional skills, enhance their work
                          experience, or develop their expertise in a specific
                          field.
                        </p>
                      </div>

                      {/* <div className="col-lg-12">
                <div className="innerSecondSection mt-5 d-flex  mb-3">
                  <h4 className="text-start">Upon the grant of the Partner (Migrant) Visa (Subclass 100), the applicant can:</h4>
                </div>
                <p className="bolder  text-start mt-4">
                Enter and live in Australia until a decision is made on their permanent Partner (Migrant) Visa (Subclass 100). 
                </p>
                <ol className="">
                  <li>
                    <div className="textColorGray d-flex me-auto ps-2 justifyText">
                    Work and study in Australia. 
                    </div>
                  </li>
                  <li className="mt-3">
                    <div className="textColorGray d-flex me-auto ps-2 justifyText">
                    Enroll in Medicare, Australia's public healthcare system.
                    </div>
                  </li>
                </ol> 
                <h4 className="bolder text-black  text-start mt-5">
                Requirements
                </h4>

                <ol className=" mt-3">
                  <li>
                    <div className=" d-flex me-auto ps-2 justifyText">
                    Relationship:- Applicants must be married or in a de facto relationship with an Australian citizen, permanent resident, or eligible New Zealand citizen. De facto relationships must have existed for at least 12 months before applying unless there are compelling reasons for waiving this requirement. 
                    </div>
                  </li>
                  <li className="mt-3">
                    <div className=" d-flex me-auto ps-2 justifyText">
                    Sponsorship:- The Australian partner must act as the visa sponsor and be willing to support the applicant and any dependent family members included in the application. 
                    </div>
                  </li>
                  <li className="mt-3">
                    <div className=" d-flex me-auto ps-2 justifyText">
                    Relationship evidence:- Applicants must provide evidence of a genuine and ongoing relationship, including financial, social, and emotional aspects. This can include joint bank accounts, photographs, shared living arrangements, and supporting statements from friends and family. 
                    </div>
                  </li>
                  <li className="mt-3">
                    <div className=" d-flex me-auto ps-2 justifyText">
                    Health and character requirements:- Applicants must meet specific health and character requirements, including undergoing a medical examination and obtaining police clearance certificates from countries they have lived in for more than 12 months.
                    </div>
                  </li>
                </ol> 
              </div>

              <div className="col-lg-12">
                <div className="innerSecondSection mt-5 d-flex  mb-3">
                  <h2 className="text-start">FAQ about family visa</h2>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <div className="col-lg-6">
                    <img
                      src={VisaBG1}
                      className="img-fluid pt-3 d-flex "
                    />
                  </div>
                  <div className="col-lg-6">
                    <p className="textColorGray  text-start mt-2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since the 1500s,
                      when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book. It has
                      survived not only five centuries, but also the leap
                      into electronic typesetting, remaining essentially
                      unchanged
                    </p>
                    <p className="textColorGray  text-start mt-2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since the 1500s,
                      when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="innerHowMuchSection pt-3 d-flex  mt-4">
                  <Row>
                    <div className="d-flex align-items-center">
                      <img
                        src={bullet}
                        width="20"
                        height="20"
                        className="mb-2"
                      />
                      <h4 className="ms-3">
                        How Much This Journey Will Cost You?
                      </h4>
                    </div>
                    <p className="textColorGray text-start mt-2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                      industry. It has survived not only five centuries,
                      but also the leap into electronic typesetting,
                      remaining essentially unchanged.
                    </p>

                    <div className="d-flex align-items-center mt-4">
                      <img
                        src={bullet}
                        width="20"
                        height="20"
                        className="mb-2"
                      />
                      <h4 className="ms-3">
                        How to take time in the procesing of registration?
                      </h4>
                    </div>
                    <p className="textColorGray text-start mt-2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                      industry. It has survived not only five centuries,
                      but also the leap into electronic typesetting,
                      remaining essentially unchanged.
                    </p>
                  </Row>
                </div>
              </div> */}

                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "WorkingVisa" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="text-start">
                            The Working and Skilled Visas
                          </h2>
                        </div>
                        <p className="textColorDefault normal  text-start mt-4">
                          Australia offers a variety of working and skilled
                          visas to attract skilled workers, professionals, and
                          entrepreneurs who can contribute to the country's
                          economy and workforce. These visas allow individuals
                          to live, work, and sometimes even settle permanently
                          in Australia, depending on the specific visa Subclass.
                          Here is an overview of some of the key working and
                          skilled visa categories:
                        </p>
                        <p className="textColorDefault normal  justifyText mt-4">
                          Temporary Skill Shortage Visa (Subclass 482): This
                          visa allows Australian employers to sponsor skilled
                          overseas workers to fill temporary skill shortages in
                          their businesses. The visa has three streams:
                          Short-term, Medium-term, and Labour Agreement. The
                          duration and eligibility requirements vary for each
                          stream.
                        </p>
                        <p className="textColorDefault normal  justifyText mt-4">
                          Skilled Independent Visa (Subclass 189): This
                          points-tested permanent residency visa is designed for
                          skilled workers who want to live and work in Australia
                          independently, without the need for sponsorship from
                          an employer, a state or territory, or a family member.
                        </p>
                        <p className="textColorDefault normal  justifyText mt-4">
                          Skilled Nominated Visa (Subclass 190): This
                          points-tested permanent residency visa is for skilled
                          workers who are nominated by an Australian state or
                          territory government. Applicants must meet the
                          eligibility criteria and have an occupation on the
                          relevant skilled occupation list.
                        </p>
                        <p className="textColorDefault normal  justifyText mt-4">
                          Skilled Work Regional (Provisional) Visa (Subclass
                          491): This points-tested provisional visa is for
                          skilled workers who want to live and work in regional
                          Australia. Applicants must be nominated by an
                          Australian state or territory government or sponsored
                          by an eligible family member residing in a designated
                          regional area.
                        </p>
                        <p className="textColorDefault normal  justifyText mt-4">
                          Skilled Regional (Permanent) Visa (Subclass 191): This
                          permanent residency visa is for individuals who have
                          lived and worked in regional Australia on Subclass 491
                          or Subclass 494 visa for at least three years and met
                          the minimum income requirement. This visa allows them
                          to transition to permanent residency.
                        </p>
                        <p className="textColorDefault normal  justifyText mt-4">
                          Employer Nomination Scheme (Subclass 186): This
                          permanent residency visa is for skilled workers who
                          are nominated by an Australian employer. It has three
                          streams: Direct Entry, Temporary Residence Transition,
                          and Labour Agreement.
                        </p>
                        <p className="textColorDefault normal  justifyText mt-4">
                          Regional Sponsored Migration Scheme (Subclass 187):
                          This permanent residency visa is for skilled workers
                          who are nominated by an employer in regional
                          Australia. It has two streams: Direct Entry and
                          Temporary Residence Transition.
                        </p>
                        <p className="textColorDefault normal  justifyText mt-4">
                          Business Innovation and Investment visas (Subclass 188
                          and 888): These visas are for entrepreneurs and
                          investors who want to establish, develop, or invest in
                          a business in Australia. The Subclass 188 visa is a
                          provisional visa, while the Subclass 888 visa is a
                          permanent visa, granted after meeting specific
                          requirements.{" "}
                        </p>
                      </div>
                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "Subclass189" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="text-start">
                            The Skilled Independent Visa (Subclass 189)
                          </h2>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-4">
                          The Skilled Independent Visa (Subclass 189) is a
                          points-tested permanent residency visa designed for
                          skilled workers who want to live and work in Australia
                          independently, without the need for sponsorship from
                          an employer, a state or territory, or a family member.
                          This visa allows successful applicants to settle in
                          Australia and enjoy the benefits of permanent
                          residency, such as access to healthcare, education,
                          and social security.
                        </p>
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3 mb-3">
                          <h4 className="text-start">
                            The Skilled Independent Visa (Subclass 189) allows
                            successful applicants to:
                          </h4>
                        </div>

                        <ol className="">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Live, work, and study in Australia as a permanent
                              resident.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Enroll in Medicare, Australia's public healthcare
                              system.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Sponsor eligible family members for permanent
                              residency.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Apply for Australian citizenship after meeting the
                              residency requirements.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Travel to and from Australia for five years from
                              the date the visa is granted (after which they
                              will need to obtain a Resident Return Visa or
                              another suitable visa to re-enter Australia)
                            </div>
                          </li>
                        </ol>
                      </div>

                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "Subclass190" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="text-start">
                            The Skilled Nominated Visa (Subclass 190)
                          </h2>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-4">
                          The Skilled Nominated Visa (Subclass 190) is a
                          points-tested permanent residency visa for skilled
                          workers who have been nominated by an Australian state
                          or territory government. This visa allows successful
                          applicants to live, work, and study in Australia as
                          permanent residents, contributing their skills to the
                          local workforce and economy.
                        </p>
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3 mb-3">
                          <h4 className="text-start">
                            The Skilled Nominated Visa (Subclass 190) allows
                            successful applicants to:
                          </h4>
                        </div>

                        <ol className="">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Live, work, and study in Australia as a permanent
                              resident.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Enroll in Medicare, Australia's public healthcare
                              system.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Sponsor eligible family members for permanent
                              residency.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Apply for Australian citizenship after meeting the
                              residency requirements.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Travel to and from Australia for five years from
                              the date the visa is granted (after which they
                              will need to obtain a Resident Return Visa or
                              another suitable visa to re-enter Australia).
                            </div>
                          </li>
                        </ol>
                      </div>

                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "Subclass491" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="text-start">
                            The Skilled Regional (Provisional) Visa (Subclass
                            491)
                          </h2>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-4">
                          The Skilled Regional (Provisional) Visa (Subclass 491)
                          is a points-tested provisional visa designed for
                          skilled workers who want to live and work in regional
                          Australia. Applicants must be nominated by an
                          Australian state or territory government or sponsored
                          by an eligible family member residing in a designated
                          regional area. This visa aims to promote economic
                          development in regional areas and address skills
                          shortages by attracting skilled migrants.
                        </p>

                        <p className="textColorDefault normal  justifyText mt-4">
                          Visa holders are required to live, work, and study
                          only in designated regional areas and notify the
                          Department of Home Affairs of any changes in their
                          address or other circumstances.
                        </p>
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3 mb-3">
                          <h4 className="text-start">
                            The Skilled Regional (Provisional) Visa (Subclass
                            491) allows successful applicants to:
                          </h4>
                        </div>

                        <ol className="">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Live, work, and study in a designated regional
                              area of Australia for up to five years.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Travel to and from Australia while the visa is
                              valid.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Apply for the Skilled Regional (Permanent) Visa
                              (Subclass 191) after meeting specific
                              requirements, including living and working in a
                              regional area for at least three years and meeting
                              the minimum income requirement.
                            </div>
                          </li>
                        </ol>

                        {/* <h4 className="bolder text-black  text-start mt-5">
                          Requirements
                        </h4>

                        <ol className=" mt-3">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Points test: Applicants must score at least 65
                              points on the Points Test, which assesses factors
                              such as age, English language proficiency, work
                              experience, education, and other relevant
                              qualifications. The Points Test is competitive,
                              and higher scores increase the chances of
                              receiving an invitation to apply for a visa.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              State or territory nomination: Applicants must be
                              nominated by an Australian state or territory
                              government. Each state or territory has its own
                              eligibility criteria, skills shortage lists, and
                              requirements for nomination.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Occupation: Applicants must nominate an occupation
                              on the relevant skilled occupation list for the
                              state or territory that nominates them. They must
                              also have a suitable skills assessment from a
                              relevant assessing authority for their nominated
                              occupation.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Age: Applicants must be under 45 years old at the
                              time of receiving an invitation to apply for the
                              visa.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              English language proficiency: Applicants must
                              demonstrate a competent level of English language
                              proficiency through recognized tests such as
                              IELTS, TOEFL, PTE, or CAE.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Health and character requirements: Applicants must
                              meet specific health and character requirements,
                              including undergoing a medical examination and
                              obtaining police clearance certificates from
                              countries they have lived in for more than 12
                              months.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Expression of Interest (EOI): Applicants must
                              submit an Expression of Interest (EOI) through the
                              SkillSelect online system, providing information
                              about their skills, work experience, and other
                              relevant qualifications. If their EOI is selected
                              and they receive a nomination from a state or
                              territory, they will be invited to apply for the
                              visa.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Invitation to apply: After receiving an invitation
                              to apply, applicants have 60 days to submit their
                              visa application and provide all the necessary
                              supporting documents.
                            </div>
                          </li>
                        </ol> */}
                      </div>
                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "Subclass191" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3 m">
                          <h2 className="text-start">
                            The Permanent Residence (Skilled Regional) Visa
                            (Subclass 191)
                          </h2>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-4">
                          The Permanent Residence (Skilled Regional) Visa
                          (Subclass 191) is a permanent visa designed for
                          skilled workers who have lived, worked, and held a
                          valid regional provisional visa (either a Subclass 491
                          or Subclass 494 visa) in a designated regional area of
                          Australia. The purpose of this visa is to encourage
                          skilled migrants to settle and contribute to the
                          economic development of regional Australia.
                        </p>
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3 mb-3">
                          <h4 className="text-start">
                            Once the Permanent Residence (Skilled Regional) Visa
                            (Subclass 191) is granted, the visa holder can:
                          </h4>
                        </div>

                        <ol className="">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Live, work, and study in Australia as a permanent
                              resident.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Access Medicare, Australia's public healthcare
                              system.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Apply for Australian citizenship after meeting the
                              residency requirements.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Sponsor eligible relatives for permanent residency
                              in Australia.
                            </div>
                          </li>
                        </ol>
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3 mb-3">
                          <h4 className="text-start">Requirements</h4>
                        </div>

                        <ol className="">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Eligibility: Applicants must hold a valid Subclass
                              491 or Subclass 494 visa at the time of
                              application.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Regional residence and work: Applicants must have
                              lived in a designated regional area of Australia
                              for at least three years while holding a Subclass
                              491 or Subclass 494 visa. They must also have
                              worked in a designated regional area for at least
                              three years, earning a minimum income specified by
                              the Department of Home Affairs.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Income Requirement: Applicants must demonstrate
                              that they have met the minimum taxable income
                              requirement for three years while holding a
                              Subclass 491 or Subclass 494 visa.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Visa compliance: Applicants must have complied
                              with the conditions of their Subclass 491 or
                              Subclass 494 visa.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Health and character requirements: Applicants must
                              meet specific health and character requirements,
                              including undergoing a medical examination and
                              obtaining police clearance certificates from
                              countries they have lived in for more than 12
                              months.
                            </div>
                          </li>
                        </ol>
                      </div>
                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "Subclass888" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="text-start">
                            The Business Innovation and Investment (Permanent)
                            Visa (Subclass 888)
                          </h2>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-4">
                          The Business Innovation and Investment (Permanent)
                          Visa (Subclass 888) is a permanent residency visa for
                          individuals who have successfully met the requirements
                          of their provisional Business Innovation and
                          Investment (Provisional) Visa (Subclass 188). This
                          visa allows successful applicants to continue owning
                          and managing a business or investing in Australia as
                          permanent residents.
                        </p>
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h4 className="text-start">
                            The Business Innovation and Investment (Permanent)
                            Visa (Subclass 888) allows successful applicants to:
                          </h4>
                        </div>

                        <ol className="">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Live, work, and study in Australia as permanent
                              residents.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Enroll in Medicare, Australia's public healthcare
                              system.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Sponsor eligible family members for permanent
                              residency.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Apply for Australian citizenship after meeting the
                              residency requirements.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Travel to and from Australia for five years from
                              the date the visa is granted (after which they
                              will need to obtain a Resident Return Visa or
                              another suitable visa to re-enter Australia).
                            </div>
                          </li>
                        </ol>
                      </div>

                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "Subclass188" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="text-start">
                            The Business Innovation and Investment (Provisional)
                            Visa (Subclass 188)
                          </h2>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-4 pb-0 mb-0">
                          The Business Innovation and Investment (Provisional)
                          Visa (Subclass 188) is a temporary visa for
                          entrepreneurs, investors, and business owners who want
                          to establish, develop, or invest in a business in
                          Australia. This visa allows successful applicants to
                          own and manage a business, or make investments in
                          Australia for a specified period before becoming
                          eligible to apply for the permanent Business
                          Innovation and Investment (Permanent) Visa (Subclass
                          888).
                        </p>
                        <p className="textColorDefault normal  text-start pt-0">
                          The duration of the Subclass 188 visa varies depending
                          on the stream, generally ranging from 4 to 5 years,
                          with a possible extension for certain streams.
                        </p>
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h4 className="text-start">
                            The Business Innovation and Investment (Provisional)
                            Visa (Subclass 188) allows successful applicants to:{" "}
                          </h4>
                        </div>

                        <ol className="">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Establish, develop, or invest in a business in
                              Australia.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Travel in and out of Australia while the visa is
                              valid.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Include their spouse or partner, and dependent
                              children in the visa application.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Apply for the Business Innovation and Investment
                              (Permanent) Visa (Subclass 888) after meeting
                              specific requirements.
                            </div>
                          </li>
                        </ol>
                      </div>

                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "Subclass485" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="text-start">
                            The Temporary Graduate Visa (Subclass 485)
                          </h2>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-4">
                          The Temporary Graduate Visa (Subclass 485) is a
                          temporary visa for international students who have
                          recently graduated from an Australian educational
                          institution. This visa allows graduates to live, work,
                          and study in Australia temporarily, providing them
                          with an opportunity to gain practical experience in
                          their field and enhance their skills.
                        </p>
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-3 d-flex  mb-3">
                          <h4 className="text-start">
                            The Subclass 485 visa has two streams:
                          </h4>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-3">
                          Graduate Work stream: This stream is for international
                          students who have recently graduated with skills and
                          qualifications that are closely related to an
                          occupation on the Skilled Occupation List (SOL). This
                          visa is valid for 18 months from the date of the
                          grant.
                        </p>

                        <p className="textColorDefault normal  justifyText mt-4">
                          Post-Study Work stream: This stream is for
                          international students who have recently graduated
                          with a degree from an Australian institution,
                          regardless of their field of study. The duration of
                          this visa depends on the type of qualification
                          completed: two years for a Bachelor's or Master's
                          degree, three years for a Master's by research, and
                          four years for a Doctoral degree.
                        </p>

                        <h4 className="text-start  mt-3">Requirements</h4>

                        <ol className=" mt-3">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Australian study requirement: Applicants must have
                              completed at least two academic years (92 weeks)
                              of study in a course or courses registered on the
                              Commonwealth Register of Institutions and Courses
                              for Overseas Students (CRICOS).
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Qualifications: Applicants must have completed a
                              relevant qualification within six months of
                              applying for the visa. For the Graduate Work
                              stream, the qualification must be related to an
                              occupation on the Skilled Occupation List (SOL).
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              English language proficiency: Applicants must
                              demonstrate a competent level of English language
                              proficiency through recognized tests such as
                              IELTS, TOEFL, PTE, or CAE.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Age: Applicants must be under 50 years of age at
                              the time of application.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Visa eligibility: Applicants must hold an eligible
                              visa (typically a student visa) at the time of
                              application.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Skills assessment (for the Graduate Work stream):
                              Applicants for the Graduate Work stream must have
                              a positive skills assessment for their nominated
                              occupation from a relevant assessing authority.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Health and character requirements: Applicants must
                              meet specific health and character requirements,
                              including undergoing a medical examination and
                              obtaining police clearance certificates from
                              countries they have lived in for more than 12
                              months.
                            </div>
                          </li>
                        </ol>

                        <h4 className="text-start  mt-5">
                          The Temporary Graduate Visa (Subclass 485) allows
                          successful applicants to:
                        </h4>

                        <ol className=" mt-3 ">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Live, work, and study in Australia temporarily.
                            </div>
                          </li>
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Travel in and out of Australia while the visa is
                              valid.
                            </div>
                          </li>
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Include their spouse or partner, and dependent
                              children in the visa application.
                            </div>
                          </li>
                        </ol>
                        {/* <h4 className="bolder text-black  text-start mt-5">
                  Requirements
                  </h4>

                  <ol className=" mt-3">
                    <li>
                      <div className=" d-flex me-auto ps-2 justifyText">
                      Relationship:- Applicants must be married or in a de facto relationship with an Australian citizen, permanent resident, or eligible New Zealand citizen. De facto relationships must have existed for at least 12 months before applying unless there are compelling reasons for waiving this requirement. 
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className=" d-flex me-auto ps-2 justifyText">
                      Sponsorship:- The Australian partner must act as the visa sponsor and be willing to support the applicant and any dependent family members included in the application. 
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className=" d-flex me-auto ps-2 justifyText">
                      Relationship evidence:- Applicants must provide evidence of a genuine and ongoing relationship, including financial, social, and emotional aspects. This can include joint bank accounts, photographs, shared living arrangements, and supporting statements from friends and family. 
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className=" d-flex me-auto ps-2 justifyText">
                      Health and character requirements:- Applicants must meet specific health and character requirements, including undergoing a medical examination and obtaining police clearance certificates from countries they have lived in for more than 12 months.
                      </div>
                    </li>
                  </ol>  */}
                      </div>

                      {/* <div className="col-lg-12">
                  <div className="innerSecondSection mt-5 d-flex  mb-3">
                    <h4 className="text-start">Upon the grant of the Partner (Migrant) Visa (Subclass 100), the applicant can:</h4>
                  </div>
                  <p className="bolder  text-start mt-4">
                  Enter and live in Australia until a decision is made on their permanent Partner (Migrant) Visa (Subclass 100). 
                  </p>
                  <ol className="">
                    <li>
                      <div className="textColorGray d-flex me-auto ps-2 justifyText">
                      Work and study in Australia. 
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="textColorGray d-flex me-auto ps-2 justifyText">
                      Enroll in Medicare, Australia's public healthcare system.
                      </div>
                    </li>
                  </ol> 
                  <h4 className="bolder text-black  text-start mt-5">
                  Requirements
                  </h4>

                  <ol className=" mt-3">
                    <li>
                      <div className=" d-flex me-auto ps-2 justifyText">
                      Relationship:- Applicants must be married or in a de facto relationship with an Australian citizen, permanent resident, or eligible New Zealand citizen. De facto relationships must have existed for at least 12 months before applying unless there are compelling reasons for waiving this requirement. 
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className=" d-flex me-auto ps-2 justifyText">
                      Sponsorship:- The Australian partner must act as the visa sponsor and be willing to support the applicant and any dependent family members included in the application. 
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className=" d-flex me-auto ps-2 justifyText">
                      Relationship evidence:- Applicants must provide evidence of a genuine and ongoing relationship, including financial, social, and emotional aspects. This can include joint bank accounts, photographs, shared living arrangements, and supporting statements from friends and family. 
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className=" d-flex me-auto ps-2 justifyText">
                      Health and character requirements:- Applicants must meet specific health and character requirements, including undergoing a medical examination and obtaining police clearance certificates from countries they have lived in for more than 12 months.
                      </div>
                    </li>
                  </ol> 
                </div>

                <div className="col-lg-12">
                  <div className="innerSecondSection mt-5 d-flex  mb-3">
                    <h2 className="text-start">FAQ about family visa</h2>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="col-lg-6">
                      <img
                        src={VisaBG1}
                        className="img-fluid pt-3 d-flex "
                      />
                    </div>
                    <div className="col-lg-6">
                      <p className="textColorGray  text-start mt-2">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap
                        into electronic typesetting, remaining essentially
                        unchanged
                      </p>
                      <p className="textColorGray  text-start mt-2">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="innerHowMuchSection pt-3 d-flex  mt-4">
                    <Row>
                      <div className="d-flex align-items-center">
                        <img
                          src={bullet}
                          width="20"
                          height="20"
                          className="mb-2"
                        />
                        <h4 className="ms-3">
                          How Much This Journey Will Cost You?
                        </h4>
                      </div>
                      <p className="textColorGray text-start mt-2">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry. It has survived not only five centuries,
                        but also the leap into electronic typesetting,
                        remaining essentially unchanged.
                      </p>

                      <div className="d-flex align-items-center mt-4">
                        <img
                          src={bullet}
                          width="20"
                          height="20"
                          className="mb-2"
                        />
                        <h4 className="ms-3">
                          How to take time in the procesing of registration?
                        </h4>
                      </div>
                      <p className="textColorGray text-start mt-2">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry. It has survived not only five centuries,
                        but also the leap into electronic typesetting,
                        remaining essentially unchanged.
                      </p>
                    </Row>
                  </div>
                </div> */}

                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "Subclass494" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="text-start">
                            The Skilled Employer Sponsored Regional
                            (Provisional) Visa (Subclass 494)
                          </h2>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-4">
                          The Skilled Employer Sponsored Regional (Provisional)
                          Visa (Subclass 494) is a provisional visa designed for
                          skilled workers who have been sponsored by an approved
                          Australian employer to work in a designated regional
                          area of Australia. This visa aims to address skills
                          shortages in regional areas and promote economic
                          growth by attracting skilled migrants. Visa holders
                          are required to live, work, and study only in
                          designated regional areas and notify the Department of
                          Home Affairs of any changes in their address or other
                          circumstances.
                        </p>
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection  d-flex  mb-3"></div>

                        <h4 className="text-start  mt-4">
                          The Skilled Employer Sponsored Regional (Provisional)
                          Visa (Subclass 494) allows successful applicants to:
                        </h4>

                        <ol className=" mt-3  ">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Live, work, and study in a designated regional
                              area of Australia for up to five years.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Travel to and from Australia while the visa is
                              valid.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Include their spouse or partner, and dependent
                              children in the visa application.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Apply for the Permanent Residence (Skilled
                              Regional) Visa (Subclass 191) after meeting
                              specific requirements, including living and
                              working in a regional area for at least three
                              years and meeting the minimum income requirement.
                            </div>
                          </li>
                        </ol>
                      </div>

                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "Subclass600" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="text-start">
                            The Visitor Visa (Subclass 600)
                          </h2>
                        </div>
                        <p className="textColorDefault normal  justifyText mt-4">
                          The Visitor Visa (Subclass 600) is a temporary visa
                          designed for individuals who want to travel to
                          Australia for tourism, business visitor activities, or
                          visiting family and friends. The visa allows eligible
                          applicants to visit Australia for a specified period,
                          generally ranging from three months to one year,
                          depending on the purpose of the visit and the
                          applicant's circumstances.
                        </p>
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h4 className="text-start">
                            There are four different streams within the Subclass
                            600 visa:
                          </h4>
                        </div>
                        <ol className="">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Tourist Stream: This stream is for people who want
                              to visit Australia for holidays, sightseeing, or
                              recreational purposes. It may also be used for
                              short, non-work-related studies or training.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Business Visitor Stream: This stream is for
                              business people who need to attend conferences,
                              meetings, or explore business opportunities in
                              Australia. It does not allow the visa holder to
                              work in Australia.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Sponsored Family Stream: This stream is for people
                              visiting family members in Australia who are
                              willing to sponsor their visit. The Australian
                              family member must provide a financial guarantee
                              and meet specific requirements.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Approved Destination Status Stream: This stream is
                              for citizens from specific countries travelling to
                              Australia as part of an organized tour group.
                            </div>
                          </li>
                        </ol>
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h4 className="text-start">Requirements</h4>
                        </div>

                        <ol className=" mt-3">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Genuine visitor: Applicants must demonstrate that
                              they genuinely intend to visit Australia for
                              tourism, business visitor activities, or to visit
                              family and friends, and that they will not engage
                              in activities that are not allowed under the visa
                              conditions.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Financial capacity: Applicants must have enough
                              funds to support themselves during their stay in
                              Australia, including accommodation, living
                              expenses, and travel costs.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Health and character requirements: Applicants must
                              meet specific health and character requirements,
                              including undergoing a medical examination if
                              necessary, and obtaining police clearance
                              certificates from countries they have lived in for
                              more than 12 months.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Health insurance: Some applicants, particularly
                              those who are older or have pre-existing medical
                              conditions, may be required to have health
                              insurance for the duration of their stay in
                              Australia.
                            </div>
                          </li>
                        </ol>
                      </div>

                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "WorkingHolidayVisa" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="text-start">
                            The Working Holiday Visa
                          </h2>
                        </div>
                        <p className="textColorDefault bolder  text-start mt-4">
                          The Working Holiday Visa program allows young
                          travellers from eligible countries to work and travel
                          in Australia for an extended period. There are two
                          types of Working Holiday Visas:
                        </p>
                      </div>

                      <div className="col-lg-12">
                        <ol className=" mt-5">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Working Holiday Visa (Subclass 417): This visa is
                              for individuals aged 18 to 30 (or 18 to 35 for
                              Canadian, French, and Irish citizens) from
                              eligible countries who want to work and travel in
                              Australia for up to 12 months. This visa can be
                              extended for a second or third year if specific
                              work requirements are met.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Work and Holiday Visa (Subclass 462): This visa is
                              for individuals aged 18 to 30 from eligible
                              countries with specific educational and language
                              requirements who want to work and travel in
                              Australia for up to 12 months. Like the Subclass
                              417 visa, this visa can also be extended for a
                              second or third year if specific work requirements
                              are met.
                            </div>
                          </li>
                        </ol>

                        {/* <h4 className="bolder text-black  text-start mt-5">
                          Partner Visa (apply in Australia - Subclass 820 and
                          801)
                        </h4>

                        <p className="textColorDefault normal  text-start mt-4">
                          The Partner Visa (Subclass 820 and 801) is designed
                          for individuals who are in a genuine relationship with
                          an Australian citizen, permanent resident, or eligible
                          New Zealand citizen and wish to live in Australia. The
                          Partner Visa is a two-stage process, starting with the
                          temporary Subclass 820 visa, followed by the permanent
                          Subclass 801 visa.
                        </p>

                        <p className="textColorDefault normal  text-start mt-4">
                          Partner Visa (Permanent) - Subclass 801: This is the
                          permanent stage of the Partner Visa process. The
                          permanent Subclass 801 visa is usually assessed two
                          years after the initial application, allowing the
                          Department of Home Affairs to evaluate the ongoing
                          genuineness of the relationship.
                        </p>

                        <ol className=" mt-3">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Partner Visa (Temporary) - Subclass 820: This is a
                              temporary visa that allows the applicant to live
                              and work in Australia while their permanent
                              Partner Visa application is being processed. The
                              application for both the Subclass 820 and Subclass
                              801 visas is submitted simultaneously, but the
                              Subclass 820 visa is granted first.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Sponsorship:- The Australian partner must act as
                              the visa sponsor and be willing to support the
                              applicant and any dependent family members
                              included in the application.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Relationship evidence:- Applicants must provide
                              evidence of a genuine and ongoing relationship,
                              including financial, social, and emotional
                              aspects. This can include joint bank accounts,
                              photographs, shared living arrangements, and
                              supporting statements from friends and family.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Health and character requirements:- Applicants
                              must meet specific health and character
                              requirements, including undergoing a medical
                              examination and obtaining police clearance
                              certificates from countries they have lived in for
                              more than 12 months.
                            </div>
                          </li>
                        </ol> */}
                      </div>

                      {/* <div className="col-lg-12">
                  <div className="innerSecondSection mt-5 d-flex  mb-3">
                    <h4 className="text-start">Upon the grant of the Partner (Migrant) Visa (Subclass 100), the applicant can:</h4>
                  </div>
                  <p className="bolder  text-start mt-4">
                  Enter and live in Australia until a decision is made on their permanent Partner (Migrant) Visa (Subclass 100). 
                  </p>
                  <ol className="">
                    <li>
                      <div className="textColorGray d-flex me-auto ps-2 justifyText">
                      Work and study in Australia. 
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="textColorGray d-flex me-auto ps-2 justifyText">
                      Enroll in Medicare, Australia's public healthcare system.
                      </div>
                    </li>
                  </ol> 
                  <h4 className="bolder text-black  text-start mt-5">
                  Requirements
                  </h4>

                  <ol className=" mt-3">
                    <li>
                      <div className=" d-flex me-auto ps-2 justifyText">
                      Relationship:- Applicants must be married or in a de facto relationship with an Australian citizen, permanent resident, or eligible New Zealand citizen. De facto relationships must have existed for at least 12 months before applying unless there are compelling reasons for waiving this requirement. 
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className=" d-flex me-auto ps-2 justifyText">
                      Sponsorship:- The Australian partner must act as the visa sponsor and be willing to support the applicant and any dependent family members included in the application. 
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className=" d-flex me-auto ps-2 justifyText">
                      Relationship evidence:- Applicants must provide evidence of a genuine and ongoing relationship, including financial, social, and emotional aspects. This can include joint bank accounts, photographs, shared living arrangements, and supporting statements from friends and family. 
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className=" d-flex me-auto ps-2 justifyText">
                      Health and character requirements:- Applicants must meet specific health and character requirements, including undergoing a medical examination and obtaining police clearance certificates from countries they have lived in for more than 12 months.
                      </div>
                    </li>
                  </ol> 
                </div>

                <div className="col-lg-12">
                  <div className="innerSecondSection mt-5 d-flex  mb-3">
                    <h2 className="text-start">FAQ about family visa</h2>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="col-lg-6">
                      <img
                        src={VisaBG1}
                        className="img-fluid pt-3 d-flex "
                      />
                    </div>
                    <div className="col-lg-6">
                      <p className="textColorGray  text-start mt-2">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap
                        into electronic typesetting, remaining essentially
                        unchanged
                      </p>
                      <p className="textColorGray  text-start mt-2">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="innerHowMuchSection pt-3 d-flex  mt-4">
                    <Row>
                      <div className="d-flex align-items-center">
                        <img
                          src={bullet}
                          width="20"
                          height="20"
                          className="mb-2"
                        />
                        <h4 className="ms-3">
                          How Much This Journey Will Cost You?
                        </h4>
                      </div>
                      <p className="textColorGray text-start mt-2">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry. It has survived not only five centuries,
                        but also the leap into electronic typesetting,
                        remaining essentially unchanged.
                      </p>

                      <div className="d-flex align-items-center mt-4">
                        <img
                          src={bullet}
                          width="20"
                          height="20"
                          className="mb-2"
                        />
                        <h4 className="ms-3">
                          How to take time in the procesing of registration?
                        </h4>
                      </div>
                      <p className="textColorGray text-start mt-2">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry. It has survived not only five centuries,
                        but also the leap into electronic typesetting,
                        remaining essentially unchanged.
                      </p>
                    </Row>
                  </div>
                </div> */}

                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "BridingVisa" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="text-start">The Bridging Visa</h2>
                        </div>
                        <p className="textColorDefault normal  text-start mt-4">
                          A Bridging Visa is a temporary visa that allows
                          individuals to remain lawfully in Australia while they
                          await the outcome of a visa application or make
                          arrangements to leave the country. There are several
                          types of Bridging Visas, each with specific
                          eligibility criteria and conditions.
                        </p>
                      </div>

                      <div className="col-lg-12">
                        <ol className=" mt-5">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Bridging Visa A (BVA - Subclass 010): This visa is
                              granted automatically to individuals who apply for
                              a substantive visa while they are in Australia and
                              already hold a valid substantive visa. The BVA
                              comes into effect when their current substantive
                              visa expires.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Bridging Visa B (BVB - Subclass 020): This visa
                              allows individuals who hold a Bridging Visa A or
                              Bridging Visa B to travel outside Australia for a
                              short period and return without affecting their
                              substantive visa application. The applicant must
                              demonstrate a genuine need for international
                              travel, and the BVB is valid for a specific
                              period.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Bridging Visa C (BVC - Subclass 030): This visa is
                              granted to individuals who apply for a substantive
                              visa while they are in Australia and do not hold a
                              valid substantive visa. The BVC does not allow the
                              visa holder to re-enter Australia if they leave
                              the country.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Bridging Visa D (BVD - Subclass 040 and 041): This
                              visa is for individuals whose substantive visa has
                              expired, and who have become unlawful non-citizens
                              or are about to become unlawful. The BVD provides
                              a short period (usually up to five days) for the
                              individual to either apply for a substantive visa
                              or make arrangements to leave Australia.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Bridging Visa E (BVE - Subclass 050 and 051): This
                              visa is for individuals who are unlawful
                              non-citizens or have had their visa cancelled and
                              need to remain in Australia temporarily while they
                              resolve their immigration status or make
                              arrangements to leave the country.
                            </div>
                          </li>
                        </ol>
                      </div>

                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "BridingVisa1" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={VisaBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3">
                          <h2 className="text-start">
                            The Employer Nomination Scheme (Subclass 186)
                          </h2>
                        </div>
                        <p className="bolder  text-start mt-4">
                          The Employer Nomination Scheme (Subclass 186) is a
                          permanent visa designed for skilled workers who are
                          nominated by an Australian employer. The purpose of
                          this visa is to fill skill shortages in the Australian
                          labour market by attracting high-quality skilled
                          workers from around the world. The Subclass 186 visa
                          has three streams:
                        </p>
                      </div>

                      <div className="col-lg-12">
                        <ol className=" mt-5">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Direct Entry Stream: This stream is for skilled
                              workers who have not worked in Australia before,
                              or only briefly, and have been nominated by an
                              Australian employer to fill a full-time position.{" "}
                            </div>
                          </li>

                          <div className=" d-flex me-auto ps-2 justifyText mt-2">
                            <div className="pe-2">a. </div>Temporary Residence
                            Transition Stream: This stream is for holders of a
                            Temporary Skill Shortage (TSS) visa (Subclass 482)
                            who have worked for their nominating employer for at
                            least three years and have been offered a permanent
                            position by the employer.
                          </div>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                              Labour Agreement Stream: This stream is for
                              skilled workers who have been nominated by an
                              employer with a Labour Agreement, which is a
                              formal agreement between the employer and the
                              Australian government allowing the employer to
                              sponsor overseas workers for specific positions.
                            </div>
                          </li>
                        </ol>
                      </div>

                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                          <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button
                            className="mt-3 lightAlice border-0 textColorInfo w-100"
                            onClick={ContactUsPage}
                          >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
