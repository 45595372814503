import React from 'react'
import Navbar from '../Components/Layout/NavBar/NavBar'
import Footer from '../Components/Layout/Footer/Footer'
import BookAnAppointmentBanner from '../Components/BookAnAppointmentPage/BookAnAppointmentBanner/BookAnAppointmentBanner'
import BookAnAppointment from '../Components/BookAnAppointmentPage/BookAnAppointmentPage'

export default function BookAnAppointments() {
  return (
    <>
      <Navbar/>
      <BookAnAppointmentBanner/>
      <BookAnAppointment/>
      <Footer/>
    </>
  )
}
