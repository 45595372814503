// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../Assets/Images/ContactusBanner.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContactUsbanner{\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") !important;\n}", "",{"version":3,"sources":["webpack://./src/Components/ContactUsPage/ContactUs_Banner/Banner.css"],"names":[],"mappings":"AAAA;IACI,oEAA8E;AAClF","sourcesContent":[".ContactUsbanner{\n    background-image: url(\"../../../Assets/Images/ContactusBanner.png\") !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
