import React, {useState,useEffect} from "react";
import { useHistory } from "react-router-dom";
import NavBar from "../../Components/Layout/NavBar/NavBar";
import "./Signup.css";
import {history} from '../../Helpers/history'
import Footer from "./../../Components/Layout/Footer/Footer";
import { Container } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { Image,Tooltip,OverlayTrigger } from "react-bootstrap";
import SignupCharacter from "../../Assets/Images/Signupcharacter.png";
import passwordinfo from '../../Assets/Images/infoIcon.png';
import Form from "react-bootstrap/Form";
import Button  from "react-bootstrap/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from 'react-phone-input-2'

import axios  from 'axios';
import { CommonConstants } from './../../Constants/common.constants';
import Successmessageicon from '../../Assets/Images/Success-message-icon.png'
import Errormessageicon from '../../Assets/Images/Error-message-icone.png'



export default function Signup({ children }) {

  const [number,setnumber]=useState('91')
  const [Mobilenumber,setMobilenumber]=useState("")

  useEffect(()=>{
    setMobilenumber("+"+number+document.getElementById("MoNumber").value)
  })

 

  let history= useHistory();
  const [Successhandle , setSuccesshandle] = useState (null);

  const [Errorhandle , setErrorhandle] = useState (null);
  

  const Login=()=>{
    history.push('/login')
    window.scrollTo(0, 0)
}

const [showSuccess, setshowSuccess] = useState(false);
const [showError, setshowError] = useState(false);

const handleSuccessClose = () => setshowSuccess(false);
const handleSuccessShow = () => setshowSuccess(true);

const handleErrorClose = () => setshowError(false);
const handleErrorShow = () => setshowError(true);

const [loading, setLoading] = useState(false);

const onSubmit = async (values) =>{
  try{
    const { confirmPassword, ...data} = values;
    data.mobileNo=Mobilenumber
    console.log(document.getElementById("MoNumber").value)
    const response = await axios.post(CommonConstants.BASE_URL + "/api/auth/createUser", data)
    
    if(response.data.status==="success"){
      setSuccesshandle(response.data.meassage)
      handleSuccessShow()
      setLoading(false);
    }
    else if(response.data.status==="fail"){
      setErrorhandle(response.data.message)
      handleErrorShow()
    }
  }
  catch(err){
    console.log(err)
  }
    
};
const formik = useFormik({
  initialValues: {
    fullName: "",
    email: "",
    mobileNo:"",
    password: "",
    confirmPassword: "",
  },

  validationSchema :Yup.object({
    fullName: Yup.string()
    // .min(2, "Mininum 2 characters")
    // .max(15, "Maximum 15 characters")
    .required("Please enter a valid name"),

    email: Yup.string()
      .email("Please enter a valid email id")
      .required("Please enter email id"),

    mobileNo: Yup.string()
    .min(7, "Mininum 7 Digit Mobile Number ")
    .max(11, "Maximum 11 Digit Mobile Number")
    .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, "Enter a valid phone number")
    .required("Please enter a valid mobile number"),
    

    password: Yup.string()
    .min(8, "Must be 8 characters or more")
    .matches(/[a-z]+/, "One lowercase character")
    .matches(/[A-Z]+/, "One uppercase character")
    .matches(/[@$!%*#?&]+/, "One special character")
    .matches(/\d+/, "One number")
    .required("Please enter password"),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Password doesn’t match ")
      .min(8, "Must be 8 characters or more")
      .matches(/[a-z]+/, "One lowercase character")
      .matches(/[A-Z]+/, "One uppercase character")
      .matches(/[@$!%*#?&]+/, "One special character")
      .matches(/\d+/, "One number")
      .required("Please enter confirm password"),

  }),
  onSubmit
})

  return (
    <>
      <NavBar></NavBar>
      <section className="BackgroundBody">
        <Container>
          <Row>
            <Col className="col-lg-12 d-flex my-5 py-5 ">
              <Col className="col-lg-6 BackgroundAlice vector-card">
                <Image
                  className="img-fluid vector-img"
                  src={SignupCharacter}
                ></Image>
              </Col>
              <Col className="col-lg-6 cardsignup">
              <Container>
              <Row className="d-flex my-5 justify-content-center">
              <Row className="d-flex justify-content-center">
              <h2 className="capitalize text-white">sign up</h2>
              <hr className="w-auto hrline text-center text-white"></hr>
              </Row>
                <Form className="col-lg-8" onSubmit={formik.handleSubmit}>
                  <Form.Group className="mb-3 text-start">
                    <Form.Label className="label text-white">Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter Name" name="fullName" value={formik.values.fullName} onChange={formik.handleChange}/>
                    
                    {formik.errors.fullName && formik.touched.fullName && (
                      <small className="validation-msg bolder">{formik.errors.fullName}</small>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3 text-start" controlId="formBasicPassword">
                    <Form.Label className="label text-white">Email ID</Form.Label>
                    <Form.Control type="email" placeholder="Enter Email " name="email" value={formik.values.email} onChange={formik.handleChange}/>
                    
                    {formik.errors.email && formik.touched.email && (
                      <small className="validation-msg bolder">{formik.errors.email}</small>
                    )}
                  </Form.Group>
             
                  <Form.Group className="mb-3 text-start" controlId="MoNumber" onChange={formik.handleChange}>
                    
                    <Form.Label className="label text-white">Mobile Number</Form.Label>
                    
                <div className="d-flex">
                <PhoneInput
                      autoFormat="false"
                      id="dial"
                      country={"in"}                      
                      enableSearch={true}
                      disableSearchIcon={true}
                      preferredCountries={["in", "us", "np","au"]}
                      inputClass="inp-cls"
                      containerClass="cust"
                      dropdownClass="dropd"
                      placeholder="Enter phone number"
                      nationalMode="false"
                      countryCodeEditable={false}
                      onChange={(v, e, ee) => {
                        console.log(v, e, ee);
                        console.log("Country Name:" + e.name )
                        console.log("Dial Code: " + e.dialCode)
                        setnumber(e.dialCode)
                      }}
                    />

                    <Form.Control  type="tel" className="inputpedding" placeholder="Enter Number" name="mobileNo" value={formik.values.mobileNo} />
                </div>

                    {formik.errors.mobileNo && formik.touched.mobileNo && (
                      <small className="validation-msg bolder">{formik.errors.mobileNo}</small>
                    )}

                  </Form.Group>

                <Row>
                  <Form.Group className="mb-3 text-start col-lg-6" controlId="formBasicPassword">
                    <Form.Label className="label text-white">Password </Form.Label>
                    &nbsp;
                     <OverlayTrigger
                          className="toolTip"
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip color='white' className="my-tooltip text-start" id="tooltip-top"><div className="toolTip text-start">The password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.</div></Tooltip>}
                      >
                      <Image src={passwordinfo} className="arrow text" data-toggle="tooltip w-25" data-placement="top" title=""></Image>
                    </OverlayTrigger>
                    <Form.Control type="password" placeholder="Enter Password" name="password" value={formik.values.password} onChange={formik.handleChange}/>
                    {formik.errors.password && formik.touched.password && (
                      <small className="validation-msg bolder">{formik.errors.password}</small>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3 text-start col-lg-6" controlId="formBasicPassword">
                    <Form.Label className="label text-white">Confirm Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter Password" name="confirmPassword" value={formik.values.confirmPassword} onChange={formik.handleChange}/>
                    {formik.errors.confirmPassword &&
                      formik.touched.confirmPassword && (
                        <small className="validation-msg bolder">{formik.errors.confirmPassword}</small>
                      )}
                  </Form.Group>
                </Row>

                  <Button className="col-lg-12 btnDanger" variant="primary" type="submit" disabled={loading}>
                  {loading ? "Loading..." : "SIGNUP"}
                  </Button>

                  <Row className="my-4">
                    <span className="text-white">Already a Member? <a className="textColorYellow bolder" onClick={Login}>LOGIN</a></span>
                  </Row>
                </Form>
              </Row>
              </Container>
              </Col>
            </Col>
          </Row>

          {/* success model  */}
          <Modal  show={showSuccess} onHide={handleSuccessClose} className="modelset lg">
            <div className="sucmodel rounded-pill">
            <Modal.Body>
                <div className='m-3'>
                    <center>
                        <img src={Successmessageicon} className='mb-3 fw-12'></img><br/>
                        <b><h4 className='mb-3 bolder '>Success</h4></b>
                        <p className='textColorDefault mb-3'><b>{Successhandle}</b></p>
                        <Button variant="primary" className="col-lg-10 rounded btnInfo justify-content-center" onClick={handleSuccessClose}>
                            Ok
                        </Button>
                    </center>
                </div>
            </Modal.Body>
            </div>
          </Modal>


          {/* error model */}
          <Modal show={showError} onHide={handleErrorClose} className="modelset lg" >
            <div className="errmodel rounded-pill">
            <Modal.Body className='' closeButton>
                <div className='m-3'>
                <center>
                    <img src={Errormessageicon} className='mb-3'></img><br/>
                    <b><h4 className='mb-3 bolder capitalize'>Error</h4></b>
                    <p className='textColorDefault mb-3'><b>{Errorhandle}</b></p>
                    <Button variant="primary" className="col-lg-10 rounded btnInfo justify-content-center" onClick={handleErrorClose}>
                    Ok
                    </Button>
                </center>
              </div>
            </Modal.Body>
            </div>
          </Modal>

          
        </Container>
      </section>
      <Footer></Footer>
    </>
  );
}
