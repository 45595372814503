import React, { useState } from "react";
import { Container, Row, Card, Col, Image, Accordion } from "react-bootstrap";
import vision from "../../../Assets/Images/vision.jpg";
import Mission from "../../../Assets/Images/Mission.jpg";
import objective from "../../../Assets/Images/objective.png";
import News1 from "../../../Assets/Images/blogsInner.png";
import News2 from "../../../Assets/Images/blogsInner.png";
import News3 from "../../../Assets/Images/blogsInner.png";
import { useHistory } from "react-router-dom";
import "./FAQMessage.css";

const FAQMessage = () => {
  const [activeKey, setActiveKey] = useState("0");
  let history = useHistory();


  const handleAccordionToggle = (key) => {
    setActiveKey(key === activeKey ? null : key);
  };

  return (
    <div className="bg-white">
      <Container className="pt-5 pb-5">
        <h2 className="normaldivpadding newshead1 bolder">
          Frequently Asked Questions
        </h2>
        <Row>
          <Col className="col-lg-8 d-flex m-auto">
            <Accordion
          activeKey={activeKey}
          onSelect={handleAccordionToggle}
          className="border-0 text-black color-black dropdowninfoFAQ form-control"
          flush
            >
              <Accordion.Item
                eventKey="0"
                className="text-black justifyText"
                className="py-2 border-bottom-0"
              >
                <Accordion.Header className="text-black color-black justifyText">
                  What is the migration process?
                </Accordion.Header>
                <Accordion.Body className="text-black justifyText">
                  The migration process involves determining your eligibility,
                  selecting the appropriate visa category, preparing supporting
                  documents, lodging the visa application, undergoing
                  assessments, and waiting for a decision from the Australian
                  immigration authorities.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="py-2 border-bottom-0">
                <Accordion.Header>
                  How long does the migration process take?
                </Accordion.Header>
                <Accordion.Body className="justifyText">
                  The processing time for Australian visa applications can vary
                  depending on the visa category, the complexity of the
                  application, and other factors. It can range from a few weeks
                  to several months.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2" className="py-2 border-bottom-0">
                <Accordion.Header>
                  What are the most demanding subject of study in Australia?{" "}
                </Accordion.Header>
                <Accordion.Body className="justifyText">
                  The most demanding subjects of study in Australia can vary
                  over time and depend on factors such as industry trends, skill
                  shortages, and the overall demand for certain professions.
                  <div className="col-lg-12 mt-3">
                    <p className="bolder">
                      Some fields that have traditionally been in high demand in
                      Australia include{" "}
                    </p>
                    <ol className="mt-2">
                      <li>
                        <div className=" d-flex me-auto ps-2 justifyText">
                          Healthcare and Allied Health: There is a continual
                          need for healthcare professionals, including doctors,
                          nurses, pharmacists, physiotherapists, occupational
                          therapists, and medical researchers, to support the
                          country's healthcare system.
                        </div>
                      </li>
                      <li className="mt-3">
                        <div className=" d-flex me-auto ps-2 justifyText">
                          Information Technology (IT): With the rapid
                          advancements in technology, there is a consistent
                          demand for IT professionals, especially in areas such
                          as software development, cybersecurity, data science,
                          artificial intelligence, and cloud computing.
                        </div>
                      </li>
                      <li className="mt-3">
                        <div className=" d-flex me-auto ps-2 justifyText">
                          Engineering: Australia has a strong demand for
                          engineers, particularly in sectors like civil
                          engineering, mechanical engineering, electrical
                          engineering, and mining engineering due to
                          infrastructure development and the growth of the
                          mining and resources industry.
                        </div>
                      </li>
                      <li className="mt-3">
                        <div className=" d-flex me-auto ps-2 justifyText">
                          Accounting and Finance: The finance industry requires
                          skilled professionals in areas like accounting,
                          finance, taxation, and financial planning to meet the
                          needs of businesses and individuals.
                        </div>
                      </li>
                      <li className="mt-3">
                        <div className=" d-flex me-auto ps-2 justifyText">
                          Business and Management: As the business landscape
                          evolves, there is a demand for professionals with
                          skills in areas such as business administration,
                          marketing, human resources, project management, and
                          entrepreneurship.
                        </div>
                      </li>
                      <li className="mt-3">
                        <div className=" d-flex me-auto ps-2 justifyText">
                          Education: Australia requires qualified educators and
                          teachers across various levels of education, including
                          early childhood education, primary education,
                          secondary education, and vocational training.
                        </div>
                      </li>
                      <li className="mt-3">
                        <div className=" d-flex me-auto ps-2 justifyText">
                          Environmental Science and Sustainability: With
                          increasing awareness of environmental issues, there is
                          a growing demand for experts in environmental science,
                          sustainability, renewable energy, and resource
                          management. It's important to note that the demand for
                          specific subjects can change over time as industries
                          evolve and new opportunities emerge.
                        </div>
                        <div className=" d-flex me-auto ps-2 justifyText">
                          It's advisable to research current market trends and
                          consider your own interests and aptitudes when
                          selecting a subject of study in Australia.
                        </div>
                      </li>
                    </ol>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3" className="py-2 border-bottom-0">
                <Accordion.Header>
                  How much do I need to pay for my study in Australia?{" "}
                </Accordion.Header>
                <Accordion.Body className="justifyText">
                  The cost of studying in Australia can vary depending on
                  factors such as the level of study (undergraduate or
                  postgraduate), the institution you choose, the specific course
                  or program, and your duration of study. Additionally, living
                  expenses will also need to be considered. Generally,
                  universities charge higher fees as compared to colleges.
                  Please note that fees are subject to change as per the
                  institution's policy.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="py-2 border-bottom-0">
                <Accordion.Header>
                  What is the living cost in Australia?
                </Accordion.Header>
                <Accordion.Body className="justifyText">
                  The cost of living in Australia can vary depending on factors
                  such as location and personal lifestyle, but on average, it is
                  estimated to range from AUD 15,000 to AUD 35,000 per year for
                  an international student, covering accommodation, food,
                  transportation, utilities, and other daily expenses.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" className="py-2 border-bottom-0">
                <Accordion.Header>
                  Is public transportation in Australia affordable?
                </Accordion.Header>
                <Accordion.Body className="justifyText">
                  Public transportation fares in Australia vary by city, but
                  generally, they are reasonably priced, with discounted fares
                  for students and concessions available, making it an
                  affordable option for commuting and travel within urban areas.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6" className="py-2 border-bottom-0">
                <Accordion.Header>
                  What is the salary and wage standard in Australia?{" "}
                </Accordion.Header>
                <Accordion.Body className="justifyText">
                  Salary and wages vary depending on which occupation you are
                  working in. Each occupation will have a minimum wage based on
                  your employment type. Fair Work ombudsman will update the
                  latest standard wages. You can check all of your work-related
                  rights and obligation from (Welcome to the Fair Work Ombudsman
                  website) and Fair Work Commission{" "}
                  <a
                    className="text-blue"
                    href="https://www.fwc.gov.au"
                    target="_blank"
                  >
                    (Fair Work Commission | Australia's national workplace
                    relations tribunal{" "}
                    <a href="https://www.fwc.gov.au" target="_blank"></a>
                    (fwc.gov.au))
                  </a>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7" className="py-2 border-bottom-0">
                <Accordion.Header>
                  What should I do to start working in Australia?{" "}
                </Accordion.Header>
                <Accordion.Body className="justifyText">
                  Everyone who has worked right in Australia must get their tax
                  file number (TFN) before they can start work in Australia
                  Legally. You can visit the Australian taxation office website
                  and apply for your TFN. Please click here to apply for your
                  TFN{" "}
                  <a
                    className="text-blue"
                    href="https://www.ato.gov.au/iar/#identification"
                    target="_blank"
                  >
                    (https://www.ato.gov.au/iar/#identification){" "}
                  </a>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8" className="py-2 border-bottom-0">
                <Accordion.Header>
                  When are the admission intakes in Australia?{" "}
                </Accordion.Header>
                <Accordion.Body className="justifyText">
                  Admission intakes in Australian universities and colleges
                  typically occur in February (Semester 1) and July (Semester
                  2), with some institutions offering additional intakes in
                  April and October/November.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FAQMessage;
