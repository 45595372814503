import React from 'react'
import './BlogsDetailsExpenseBanner.css'
import AboutusBanner from '../../../Assets/Images/blogsBanner.jpg'



import { Card, Col, Container, Row } from 'react-bootstrap'

export default function BlogsDetailsExpenseBanner() {
  return (
    <section className='servicesbanner'>
      
      <img src={AboutusBanner} className='img-fluid banner_img'/>
      
      <Container fluid className='banner_container'>
        <h1>BLOGS </h1>
      </Container>
    </section>
  )
}
