import React,{ useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Router from './Components/Router/Router';


export default function App({ children }) {
  useEffect(() => {
    console.warn = () => {};
  }, [])
  
  return (
    <div className="App overflow-hidden">
       <Router/>
    </div>
  );
}


