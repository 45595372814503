import React from "react";
import Navbar from "../Components/Layout/NavBar/NavBar";
import Footer from "../Components/Layout/Footer/Footer";
import Banner from "../Components/AboutUsPage/AboutUs_Banner/Banner";
import AboutUs_Msg from "../Components/AboutUsPage/AboutUs_Msg//AboutUs_Msg";

const  AboutUs = () => {
  return (
    <div>
      <Navbar Page={"AboutUs"}></Navbar>
      <Banner></Banner>
      <AboutUs_Msg></AboutUs_Msg>
      <Footer></Footer>
    </div>
  );
};

export default AboutUs;
