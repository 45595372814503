import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import '../CompanySponser/CompanySponser.css'
import Client1 from '../../../Assets/Images/client-1.png'
import Client2 from '../../../Assets/Images/client-2.png'
import Client3 from '../../../Assets/Images/client-3.png'
import Client4 from '../../../Assets/Images/client-4.png'

export default function CompanySponser() {
  return (
    <div className='Bgcolor py-2'>
      <Container className='py-2'>
        <Row>
          
            <Col className='my-3 col-lg-2 col-md-2 col-sm-2 col-4'>
                <Image src={Client1} className='imgsett'></Image>
            </Col>
            <Col className='my-3 col-lg-2 col-md-2 col-sm-2 col-4'>
                <Image src={Client2} className='imgsett'></Image>
            </Col>
            <Col className='my-3 col-lg-2 col-md-2 col-sm-2 col-4'>
                <Image src={Client3} className='imgsett'></Image>
            </Col>
            <Col className='my-3 col-lg-2 col-md-2 col-sm-2 col-4'>
                <Image src={Client4} className='imgsett'></Image>
            </Col>
            <Col className='my-3 col-lg-2 col-md-2 col-sm-2 col-4'>
                <Image src={Client1} className='imgsett'></Image>
            </Col>
            <Col className='my-3 col-lg-2 col-md-2 col-sm-2 col-4'>
                <Image src={Client4} className='imgsett'></Image>
            </Col>
        </Row>
      </Container>
    </div>
  )
}
