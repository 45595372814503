import React, { Component } from 'react';
import loading from './gifMain.gif';
import './Loading.css'; // Import the CSS file for styling

export default class Loading extends Component {
  render() {
    return (
      <div className='loader-overlay'>
        <div className='loader-content'>
          <img src={loading} alt='Loading' className='loader-spinner' />
        </div>
      </div>
    );
  }
}
