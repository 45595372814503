import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from '../../Pages/Home';
import Login from './../../Auth/Login/Login';
import Signup from './../../Auth/Signup/Signup';
import ContactUs from '../../Pages/ContactUs'
import Services_Page from '../../Pages/Services';
import BookAnAppointments from '../../Pages/BookAnAppointments';

import authGoogle from '../../Auth/Login/authGoogle';

import { useHistory } from "react-router-dom";
import Vision from '../../Components/HomePage/OurVision/Vision';
import Milestone from '../HomePage/Milestone/Milestone';
import MissionObjective from '../HomePage/MissionObjective/MissionObjective';
import News from '../../Components/HomePage/News/News';
import FAQ from '../HomePage/FAQ/FAQ';
import CompanySponser from '../HomePage/CompanySponser/CompanySponser';
import VisaProcess from '../../Pages/VisaProcess';
import MigrationProcess from '../../Pages/MigrationProcess';
import SkillAssessment from '../../Pages/SkillAssessment';
import LifeInAustralia from '../../Pages/LifeInAustralia';
import ExpancesInAustralia from '../../Pages/ExpancesInAustraliaPage';
import AboutUs from '../../Pages/AboutUs';
import Blogs from '../../Pages/Blogs';
import BlogsDetails from '../../Pages/BlogsDetails';
import FAQs from '../../Pages/FAQs';
import PointsCalculator from '../../Pages/PointsCalculator';
import Insurance from '../../Pages/Insurance';
import CareerConsultation from '../../Pages/CareerConsultation';
import BlogsDetailsExpense from '../../Pages/BlogsDetailsExpense';
import CustomerReviewPopup from '../HomePage/Review/CustomerReviewPopup';
import GlobalVisaProcessingTime from '../../Pages/GlobalVisaProcessingTime';
import VisaPricingEstimator from '../../Pages/VisaPricingEstimator';
import AppointmentPage from '../AppointmentPage/AppointmentPage';




export default function Router({ children }) {
        

    // const [isAuth, setIsAuth] = useState(CheckLocalStorage());
        return (
            <BrowserRouter>
                <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/ContactUs" component={ContactUs}/>
                <Route exact path="/services" component={Services_Page}/>
                <Route exact path="/bookanappointment" component={BookAnAppointments}/>
                <Route exact path="/visaprocess" component={VisaProcess}/>
                <Route exact path="/migrationprocess" component={MigrationProcess}/>
                <Route exact path="/skillassessment" component={SkillAssessment}/>
                <Route exact path="/lifeinaustralia" component={LifeInAustralia}/>
                <Route exact path="/expancesinaustralia" component={ExpancesInAustralia}/>
                <Route exact path="/aboutus" component={AboutUs}/>
                <Route exact path="/blogs" component={Blogs}/>
                <Route exact path="/blogsdetails" component={BlogsDetails}/>
                <Route exact path="/blogsdetailsexpense" component={BlogsDetailsExpense}/>
                <Route exact path="/FAQ" component={FAQs}/>
                <Route exact path="/pointscalculator" component={PointsCalculator}/>
                <Route exact path="/insurance" component={Insurance}/>
                <Route exact path="/careerconsultation" component={CareerConsultation}/>
                <Route exact path="/customerreviewpopup" component={CustomerReviewPopup}/>
                <Route exact path="/globalvisaprocessingtime" component={GlobalVisaProcessingTime}/>
                <Route exact path="/visapricingestimator" component={VisaPricingEstimator}/>
                <Route exact path="/cancelappointment" component={AppointmentPage}/>










                <Route exact path="/login" component={Login}/>
                <Route exact path="/signup" component={Signup}/>
                <Route exact path="/auth" component={authGoogle}/>{/**example k lia hi hai */}
                {/* <Route exact path="/vision" component={Vision}/>
                <Route exact path="/missionobjective" component={MissionObjective}/>
                <Route exact path="/milestone" component={Milestone}/>
                <Route exact path="/news" component={News}/>
                <Route exact path="/faq" component={FAQ}/>
                <Route exact path="/companyClient" component={CompanySponser}/> */}
                <Redirect to="/" />

                </Switch>
            </BrowserRouter>
        )
    
}
