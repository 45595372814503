import React,{useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import NavBar  from '../../Components/Layout/NavBar/NavBar';
import './Login.css'
import { history } from '../../Helpers/history';
import Footer from './../../Components/Layout/Footer/Footer';
import { Container } from 'react-bootstrap';
import Charachter from '../../Assets/Images/character.png'
import {Row} from 'react-bootstrap';
import {Col} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import {Button,Image,ListGroup} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import Facebook from '../../Assets/Images/facebook.png'
import Tweeter from '../../Assets/Images/tweeter.png'
import Tiktok from '../../Assets/Images/tiktok.png'
import legal_remit_icon from '../../Assets/Images/legal remit icon 1.png'
import Google from '../../Assets/Images/google.png'
// import Passwordeye from '../../Assets/Images/password_eye.png'
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { CommonConstants } from './../../Constants/common.constants';

import Successmessageicon from '../../Assets/Images/Success-message-icon.png'
import Errormessageicon from '../../Assets/Images/Error-message-icone.png'


export default function Login({ children }) {

    //set data in localstorage//
    const [email,setemail]=useState();
    const [checked,setchecked]=useState(false);
    const checkhandle=()=>{ 
        setchecked(true)
        setemail(document.getElementById('emailormobilenumber').value)
    }
    /////////

    let history = useHistory();
    const Signup=()=>{
        history.push('/signup')
        window.scrollTo(0, 0)
    }


    /// error & success model handling ///
    const [Successhandle , setSuccesshandle] = useState (null);
    const [Errorhandle , setErrorhandle] = useState (null);

    const [showSuccess, setshowSuccess] = useState(false);
    const [showError, setshowError] = useState(false);

    const handleSuccessClose = () => {
        setshowSuccess(false)
        history.push('/')
    };
    const handleSuccessShow = () => setshowSuccess(true);

    const handleErrorClose = () => setshowError(false);
    const handleErrorShow = () => setshowError(true);
    //////////////

    const onSubmit = async (values) =>{
        try{
            debugger
            const {...data} = values;
            console.log(values)
            const response = await axios.post(CommonConstants.BASE_URL + "/api/auth/login", data);
            console.log(response)/////login na responce ma email mangavo padse localstorage ma save karava mate /////
            if(response.data.status==="success"){
                setSuccesshandle(response.data.meassage)
                handleSuccessShow()
                if(checked===true){
                    localStorage.setItem('EmailorMobilenumber', email);
                }
              }
              else if(response.data.status==="error"){
                setErrorhandle(response.data.message)
                handleErrorShow()
              }
        }
        catch(err){
            console.log(err)
        }
      };


    const formik = useFormik({
        initialValues: {
          email: "",
          password: ""
        },

        validationSchema :Yup.object({
      
            email: Yup.string()
              .email("Please enter a valid email id")
              .required("Please enter email id"),
      
            password: Yup.string()
            .min(8, "Must be 8 characters or more")
            .matches(/[a-z]+/, "One lowercase character")
            .matches(/[A-Z]+/, "One uppercase character")
            .matches(/[@$!%*#?&]+/, "One special character")
            .matches(/\d+/, "One number")
            .required("Please enter password"),
      
          }),
          onSubmit
        })

    return (
        <>
            <NavBar></NavBar>
            <section className="BackgroundBody">
                <Container>
                <Row>
                    <Col className="col-lg-12 col-md-12 col-sm-12  d-flex my-5 py-5 ">
                    <Col className="col-lg-6 col-md-6 col-sm-12 BackgroundAlice vector-card vector_dis">
                        <Image
                        className="img-fluid vector-img"
                        src={Charachter}
                        ></Image>
                    </Col>
                    <Col className="col-lg-6 col-md-6 col-sm-12 card cardsignup">
                    <Container className=''>
                    <Row className="d-flex my-5 justify-content-center">
                    <Row className="d-flex justify-content-center">
                    <h2 className="capitalize text-white">LOGIN</h2>
                    <hr className="w-auto pb-4 hrline text-center text-white"></hr>
                    </Row>
                        <Form className="col-lg-8" onSubmit={formik.handleSubmit}>
                            <Form.Group className="mb-3 text-start" controlId="formBasicEmail">
                                <Form.Label className="label text-white">Email ID / Phone number</Form.Label>
                                <Form.Control type="email" placeholder="Enter Email" id ='emailormobilenumber'name='email' value={formik.values.email} onChange={formik.handleChange}/>
                                {formik.errors.email && formik.touched.email && (
                                    <small className="validation-msg bolder">{formik.errors.email}</small>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3 text-start" controlId="formBasicPassword">
                                <Form.Label className="label text-white">Password</Form.Label>
                                <Form.Control type="password" placeholder="Enter Password" name='password' value={formik.values.password} onChange={formik.handleChange}/>
                                {formik.errors.password && formik.touched.password && (
                                    <small className="validation-msg bolder">{formik.errors.password}</small>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3 text-start color-white" controlId="formBasicCheckbox" >
                                <Form.Check type="checkbox" label="Remember Me" className='text-white' onChange={checkhandle}/>
                            </Form.Group>

                            <Button className="col-lg-12 col-md-12 col-sm-12 col-12 btnDanger" variant="primary" type="submit">
                                LOGIN
                            </Button>
                            <Row className="my-4">
                                <Col className="text-white col-lg-6 text-start">
                                    <span className="">Forgot Password?</span>
                                </Col>
                                <Col className="text-white col-lg-6 text-end">
                                    <span className=" ">New Member?  <a className="textColorYellow bolder" onClick={Signup}>SIGN UP</a></span>
                                </Col>
                            </Row>
                            <Row className='my-4'>
                                <Col className='text-white'>
                                    <span>Or <br></br>Login with</span>
                                </Col>
                            </Row>
                            <Row className='my-2 justify-content-center d-flex'>
                                <Col className='col-lg-12 col-md-12 col-sm-12 col-12'>

                            <ul className='pt-4 d-flex justify-content-center'>
                                <li className='list-group me-4'><Image src={Facebook} className='pointer img-fluid'></Image></li>
                                <li className='list-group me-4'><Image src={Google} className='pointer img-fluid'></Image></li>
                                <li className='list-group me-4'><Image src={Tweeter} className='pointer img-fluid'></Image></li>
                                <li className='list-group me-4'><Image src={legal_remit_icon} className='pointer img-fluid'></Image></li>
                                <li className='list-group me-4'><Image src={Tiktok} className='pointer img-fluid'></Image></li>
                            </ul>
                            </Col>

                                    {/* <Col className='py-4 col-lg-2 col-md-2 col-sm-2 col-3'>
                                            <Image src={Facebook} className='pointer img-fluid'></Image>
                                    </Col>
                                    <Col className='py-4 col-lg-2 col-md-2 col-sm-2 col-3 img-fluid'>
                                            <Image src={Google} className='pointer img-fluid'></Image>
                                    </Col>
                                    <Col className='py-4 col-lg-2 col-md-2 col-sm-2 col-3 img-fluid'>
                                            <Image src={Tweeter} className='pointer img-fluid'></Image>
                                    </Col>
                                    <Col className='py-4 col-lg-2 col-md-2 col-sm-2 col-3 img-fluid'>
                                            <Image src={legal_remit_icon} className='pointer img-fluid'></Image>
                                    </Col>
                                    <Col className='py-4 col-lg-2 col-md-2 col-sm-2 col-3 img-fluid'>
                                            <Image src={Tiktok} className='pointer img-fluid'></Image>
                                    </Col> */}
                            </Row>
                        </Form>
                    </Row>
                    </Container>
                    </Col>
                    </Col>
                </Row>

                    {/* success model  */}
                    <Modal  show={showSuccess} onHide={handleSuccessClose} className="modelset lg">
                        <div className="sucmodel rounded-pill">
                        <Modal.Body>
                            <div className='m-3'>
                                <center>
                                    <img src={Successmessageicon} className='mb-3 fw-12'></img><br/>
                                    <b><h4 className='mb-3 bolder '>Success</h4></b>
                                    <p className='textColorDefault mb-3'><b>{Successhandle}</b></p>
                                    <Button variant="primary" className="col-lg-10 rounded btnInfo justify-content-center" onClick={handleSuccessClose}>
                                        Ok
                                    </Button>
                                </center>
                            </div>
                        </Modal.Body>
                        </div>
                    </Modal>

                    {/* error model */}
                    
                    <Modal show={showError} onHide={handleErrorClose} className="modelset lg" >
                        <div className="errmodel rounded-pill">
                        <Modal.Body className='' closeButton>
                            <div className='m-3'>
                            <center>
                                <img src={Errormessageicon} className='mb-3'></img><br/>
                                <b><h4 className='mb-3 bolder capitalize'>Error</h4></b>
                                <p className='textColorDefault mb-3'><b>{Errorhandle}</b></p>
                                <Button variant="primary" className="col-lg-10 rounded btnInfo justify-content-center" onClick={handleErrorClose}>
                                Ok
                                </Button>
                            </center>
                        </div>
                        </Modal.Body>
                        </div>
                    </Modal>

               </Container>
            </section>
            <Footer></Footer>
        </>
    )
}

