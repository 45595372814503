import React from 'react';
import Navbar from '../Components/Layout/NavBar/NavBar'
import Footer from '../Components/Layout/Footer/Footer'
import BlogsMessage from '../Components/BlogsPage/BlogsMessage/BlogsMessage';
import BlogsBanner from '../Components/BlogsPage/BlogsBanner/BlogsBanner';
import FooterVideo from '../Components/Layout/FooterVideo/FooterVideo';
import BlogsDetailsMessage from '../Components/BlogsDetailsPage/BlogsDetailsMessage/BlogsDetailsMessage';
import BlogDetailsBanner from '../Components/BlogsDetailsPage/BlogsDetailsBanner/BlogsDetailsBanner';


export default function BlogsDetails({ children }) {
  

    return (
        <>
            <Navbar></Navbar>
            <BlogDetailsBanner></BlogDetailsBanner>
            <BlogsDetailsMessage></BlogsDetailsMessage>
      <FooterVideo/>

            <Footer></Footer>
        </>
    )
}

