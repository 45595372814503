import React from "react";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import "../Facilities/Facilities.css";
import card1 from "../../../Assets/Images/visaservice.jpg";
import card2 from "../../../Assets/Images/2.jpg";
import card3 from "../../../Assets/Images/3.jpg";
import card4 from "../../../Assets/Images/migrationService.jpg";
import card5 from "../../../Assets/Images/skillServices.jpg";
import card6 from "../../../Assets/Images/insuraneService.png";
import card7 from "../../../Assets/Images/careerSerice.jpg";
import { useHistory } from "react-router-dom";

export default function Facilities() {
  let history = useHistory();

  const visaProcess = () => {
    history.push("/visaprocess");
    window.scrollTo(0, 0);
  };

  const InsurancePage = () => {
    history.push("/insurance");
    window.scrollTo(0, 0);
  };

  const migrationProcess = () => {
    history.push("/migrationprocess");
    window.scrollTo(0, 0);
  };

  const lifeinAustralia = () => {
    history.push("/lifeinaustralia");
    window.scrollTo(0, 0);
  };

  const skillAssessment = () => {
    history.push("/skillassessment");
    window.scrollTo(0, 0);
  };

  const CareerConsultationPage = () => {
    history.push("/careerconsultation");
    window.scrollTo(0, 0);
  };

  return (
    <div className="bg-white">
      <div className="headerText pt-5 ">
        <h2>Services We Offer</h2>
        <span className="text-center w-25 my-5 pb-4">To boost your trip</span>
      </div>
      <Container className="py-5 ">
        <Row>
          <Col className="col-lg-12 d-flex flex-wrap servicecardcontainer">
            <Col className="col-lg-4 col-md-6 col-sm-12 col-xs-12 px-2">
              <a className="url-box">
                <figure className="newsCard news-Slide-up ">
                  <img src={card1} className="img-fluid" height="1000" width="1000" />
                  <div className="newsCaption px-4">
                    <div className="d-flex align-items-center cnt-title text-center justify-content-center">
                      <h4 className="newsCaption-title text-white m-0 text-center mt-4">
                        Visa Process
                      </h4>
                    </div>
                    <div className="newsCaption-content d-flex justify-content-center flex-column align-items-center">
                      <p className="col-10 py-5 px-0 pb-2 justifyText mt-1">
                        The Australian visa process is designed to ensure that
                        applicants meet the eligibility requirements for their
                        specific visa type. As a country with diverse
                        opportunities for work, study, travel, and migration.
                      </p>
                      <Button
                        className="btn btn-deafult btnDanger  d-block m-auto mt-3"
                        onClick={visaProcess}
                      >
                        READ MORE
                      </Button>
                    </div>
                  </div>
                  <span className="overlay"></span>
                </figure>
              </a>
            </Col>
            <Col className="col-lg-4 col-md-6 col-sm-12 col-xs-12 px-2">
              <a className="url-box">
                <figure className="newsCard news-Slide-up ">
                  <img src={card4} className="img-fluid" height="1000" width="1000" />
                  <div className="newsCaption px-4">
                    <div className="d-flex align-items-center cnt-title text-center justify-content-center">
                      <h4 className="newsCaption-title text-white m-0 text-center mt-4">
                        Migration Process
                      </h4>
                    </div>
                    <div className="newsCaption-content d-flex justify-content-center flex-column align-items-center">
                      <p className="col-10 py-5 px-0 pb-2 justifyText mt-1">
                        Australia is a diverse and vibrant country that offers a
                        wide range of experiences for its residents. However,
                        living expenses can vary significantly depending on the
                        state you choose to call home.
                      </p>
                      <Button
                        className="btn btn-deafult btnDanger  d-block m-auto mt-3"
                        onClick={migrationProcess}
                      >
                        READ MORE
                      </Button>
                    </div>
                  </div>
                  <span className="overlay"></span>
                </figure>
              </a>
            </Col>
            <Col className="col-lg-4 col-md-6 col-sm-12 col-xs-12 px-2">
              <a className="url-box">
                <figure className="newsCard news-Slide-up ">
                  <img src={card5} className="img-fluid" height="1000" width="1000" />
                  <div className="newsCaption px-4">
                    <div className="d-flex align-items-center cnt-title text-center justify-content-center">
                      <h4 className="newsCaption-title text-white m-0 text-center mt-4">
                        Skills Assessments
                      </h4>
                    </div>
                    <div className="newsCaption-content d-flex justify-content-center flex-column align-items-center">
                      <p className="col-10 py-5 px-0 pb-2 justifyText mt-1">
                        At Focus Visa and Education Services, we offer
                        comprehensive skill assessment support to help you
                        navigate this vital aspect of the migration process. Our
                        team of experts works closely .
                      </p>
                      <Button
                        className="btn btn-deafult btnDanger  d-block m-auto mt-3"
                        onClick={skillAssessment}
                      >
                        READ MORE
                      </Button>
                    </div>
                  </div>
                  <span className="overlay"></span>
                </figure>
              </a>
            </Col>
            {/* <Col className="col-lg-4 px-2">
              <a  className="url-box">
                <figure className="newsCard news-Slide-up ">
                  <img src={card2} className="img-fluid" />
                  <div className="newsCaption px-4">
                    <div className="d-flex align-items-center cnt-title text-center justify-content-center">
                      <h4 className="newsCaption-title text-white m-0 text-center mt-4">
                        Life in Australia
                      </h4>
                    </div>
                    <div className="newsCaption-content d-flex justify-content-center flex-column align-items-center">
                      <p className="col-10 py-3 px-0 pb-2 justifyText mt-1">
                      Australia, known as the "Land Down Under," is a diverse and fascinating country that attracts people from all over the world. With its unique blend of cultures, beautiful landscapes, and high quality of life, it's no wonder that Australia is a popular destination for international students, workers, and those seeking to become permanent residents. .

                      </p>
                      <Button
                        className="btn btn-deafult btnDanger  d-block m-auto mt-3"
                        onClick={lifeinAustralia}
                      >
                        READ MORE
                      </Button>
                    </div>
                  </div>
                  <span className="overlay"></span>
                </figure>
              </a>
            </Col> */}
            {/* <Col className="col-lg-4 px-2">
              <a  className="url-box">
                <figure className="newsCard news-Slide-up ">
                  <img src={card3} className="img-fluid" />
                  <div className="newsCaption px-4">
                    <div className="d-flex align-items-center cnt-title text-center justify-content-center">
                      <h4 className="newsCaption-title text-white m-0 text-center mt-4">
                        Living Expenses in Australia
                      </h4>
                    </div>
                    <div className="newsCaption-content d-flex justify-content-center flex-column align-items-center">
                      <p className="col-10 py-3 px-0 pb-2 justifyText mt-1">
                      Australia is a diverse and vibrant country that offers a wide range of experiences for its residents. However, living expenses can vary significantly depending on the state you choose to call home. In this blog post, we'll provide a detailed breakdown of the living expenses in each Australian state, covering aspects like average income, rental costs, and other day-to-day expenses.
                      </p>
                      <Button
                        className="btn btn-deafult btnDanger  d-block m-auto mt-3"
                        onClick={expancesinAustralia}
                      >
                        READ MORE
                      </Button>
                    </div>
                  </div>
                  <span className="overlay"></span>
                </figure>
              </a>
            </Col> */}
          
            <Col className="col-lg-4 col-md-6 col-sm-12 col-xs-12 px-2">
              <a className="url-box">
                <figure className="newsCard news-Slide-up ">
                  <img src={card6} className="img-fluid" height="1000" width="1000" />
                  <div className="newsCaption px-4">
                    <div className="d-flex align-items-center cnt-title text-center justify-content-center">
                      <h4 className="newsCaption-title text-white m-0 text-center mt-4">
                        Insurance
                      </h4>
                    </div>
                    <div className="newsCaption-content d-flex justify-content-center flex-column align-items-center">
                      <p className="col-10 py-5 px-0 pb-2 justifyText mt-1">
                        Our Life Insurance pays a lump sum in the event of your
                        death or if you’re diagnosed with a Terminal Illness3,
                        which can help support your family when you’re no longer
                        around to provide for them. 
                      </p>
                      <Button
                        className="btn btn-deafult btnDanger  d-block m-auto mt-3"
                        onClick={InsurancePage}
                      >
                        READ MORE
                      </Button>
                    </div>
                  </div>
                  <span className="overlay"></span>
                </figure>
              </a>
            </Col>

            <Col className="col-lg-4 col-md-6 col-sm-12 col-xs-12 px-2">
              <a className="url-box">
                <figure className="newsCard news-Slide-up ">
                  <img src={card7} className="img-fluid" height="1000" width="1000" />
                  <div className="newsCaption px-4 ">
                    <div className="d-flex align-items-center cnt-title text-center justify-content-center">
                      <h4 className="newsCaption-title text-white m-0 text-center mt-4">
                        Career Consultation
                      </h4>
                    </div>
                    <div className="newsCaption-content d-flex justify-content-center flex-column align-items-center">
                      <p className="col-10 py-5 px-0 pb-2 justifyText mt-1">
                        Taking the next step in your career can be exciting yet
                        challenging. Sometimes you may not know the best
                        approach to take. With the right support, you can learn
                        how to present yourself effectively to employers.
                      </p>
                      <Button
                        className="btn btn-deafult btnDanger  d-block m-auto mt-3"
                        onClick={CareerConsultationPage}
                      >
                        READ MORE
                      </Button>
                    </div>
                  </div>
                  <span className="overlay"></span>
                </figure>
              </a>
            </Col>
          </Col>

          {/* <Col className="col-lg-12 d-flex my-3">
            <Col className="col-lg-4 ">
              <a  className="url-box">
                <figure className="newsCard news-Slide-up ">
                  <img src={card7} className="img-fluid" />
                  <div className="newsCaption px-4">
                    <div className="d-flex align-items-center cnt-title text-center justify-content-center">
                      <h4 className="newsCaption-title text-white m-0 text-center mt-4">
                        Search Occupation
                      </h4>
                    </div>
                    <div className="newsCaption-content d-flex justify-content-center flex-column align-items-center">
                      <p className="col-10 py-3 px-0 pb-2 justifyText mt-5  ">
                      Skilled workers from overseas are often in high demand, particularly in industries experiencing a skills shortage. The Australian government has a list of eligible skilled occupations that can help guide potential workers in their job search. Some popular industries for international workers include healthcare, engineering, and technology.
                      </p>
                      <Button className="btn btn-deafult btnDanger  d-block m-auto mt-3">
                        READ MORE
                      </Button>
                    </div>
                  </div>
                  <span className="overlay"></span>
                </figure>
              </a>
            </Col>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
}
