import React from 'react'
import '../Services_Banner/Services_Banner.css'
import Services from '../../../Assets/Images/insuranceBanner.jpg'



import { Card, Col, Container, Row } from 'react-bootstrap'

export default function Services_Banner() {
  return (
    <section className='servicesbanner overflow-hidden'>
      
      <img src={Services} className='img-fluida banner_img'/>
      
      <Container fluid className='banner_container'>
        <h1>Services</h1>
      </Container>
    </section>
  )
}
