import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import FeedRoundedIcon from "@mui/icons-material/FeedRounded";
import ViewListIcon from "@mui/icons-material/ViewList";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Container } from "react-bootstrap";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import BookAnAppointmentSteps1 from "./BookAnAppointmentSteps/BookAnAppointmentSteps1";
import BookAnAppointmentSteps2 from "./BookAnAppointmentSteps/BookAnAppointmentSteps2";
import BookAnAppointmentSteps3 from "./BookAnAppointmentSteps/BookAnAppointmentSteps3";
import BookAnAppointmentSteps4 from "./BookAnAppointmentSteps/BookAnAppointmentSteps4";
import { useState } from "react";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "#003C9E",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "#003C9E",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#d2dcee",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient(135deg, rgba(0, 60, 158, 0.6) 0%, #003C9E 100%);",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient(135deg, rgba(0, 60, 158, 0.6) 0%, #003C9E 100%);",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <PersonIcon />,
    2: <CalendarMonthRoundedIcon />,
    3: <ViewListIcon />,
    4: <FeedRoundedIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ["", "", "", ""];

export default function BookAnAppointmentPage() {
  const [Consoler,setConsoler]=useState({id:'',fullName:'',email:'',image:'',mobileNo:'',description:''})
  const [Details,setDetails]=useState({appointmentId:'',consolerName:'',date:'',time:''})
  const [calenderDetails,setCalenderDetails]=useState({time:'',date:''})
  const [lastStep,setLastStep]=useState(1)
  const [consolerCountry,setconsolerCountry]=useState('')
  const [AppointmentDate,setAppointmentDate]=useState('')

  // console.log(consolerCountry,"consolerCountry")
  const FirstComponent = () => {
    return <BookAnAppointmentSteps1 handleNext={handleNext} FirstStepData={FirstStepData} handleConsoler={handleConsolerr}/>;
  };
  const SecondComponent = () => {
    return <BookAnAppointmentSteps2 Consoler={Consoler} AppointmentDate={Appointment_Date} ColsolerCountry={consolerCountry} calenderDetails={calenderDetails} handleNext={handleNext}  SecondStepData={SecondStepData}/>;
  };
  const ThirdComponent = () => {
    return <BookAnAppointmentSteps3 ThirdStepData={ThirdStepData} handleNext={handleNext} allData={{"cosoler_id":Consoler.id,"calenderDetails":calenderDetails}} A_Date={AppointmentDate}/>;
  };
  const FinalComponent = () => {
    return <BookAnAppointmentSteps4 Details={Details} A_Date={AppointmentDate} handleReset={handleReset}/>;
  };
  const FirstStepData=(data)=>{
    setConsoler(data)
    setLastStep(1)
  }
  const SecondStepData=(data)=>{
    setCalenderDetails(data)
    setLastStep(2)
  }
  const ThirdStepData=(data)=>{
    // console.log(data)
    setDetails(data)
    setLastStep(3)
  }

  const Appointment_Date = (Date) =>{
    setAppointmentDate(Date)
    console.log(Date,"setAppointmentDate")
  }
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <FirstComponent />;
      case 1:
        return <SecondComponent />;
      case 2:
        return <ThirdComponent />;
      case 3:
        return <FinalComponent />;
      default:
        return "Unknown stepIndex";
    }
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const chooseMessage = (data) => {
    setCalenderDetails(data)
  };
  const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    
  };

  const handleConsolerr = (consoler) => {
    // console.log(consoler,"ConsolerCountry")
    setconsolerCountry(consoler)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    if(step==1){
      if(Consoler.fullName!=''){
        setActiveStep(step);
      }
    }else if(step==2){
      if(calenderDetails.time && calenderDetails.date){
        setActiveStep(step);
      }
    }else if(step==0){
      setActiveStep(step);
    }
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

 

  return (
    <>
      <div className="bg-white">
        <Container className="py-5 ">
          <Stack sx={{ width: "100%" }} spacing={4}>
            <Stepper
            
              alternativeLabel
              activeStep={activeStep}
              connector={<ColorlibConnector />}
            >
              {steps.map((label,i) => (
                <Step key={i}>
                  <StepLabel StepIconComponent={ColorlibStepIcon} onClick={handleStep(i)} className="pointer">
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            <div>
              {activeStep === steps.length ? (
                <div>
                  <p>All steps completed</p>
                  <Button onClick={handleReset}>Reset</Button>
                </div>
              ) : (
                <div>
                  <p>{getStepContent(activeStep)}</p>
                  <div className="d-flex justify-content-between">
                    {/* <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className="text-black"
                    >
                      Back
                    </Button> */}
                    {/* <Button
                      variant="contained"
                      className="btnInfo"
                      onClick={handleNext}
                    >
                      {activeStep === steps.length - 1 ? "Book another appointment" : lastStep?'Submit': "Next"}
                    </Button> */}
                  </div>
                </div>
              )}
            </div>
          </Stack>
        </Container>
      </div>
    </>
  );
}
