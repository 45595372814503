import React from 'react'
import './CareerConsultationBanner.css'
import ServicesBanner from '../../../Assets/Images/insuranceBanner.jpg'



import { Card, Col, Container, Row } from 'react-bootstrap'

export default function CareerConsultationBanner() {
  return (
    <section className='servicesbanner'>
      
      <img src={ServicesBanner} className='img-fluid banner_img'/>
      
      <Container fluid className='banner_container'>
        <h1 className='calcPoint'>CAREER CONSULTATION</h1>
        {/* <span className='text-white bolder'>SERVICES · Career Consultation</span> */}
      </Container>
    </section>
  )
}
