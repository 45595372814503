import React from "react";
import "./SkillAssessmentsPage.css";
import { Container, Row, Col, Button } from "react-bootstrap";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SKILLBG from "../../Assets/Images/skillBG.png";
import MigrationBG1 from "../../Assets/Images/migrationChild.png";
import bullet from "../../Assets/Images/beforeBullet.png";
import bookApp from "../../Assets/Images/calanderApp.png";
import chatApp from "../../Assets/Images/chatEnquire.png";

import { useHistory } from "react-router-dom";

export default function SkillAssessmentsPage() {
  let history = useHistory();

  const AppointementPage = () => {
    history.push("/bookanappointment");
    window.scrollTo(0, 0);
  };

  const ContactUsPage = () => {
    history.push("/ContactUs");
    window.scrollTo(0, 0);
  };
  return (
    <>
      <section className="bg-white">
        <Container>
          <Row className="py-5 responsivePadding">
            <Col className="col-lg-12">
              <div className="imgBG pt-3 d-flex ">
                <img src={SKILLBG} className="w-100" />
              </div>

              <div className="col-lg-12">
                <div className="innerSecondSection mt-4 d-flex  mt-3">
                  {/* <h2 className="text-start">Skills assessment Overview</h2> */}
                </div>
                <p className="  justifyText mt-2 normal">
                  Skill assessment is a crucial step in the Australian skilled
                  migration process, as it evaluates an applicant's
                  qualifications and work experience to ensure they meet the
                  required standards for a specific occupation. Skill
                  assessments are conducted by designated assessing authorities,
                  which are independent organizations appointed by the
                  Australian Department of Home Affairs.
                </p>
                <p className="  justifyText mt-2 normal">
                  Each assessing authority has its own specific requirements,
                  fees, and processing times. It is essential to consult the
                  relevant authority's website for detailed information on their
                  assessment criteria and procedures.<br></br> Please note that skill
                  assessment requirements and eligibility criteria may change
                  time to time.
                </p>
              </div>

              <div className="col-lg-12">
                <div className="innerHowMuchSection d-flex  ">
                  <Row>
                    <div className="innerSecondSection mt-3 d-flex mb-3 normal">
                      <h4 className="text-start">
                        {" "}
                        The purpose of a skill assessment is to:
                      </h4>
                    </div>

                    <div className="d-flex align-items-center">
                      <img
                        src={bullet}
                        width="20"
                        height="20"
                        className="mb-3"
                      />
                      <p className="ms-3 normal justifyText justifyText">
                        Confirm that the applicant's qualifications are
                        comparable to the Australian Qualification Framework
                        (AQF) standards for the nominated occupation.
                      </p>
                    </div>

                    <div className="d-flex align-items-center">
                      <img
                        src={bullet}
                        width="20"
                        height="20"
                        className="mb-3"
                      />
                      <p className="ms-3 normal justifyText ">
                        Verify that the applicant has the necessary work
                        experience in their nominated occupation.
                      </p>
                    </div>
                  </Row>
                </div>
                <Row className=" ">
                  <div className="innerSecondSection mt-4 d-flex mb-3 normal">
                    <h4 className="text-start">
                      {" "}
                      The skill assessment process varies depending on the
                      assessing authority and the occupation. Generally,
                      applicants need to provide:
                    </h4>
                  </div>

                  <div className="d-flex align-items-center">
                    <img src={bullet} width="20" height="20" className="mb-3" />
                    <p className="ms-3 normal justifyText">
                    Certified copies of qualification certificates and academic transcripts
                    </p>
                  </div>

                  <div className="d-flex align-items-center">
                    <img src={bullet} width="20" height="20" className="mb-3" />
                    <p className="ms-3 normal justifyText">
                      Evidence of relevant work experiences, such as employment
                      contracts, payslips, job descriptions, and reference
                      letters from employers
                    </p>
                  </div>

                  <div className="d-flex align-items-center">
                    <img src={bullet} width="20" height="20" className="mb-3" />
                    <p className="ms-3 normal justifyText">
                      Proof of English language proficiency, if applicable.
                    </p>
                  </div>

                  <div className="d-flex align-items-center">
                    <img src={bullet} width="20" height="20" className="mb-3" />
                    <p className="ms-3 normal justifyText">
                      Any additional documents or information required by the
                      assessing authority.
                    </p>
                  </div>
                </Row>
                <Row className=" ">
                  <div className="innerSecondSection mt-4 d-flex mb-3 normal">
                    <h4 className="text-start">
                      {" "}
                      A positive skill assessment is typically required for the
                      following Australian skilled visas:
                    </h4>
                  </div>

                  <div className="d-flex align-items-center">
                    <img src={bullet} width="20" height="20" className="mb-3" />
                    <p className="ms-3 normal justifyText">
                      Skilled Independent Visa (Subclass 189)
                    </p>
                  </div>

                  <div className="d-flex align-items-center">
                    <img src={bullet} width="20" height="20" className="mb-3" />
                    <p className="ms-3 normal justifyText">
                      Skilled Nominated Visa (Subclass 190)
                    </p>
                  </div>

                  <div className="d-flex align-items-center">
                    <img src={bullet} width="20" height="20" className="mb-3" />
                    <p className="ms-3 normal justifyText">
                      Skilled Work Regional (Provisional) Visa (Subclass 491)
                    </p>
                  </div>

                  <div className="d-flex align-items-center">
                    <img src={bullet} width="20" height="20" className="mb-3" />
                    <p className="ms-3 normal justifyText">
                      Temporary Skill Shortage (TSS) Visa (Subclass 482) – in
                      certain cases
                    </p>
                  </div>
                </Row>
              </div>

              <div className="col-lg-12 d-flex m-auto justify-content-center mt-4">
                <div className="col-lg-4">
                  <Button
                    className="btnInfo w-100 ps-2 pe-0"
                    onClick={AppointementPage}
                  >
                    {" "}
                    <img src={bookApp} className="img-fluid pe-2" /> BOOK AN
                    APPOINTEMENT
                  </Button>
                  <Button
                    className="mt-3 lightAlice border-0 textColorInfo w-100"
                    onClick={ContactUsPage}
                  >
                    <img src={chatApp} className="img-fluid pe-2" /> ENQUIRE NOW
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
