import React, { useState, useEffect } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../News/News.css";
import News1 from "../../../Assets/Images/News-1.png";
import News2 from "../../../Assets/Images/News-2.png";
import News3 from "../../../Assets/Images/News-3.png";

const apiKey = "71fc1cdfa4fcbfaece15ab70bc4ce4df"; // Replace with your GNews API key

export default function NewsSlider() {
  const [newsArticles, setNewsArticles] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const fetchNews = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/admin/news`
      );
      console.log("News response:", response.data);
      setNewsArticles(response.data.articles);
    } catch (error) {
      console.log("Error fetching news articles:", error);
    }
  };

  useEffect(() => {
    fetchNews();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  const getSlidesToShow = () => {
    if (windowWidth <= 768) {
      return 1; // Number of slides to show for window widths less than or equal to 768px (mobile devices)
    } else if (windowWidth <= 1400) {
      return 2; // Number of slides to show for window widths less than or equal to 1400px (larger mobile devices/tablets)
    } else {
      return 3; // Number of slides to show for window widths greater than 1400px (desktop)
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: getSlidesToShow(),
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
    
  };

  return (
    <div className="bg-white body pagepadding pt-4">
      <Container>
        <div className="normaldivpadding capitalize textColorInfo ">
          <h5 className="h1AfterFAQ responsiveFontLargeMedium">Latest News</h5>
       
        </div>
        <h2 className="normaldivpadding newshead1 normal pt-0 pb-0">
          In Your <span className="bolder">Inbox News</span>
        </h2>
        <Slider {...settings}>
          {newsArticles?.map((article, index) => (
            <div key={index} className="slider-item">
              <a
                href={article?.url}
                target="_blank"
                rel="noopener noreferrer"
                className="news-link"
              >
                <Card className="news">
                  <Image src={article?.image} height="300" width="400" />
                  <span className="textColorInfo text-start px-3 mt-3 d-flex">
                    <small className="bolder">
                      <small className="NewsDate ps-2">
                        {new Date(article?.publishedAt).toLocaleDateString(
                          "en-US",
                          {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )}
                      </small>
                    </small>
                  </span>
                  <span></span>
                  <Card.Title className="bolder text-start px-3 py-3">
                    {truncateString(article?.title, 50)}
                  </Card.Title>
                  <Card.Text className="textColorDefault text-start px-3">
                    <small className="normal">
                      {truncateString(
                        article?.description.replace(/<[^>]+>/g, ""),
                        100
                      )}
                    </small>
                  </Card.Text>
                </Card>
              </a>
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  );
}
