import React from 'react'
import Navbar from '../Components/Layout/NavBar/NavBar'
import Footer from '../Components/Layout/Footer/Footer'
import BookAnAppointment from '../Components/BookAnAppointmentPage/BookAnAppointmentPage'
import VisaProcessBanner from '../Components/VisaProcessPage/VisaProcessBanner/VisaProcessBanner'
import VisaProcessPage from '../Components/VisaProcessPage/VisaProcessPage'
import FooterVideo from '../Components/Layout/FooterVideo/FooterVideo'

export default function VisaProcess() {
  return (
    <>
      <Navbar/>
      <VisaProcessBanner/>
      <VisaProcessPage/>
      <FooterVideo/>
      <Footer/>
    </>
  )
}
