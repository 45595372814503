import React, { useState } from "react";
import "../Review/CustomerReview.css";
import { Container, Carousel, Row, Col, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Review from "../../../Assets/Images/avatar review 1.jpg";
import Reviewstar from "../../../Assets/Images/Star 1.png";
import CommentAdd from "../../../Assets/Images/commentAdd.png";
import Doubletick from "../../../Assets/Images/doubletick.png";
import CustomerReviewPopup from "./CustomerReviewPopup";
import TextField from "@mui/material/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useEffect } from "react";
import Successmessageicon from "../../../Assets/Images/Success-message-icon.png";
import _defaultAvtar from "../../../Assets/Images/defaultAvtar.png";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%", // Adjust the width as desired
  maxWidth: "50%",
  maxHeight: "99%", // Add a maxHeight to prevent overlap and cutoff
  overflow: "auto", // Enable scrolling if the content exceeds maxHeight
  bgcolor: "#FFFFFF",
  border: "0",
  boxShadow: 24,
  p: 4,
};

export default function CustomerReview() {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [rating, setRating] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [review, setReview] = useState("");
  const [error, seterror] = useState(false);
  const [image, setImage] = useState(null);
  const [reviews, setreviews] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let history = useHistory();
  const emailRegex = /^\S+@\S+\.\S+$/;
  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!emailRegex.test(email)) {
      seterror(true);
      return;
    }

    // Submit the form data to your server or perform desired actions
    let formData = new FormData();
    formData.append("fullName", name);
    formData.append("rating", rating);
    formData.append("description", review);
    formData.append("email", email);
    formData.append("review_image", image);
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/admin/review`,
      formData
    ).then((res) => {
        if (res.data.statuscode === 200) {
          // Form submission successful
          setRating(0);
          setName("");
          setEmail("");
          setReview("");
          setImage(null);
          seterror(false);
          getReviews();
          handleClose();
          setSubmitSuccess(true); // Set submitSuccess state to true
          setSuccessModalOpen(true); // Open the success modal
        }
      })
      .catch((error) => {
        // Handle error
      });
  };

  const getReviews = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/admin/fetchallReview`)
      .then((res) => {
        if (res.data.statuscode == 200) {
          console.log(res.data.data);
          setreviews(res.data.data);
        }
      });
  };

  useEffect(() => {
    getReviews();
  }, []);

  const OpenReviewPopup = () => {
    history.push("/customerreviewpopup");
    window.scrollTo(0, 0);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div className="Bgcolor">
      <Container>
        <Row>
          <Col className="col-lg-12 p-5">
            <div className=" justify-content-center pb-4 textColorInfo">
              <h5 className="h1AfterFAQ responsiveFontLargeMedium">Customer Reviews</h5>
            </div>
            <Carousel className="">
              {reviews.map((review) => (
                <Carousel.Item>
                  <div className="justify-content-center reviewblock bg-white ">
                    <img
                      className="personal-avatar"
                      height="130"
                      width="130"
                      src={
                        review?.image
                          ? review?.image 
                          : _defaultAvtar
                      }
              
                    />
                    <div className="justify-content-center  bg-white py-3">
                      {Array.from({ length: review?.rating }, (_, index) => (
                        <img className="" src={Reviewstar} alt="Second slide" />
                      ))}
                    </div>

                    {/* <h1 className=''>Get your visa approved swiftly-1</h1> */}
                    {/* <h2>World Most Trusted Immigration Company</h2> */}
                    <center>
                      <p className="bolder w-75 ">{review?.description}</p>
                    </center>
                    <p className="bolder">
                      {review?.fullName}  
                      {/* <small className="medium">
                        <small> {review?.email}</small>
                      </small> */}
                    </p>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>

            <Button
              className="btnInfo d-flex justify-content-center m-auto mt-5 px-5"
              onClick={handleOpen}
            >
              {" "}
              <div className="d-flex align-items-center justify-content-between">
                <img
                  src={CommentAdd}
                  className="img-fluid pe-2"
                  alt="Book Appointment"
                />
                <span className="text-nowrap text-white">Add Review</span>
              </div>
            </Button>
          </Col>
        </Row>
      </Container>

      <Modal
        className="pt-0"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle} className="py-3 overflow-auto">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className="innerModalbody">
              <div className="left-arrow pointer" onClick={handleClose}>
                <i className="fa fa-close fa-2x text-black text-end ms-auto d-block" />
              </div>
              <div className="bg-transparent innerBoxChild d-flex align-items-start">
                <div className="personal-image d-block m-auto">
                  <label className="label">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                    <figure
                      className="personal-figure p-0 m-0"
                      height="130"
                      width="130"
                    >
                      <div>
                        <img
                          className="personal-avatar"
                          height="130"
                          width="130"
                          src={
                            image
                              ? URL.createObjectURL(image)
                              : "https://s3-us-west-2.amazonaws.com/s.cdpn.io/20625/avatar-bg.png"
                          }
                     
                        />
                      </div>

                      {/* <img
                        src="https://avatars1.githubusercontent.com/u/11435231?s=460&v=4"
                        className="personal-avatar"
                        height="130"
                        width="130"
                        alt="avatar"
                      /> */}
                      <div className="BackgroundInfo rounded-circle avatar-edit m-1">
                        <i className="fa fa-pencil text-white"></i>
                      </div>
                    </figure>
                  </label>
                </div>
              </div>
            </div>
          </Typography>

          <div className="innerBodyModal px-4">
            <span className="text-black  bolder text-center d-flex justify-content-center">
              How do you rate our services
            </span>
            <div className="d-flex justify-content-around mt-1">
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={star}
                  style={{
                    cursor: "pointer",
                    color: star <= rating ? "#3E66DF" : "gray",
                  }}
                  onClick={() => handleRatingChange(star)}
                  className="starRate"
                >
                  &#9733;
                </span>
              ))}
            </div>

            <Form className="mt-3">
              <Row>
                <div className="d-flex">
                  <div className="w-100">
                    <TextField
                      id="filled-basic"
                      label={<span>Full Name</span>}
                      variant="filled"
                      className="w-100"
                      value={name}
                      onChange={handleNameChange}
                    />
                  </div>
                </div>
              </Row>

              <Row className="mt-4">
                <div className="d-flex">
                  <div className="w-100">
                    <TextField
                      id="filled-basic"
                      label={<span>Email</span>}
                      variant="filled"
                      className="w-100"
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </div>
                </div>
                {error && !emailRegex.test(email) && (
                  <div className="text-danger">
                    Please enter a valid email address.
                  </div>
                )}
              </Row>

              <Row className="mt-4">
                <div className="d-flex">
                  <div className="w-100">
                    <TextField
                      multiline
                      rows={2}
                      cols={50}
                      className="mb-4 w-100"
                      label={<span>Write Your Review</span>}
                      variant="filled"
                      color="info"
                      value={review}
                      onChange={handleReviewChange}
                    />
                  </div>
                </div>
              </Row>
              <Button
                variant="contained"
                onClick={handleSubmit}
                className="d-flex justify-content-center m-auto btnInfo py-2 mt-1"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <img
                    src={Doubletick}
                    className="img-fluid pe-2"
                    alt="Book Appointment"
                  />
                  <span className="text-nowrap text-white">SUBMIT</span>
                </div>
              </Button>
            </Form>
          </div>
        </Box>
      </Modal>

      <Modal
        // Other modal props
        open={successModalOpen}
        onClose={() => setSuccessModalOpen(false)}
      >
        <Box sx={modalStyle} className="py-3 overflow-auto">
          <div className="">
            <center>
              <div
                className="left-arrow pointer"
                onClick={() => setSuccessModalOpen(false)}
              >
                <i className="fa fa-close fa-2x text-black text-end ms-auto d-block" />
              </div>
              <img src={Successmessageicon} className="mb-3 fw-12"></img>
              <br />
              <b>
                <h4 className="mb-3 bolder ">Success</h4>
              </b>
              <p className="textColorDefault mb-3">
                <b>Review Submitted Successfully!</b>
              </p>
              <Button
                variant="primary"
                className="text-white col-lg-10 rounded btnInfo justify-content-center"
                onClick={() => setSuccessModalOpen(false)}
              >
                Ok
              </Button>
            </center>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
