import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import "moment-timezone";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { makeStyles } from "@mui/styles";
import Loading from "../../Layout/loading/Loading";
import "./BookAppointmentSteps.css";
import axios from "axios";
import Select from "react-select";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#FFFFFF",
  border: "0",
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles({
  select: {
    borderBottom: "none",
  },
});

const generateTimeSlots = (timezone) => {
  const now = moment().tz(timezone);
  const dateTimeMoment = moment.tz(
    `${now.format("YYYY-MM-DD ")} ${now.format("HH:mm")}`,
    "YYYY-MM-DD HH:mm",
    timezone
  );

  const startOfDay = dateTimeMoment?.clone().startOf("day").hour(9); // Start at 9 AM
  const endOfDay = dateTimeMoment?.clone().startOf("day").hour(17); // End at 5 PM
  const timeSlots = [];

  while (startOfDay.isBefore(endOfDay)) {
    // if (startOfDay.isoWeekday() <= 5) {
    // Add only time slots on weekdays (Monday to Friday)
    timeSlots.push(startOfDay?.clone());
    //setWorkingTimeSlots((prew)=>[...prew, startOfDay?.clone()])
    //}
    startOfDay.add(30, "minutes"); // Increment by 30 minutes
  }
  //setWorkingTimeSlots(timeSlots);
  // setDateTimeSlots(timeSlots);
  //console.log(timeSlots,"jjjjj");    
  return timeSlots;
  // setTimeSlots(timeSlots)
};

export default function BookAnAppointmentSteps2(props) {
  const classes = useStyles();
  
  let timeZones = [
    {
      label: "(GMT-12:00) International Date Line West",
      country: "",
      timezone: "Etc/GMT+12",
    },
    {
      label: "(GMT-11:00) Midway Island, Samoa",
      country: "Samoa",
      timezone: "Pacific/Apia",
    },
    {
      label: "(GMT-10:00) Hawaii",
      country: "United States",
      timezone: "Pacific/Honolulu",
    },
    {
      label: "(GMT-09:00) Alaska",
      country: "United States",
      timezone: "America/Anchorage",
    },
    {
      label: "(GMT-08:00) Pacific Time (US & Canada)",
      country: "United States",
      timezone: "America/Los_Angeles",
    },
    {
      label: "(GMT-08:00) Tijuana, Baja California",
      country: "Mexico",
      timezone: "America/Tijuana",
    },
    {
      label: "(GMT-07:00) Arizona",
      country: "United States",
      timezone: "America/Phoenix",
    },
    {
      label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
      country: "Mexico",
      timezone: "America/Chihuahua",
    },
    {
      label: "(GMT-07:00) Mountain Time (US & Canada)",
      country: "United States",
      timezone: "America/Denver",
    },
    {
      label: "(GMT-06:00) Central America",
      country: "",
      timezone: "America/Belize",
    },
    {
      label: "(GMT-06:00) Central Time (US & Canada)",
      country: "United States",
      timezone: "America/Chicago",
    },
    {
      label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
      country: "Mexico",
      timezone: "America/Mexico_City",
    },
    {
      label: "(GMT-06:00) Saskatchewan",
      country: "Canada",
      timezone: "America/Regina",
    },
    {
      label: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
      country: "Colombia",
      timezone: "America/Bogota",
    },
    {
      label: "(GMT-05:00) Eastern Time (US & Canada)",
      country: "United States",
      timezone: "America/New_York",
    },
    {
      label: "(GMT-05:00) Indiana (East)",
      country: "United States",
      timezone: "America/Indiana/Indianapolis",
    },
    {
      label: "(GMT-04:30) Caracas",
      country: "Venezuela",
      timezone: "America/Caracas",
    },
    {
      label: "(GMT-04:00) Atlantic Time (Canada)",
      country: "Canada",
      timezone: "America/Halifax",
    },
    {
      label: "(GMT-04:00) Manaus",
      country: "Brazil",
      timezone: "America/Manaus",
    },
    {
      label: "(GMT-04:00) Santiago",
      country: "Chile",
      timezone: "America/Santiago",
    },
    {
      label: "(GMT-03:30) Newfoundland",
      country: "Canada",
      timezone: "America/St_Johns",
    },
    {
      label: "(GMT-03:00) Brasilia",
      country: "Brazil",
      timezone: "America/Sao_Paulo",
    },
    {
      label: "(GMT-03:00) Buenos Aires, Georgetown",
      country: "Argentina",
      timezone: "America/Argentina/Buenos_Aires",
    },
    {
      label: "(GMT-03:00) Greenland",
      country: "Greenland",
      timezone: "America/Godthab",
    },
    {
      label: "(GMT-03:00) Montevideo",
      country: "Uruguay",
      timezone: "America/Montevideo",
    },
    {
      label: "(GMT-02:00) Mid-Atlantic",
      country: "",
      timezone: "Atlantic/South_Georgia",
    },
    {
      label: "(GMT-01:00) Cape Verde Is.",
      country: "Cape Verde",
      timezone: "Atlantic/Cape_Verde",
    },
    {
      label: "(GMT-01:00) Azores",
      country: "Portugal",
      timezone: "Atlantic/Azores",
    },
    {
      label: "(GMT+00:00) Casablanca, Monrovia, Reykjavik",
      country: "Morocco",
      timezone: "Africa/Casablanca",
    },
    {
      label:
        "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
      country: "United Kingdom",
      timezone: "Europe/London",
    },
    {
      label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
      country: "Germany",
      timezone: "Europe/Berlin",
    },
    {
      label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
      country: "Slovakia",
      timezone: "Europe/Bratislava",
    },
    {
      label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
      country: "France",
      timezone: "Europe/Paris",
    },
    {
      label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
      country: "Croatia",
      timezone: "Europe/Zagreb",
    },
    {
      label: "(GMT+01:00) West Central Africa",
      country: "",
      timezone: "Africa/Lagos",
    },
    { label: "(GMT+02:00) Amman", country: "Jordan", timezone: "Asia/Amman" },
    {
      label: "(GMT+02:00) Athens, Bucharest, Istanbul",
      country: "Greece",
      timezone: "Europe/Athens",
    },
    {
      label: "(GMT+02:00) Beirut",
      country: "Lebanon",
      timezone: "Asia/Beirut",
    },
    { label: "(GMT+02:00) Cairo", country: "Egypt", timezone: "Africa/Cairo" },
    {
      label: "(GMT+02:00) Harare, Pretoria",
      country: "South Africa",
      timezone: "Africa/Johannesburg",
    },
    {
      label: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
      country: "Finland",
      timezone: "Europe/Helsinki",
    },
    {
      label: "(GMT+02:00) Jerusalem",
      country: "Israel",
      timezone: "Asia/Jerusalem",
    },
    {
      label: "(GMT+02:00) Minsk",
      country: "Belarus",
      timezone: "Europe/Minsk",
    },
    {
      label: "(GMT+02:00) Windhoek",
      country: "Namibia",
      timezone: "Africa/Windhoek",
    },
    {
      label: "(GMT+03:00) Kuwait, Riyadh, Baghdad",
      country: "Iraq",
      timezone: "Asia/Baghdad",
    },
    {
      label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
      country: "Russia",
      timezone: "Europe/Moscow",
    },
    {
      label: "(GMT+03:00) Nairobi",
      country: "Kenya",
      timezone: "Africa/Nairobi",
    },
    { label: "(GMT+03:30) Tehran", country: "Iran", timezone: "Asia/Tehran" },
    {
      label: "(GMT+04:00) Abu Dhabi, Muscat",
      country: "United Arab Emirates",
      timezone: "Asia/Dubai",
    },
    { label: "(GMT+04:00) Baku", country: "Azerbaijan", timezone: "Asia/Baku" },
    {
      label: "(GMT+04:00) Port Louis",
      country: "Mauritius",
      timezone: "Indian/Mauritius",
    },
    {
      label: "(GMT+04:00) Tbilisi",
      country: "Georgia",
      timezone: "Asia/Tbilisi",
    },
    {
      label: "(GMT+04:00) Yerevan",
      country: "Armenia",
      timezone: "Asia/Yerevan",
    },
    {
      label: "(GMT+04:30) Kabul",
      country: "Afghanistan",
      timezone: "Asia/Kabul",
    },
    {
      label: "(GMT+05:00) Ekaterinburg",
      country: "Russia",
      timezone: "Asia/Yekaterinburg",
    },
    {
      label: "(GMT+05:00) Islamabad, Karachi, Tashkent",
      country: "Pakistan",
      timezone: "Asia/Karachi",
    },
    {
      label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
      country: "India",
      timezone: "Asia/Kolkata",
    },
    {
      label: "(GMT+05:45) Kathmandu",
      country: "Nepal",
      timezone: "Asia/Kathmandu",
    },
    {
      label: "(GMT+06:00) Astana, Dhaka",
      country: "Bangladesh",
      timezone: "Asia/Dhaka",
    },
    {
      label: "(GMT+06:00) Sri Jayawardenepura",
      country: "Sri Lanka",
      timezone: "Asia/Colombo",
    },
    {
      label: "(GMT+06:00) Almaty, Novosibirsk",
      country: "Russia",
      timezone: "Asia/Novosibirsk",
    },
    {
      label: "(GMT+06:30) Yangon (Rangoon)",
      country: "Myanmar (Burma)",
      timezone: "Asia/Yangon",
    },
    {
      label: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
      country: "Thailand",
      timezone: "Asia/Bangkok",
    },
    {
      label: "(GMT+07:00) Krasnoyarsk",
      country: "Russia",
      timezone: "Asia/Krasnoyarsk",
    },
    {
      label: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
      country: "China",
      timezone: "Asia/Shanghai",
    },
    {
      label: "(GMT+08:00) Kuala Lumpur, Singapore",
      country: "Malaysia",
      timezone: "Asia/Kuala_Lumpur",
    },
    {
      label: "(GMT+08:00) Perth",
      country: "Australia",
      timezone: "Australia/Perth",
    },
    { label: "(GMT+08:00) Taipei", country: "Taiwan", timezone: "Asia/Taipei" },
    {
      label: "(GMT+08:00) Irkutsk, Ulaan Bataar",
      country: "Mongolia",
      timezone: "Asia/Ulaanbaatar",
    },
    {
      label: "(GMT+09:00) Osaka, Sapporo, Tokyo",
      country: "Japan",
      timezone: "Asia/Tokyo",
    },
    {
      label: "(GMT+09:00) Seoul",
      country: "South Korea",
      timezone: "Asia/Seoul",
    },
    {
      label: "(GMT+09:00) Yakutsk",
      country: "Russia",
      timezone: "Asia/Yakutsk",
    },
    {
      label: "(GMT+09:30) Adelaide",
      country: "Australia",
      timezone: "Australia/Adelaide",
    },
    {
      label: "(GMT+09:30) Darwin",
      country: "Australia",
      timezone: "Australia/Darwin",
    },
    {
      label: "(GMT+10:00) Brisbane",
      country: "Australia",
      timezone: "Australia/Brisbane",
    },
    {
      label: "(GMT+10:00) Canberra, Melbourne, Sydney",
      country: "Australia",
      timezone: "Australia/Sydney",
    },
    {
      label: "(GMT+10:00) Hobart",
      country: "Australia",
      timezone: "Australia/Hobart",
    },
    {
      label: "(GMT+10:00) Guam, Port Moresby",
      country: "Papua New Guinea",
      timezone: "Pacific/Port_Moresby",
    },
    {
      label: "(GMT+10:00) Vladivostok",
      country: "Russia",
      timezone: "Asia/Vladivostok",
    },
    {
      label: "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
      country: "Russia",
      timezone: "Asia/Magadan",
    },
    {
      label: "(GMT+12:00) Auckland, Wellington",
      country: "New Zealand",
      timezone: "Pacific/Auckland",
    },
    {
      label: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
      country: "Fiji",
      timezone: "Pacific/Fiji",
    },
    {
      label: "(GMT+13:00) Nuku'alofa",
      country: "Tonga",
      timezone: "Pacific/Tongatapu",
    },
  ];
  const [selectedTimezone, setSelectedTimezone] = useState(timeZones.find((obj) => obj.timezone === props.ColsolerCountry));
  const [searchQuery, setSearchQuery] = useState("");
  // const [date, setDate] = useState(new Date(Date.now()).toISOString())
  // let [date, setDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substr(0, 10) + 'T00:00:00.000Z')
  let [date, setDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
      .toISOString()
      .split("T")[0]
  );
  //  let [date, setDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)))
  let [currentDate, setcurrentDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  //let [DesableDate, setDesableDates] = useState([]);
  // const [Availabledates, setAvailability] = useState([]);
  // const [Slots, setSlots] = useState([]);
  // const [TimeSlots, setTimeSlots] = useState([]);
  const [timeZone, setTimeZone] = useState(props.ColsolerCountry); //static props.ColsolerCountry)//"America/New_York")
  const [loading, setloading] = React.useState(true);
  const [Appontment_leaveDateTime, setAppontmentleaveDateTime] = useState([]); // static
  const [DateTimeSlots, setDateTimeSlots] = useState(generateTimeSlots(props.ColsolerCountry)); // static
  const [LeaveTimeSlots, setleaveDateTime] = useState([]); // dynemic based on changes
  const [WorkingTimeSlots, setWorkingTimeSlots] = useState(generateTimeSlots(props.ColsolerCountry)); // dynemic based on changes
  // console.log(WorkingTimeSlots, "WorkingTimeSlots");
  // console.log(DateTimeSlots.toString(), "leaveDateTime");
  // console.log(TimeSlots.toString(), "leaveDateTime");


  const getAvailibility = async (id, newdate) => {
    // debugger;
    setloading(true)
    props.SecondStepData(false);
    var lstLeaveTimeSlots = [];
    const originalDate = moment(newdate);
    const convertedDate = originalDate.format("YYYY-MMM-DD");
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/admin/appoinment/get-availibility2`,
        { currentDate: convertedDate, consoler_id: id }
      )
      .then((res) => {
        setloading(false);
        if (res.data.statuscode == 200) {
          res.data.data.booked_appointment_dates.map((dates) => {
            if (!lstLeaveTimeSlots.includes(dates)) {
              lstLeaveTimeSlots.push(dates);
              setAppontmentleaveDateTime((prewdates) => [...prewdates, dates]);
            }
          });
          setleaveDateTime(lstLeaveTimeSlots);
          setloading(false)
        } else {
          console.log("error");
        }
        setAllSlotsBasedonChanges(newdate, timeZone, lstLeaveTimeSlots);
      });
  };

  const handleMonthChange = (d) => {
    setDate(moment(d.$d).format("YYYY-MM-DD"));
    getAvailibility(props.Consoler.id, moment(d.$d).format("YYYY-MM-DD"));
  };

  const handleDayChange = (d) => {
    setDate(moment(d.$d).format("YYYY-MM-DD"));
    getAvailibility(props.Consoler.id, moment(d.$d).format("YYYY-MM-DD"));
  };

  const handleTime = (time) => {
    console.log(time.format('hh:mm:ss'), "timetimetimeopopop")
    var Timeset = time.format("HH:mm");
    createCustomDateTime(date, Timeset, timeZone, props.ColsolerCountry); // pass timezone from drodown
    let matchedObject = timeZones.find((obj) => obj.timezone === timeZone);
    props.SecondStepData({ time, date, timeZone: matchedObject });
    props.handleNext();
  };
  const createDateTimeOnchangeTimeZone = (date, time, fromTimeZone, ToTimezone) => {
    try {
      var dateTime = moment.tz(
        `${date} ${time}`,
        "YYYY-MM-DD HH:mm",
        fromTimeZone
      );
      const converteddateTime = dateTime.tz(ToTimezone);
      return converteddateTime;
    } catch (e) {
      console.log("An error occurred:", e);
    }
  };
  const createCustomDateTime = (
    objdate,
    time,
    fromTimeZone,
    staticTimezone
  ) => {
    try {
      var dateTime = moment.tz(
        `${objdate} ${time}`,
        "YYYY-MM-DD HH:mm",
        fromTimeZone
      );
      const AppointmentDate = dateTime.tz(staticTimezone);
      const AppointmentDatee = AppointmentDate.toString();
      props.AppointmentDate(AppointmentDatee);
    } catch (e) {
      console.log("An error occurred:", e);
    }
  };
  const createDateTime = (date, time, fromTimeZone) => {
    // debugger
    try {
      var dateTime = moment.tz(
        `${date} ${time}`,
        "YYYY-MM-DD HH:mm",
        fromTimeZone
      );
      return dateTime;
    } catch (e) {
      console.log("An error occurred:", e);
    }
  };
  const covertSlotBasedOnTimezone = (
    objdate,
    time,
    fromTimeZone,
    toTimeZone
  ) => {
    try {
      var dateTime = moment.tz(
        `${objdate} ${time}`,
        "YYYY-MM-DD HH:mm",
        fromTimeZone
      );
      const convertedDateTime = dateTime.tz(toTimeZone);

      return moment.tz(
        `${convertedDateTime.format("YYYY-MM-DD ")} ${convertedDateTime.format(
          "HH:mm"
        )}`,
        "YYYY-MM-DD HH:mm",
        toTimeZone
      );
    } catch (e) {
      console.log("An error occurred:", e);
    }
  };

  const selectCountry = (e) => {
    // debugger
    setSelectedTimezone(e)
    setTimeZone(e.timezone);
    setAllSlotsBasedonChanges(date, e.timezone);
    // setAllSlotsBasedonChanges(newdate);
  };
  const filteredOptions = timeZones.filter((option) =>
  option.label.toLowerCase().includes(searchQuery.toLowerCase())
);

  const setAllSlotsBasedonChanges = (newdate, CountryTimeZone, lstLeaveTimeSlots = Appontment_leaveDateTime) => {
    // debugger;
    var slots = [];
    var invalid_slots = [];
    invalid_slots = getNotAvailableTimes(newdate, CountryTimeZone, lstLeaveTimeSlots);
    // console.log(invalid_slots,"DateTimeSlots")
    DateTimeSlots.map((item) => {
      //if (item.format("YYYY-MM-DD") == date) {
      if (CountryTimeZone !== props.ColsolerCountry) {
        var itemSlot = covertSlotBasedOnTimezone(
          item.format("YYYY-MM-DD"),
          item.format("HH:mm"),
          props.ColsolerCountry,
          CountryTimeZone
        );
      } else {
        itemSlot = item;
      }
      // console.log(invalid_slots.includes(itemSlot.format("HH:mm").toString()));
      if (!invalid_slots.includes(itemSlot.format("HH:mm").toString())) {
        slots.push(itemSlot);
        // console.log(itemSlot, "itemSlot")
      }
      // }
    });

    setWorkingTimeSlots(slots);
    // console.log(slots, "slots");
  };

  const getNotAvailableTimes = (newdate, CTimeZone, lstLeaveTimeSlots) => {
    var invalid_slots = [];
    var setLeaveTimeSlots = [];
    lstLeaveTimeSlots.map((leave) => {
      //var check_slots = moment(leave).tz(CTimeZone);
      var check_slots;
      if(CTimeZone == props.ColsolerCountry){
        check_slots = createDateTime(
          moment(leave).format("YYYY-MM-DD"),
          leave?.split(" ")[4],
          CTimeZone
        );
      }
      else{
        check_slots = createDateTimeOnchangeTimeZone(
          moment(leave).format("YYYY-MM-DD"),
          leave?.split(" ")[4],
          props.ColsolerCountry,
          CTimeZone
        );
      }
      // console.log(leave, 'leave');
      // const check_slots = createDateTime(
      //   moment(leave).format("YYYY-MM-DD"),
      //   leave?.split(" ")[4],
      //   CTimeZone
      // );
      // console.log("check_slots",check_slots);
      if (check_slots.format("YYYY-MM-DD").toLowerCase() == newdate) {
        if (!invalid_slots.includes(check_slots.format("HH:mm"))) {
          setLeaveTimeSlots.push(check_slots);
          invalid_slots.push(check_slots.format("HH:mm"));
        }
      }
    });
    setleaveDateTime(setLeaveTimeSlots);
    return invalid_slots;
  };

  useEffect(() => {
    setloading(true);
    getAvailibility(props.Consoler.id, date);
    //setloading(false);
  }, []);

  return (
    <>
      <section className="dateandtimebox h-100 mb-5 mt-4 responsiveMainForMobile">
        <Container>
          {loading ? (
            <Loading />
          ) : ( ""  )}
          <div className="headerText">
            <h3>Select Date and Time</h3>
            <span className="text-center w-25 my-5 px-3">
              To see the available appointment times in your local time zone, select your time zone and then choose a date and time from the list provided below. <br></br> You can schedule appointments online or in-person.
            </span>
          </div>
           
            <Row className="mt-5 py-5">
              <Col className="Col-lg-6">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StaticDatePicker
                    className="d-block mx-auto p-0 text-start custom-datepicker text-start"
                    defaultValue={dayjs(date)}
                    minDate={dayjs(currentDate)}
                    onMonthChange={handleMonthChange}
                    // shouldDisableDate={shouldDisableDate}
                    onChange={(newDate) => handleDayChange(newDate)}
                    slotProps={{
                      actionBar: {
                        actions: [""],
                      },
                    }}
                  // className="custom-datepicker text-start"
                  />
                </LocalizationProvider>

                <div className="notice my-5 py-5 d-flex">
                  <span className="text-gray text-start">
                    First Free 30 min (Face to Face Consultation) -{" "}
                    {props.Consoler.fullName}
                    (Australia)
                  </span>
                </div>
              </Col>
              <Col className="Col-lg-6">
                {/* <div className="mainSelectDateTime  pb-5">
                  <div className="dateCalnderTimeLabel py-3 bolder ps-3">
                    Select time zone
                  </div>
                  <FormControl
                    fullWidth
                    className="selectdateCalnderTimeLabel border-0 ps-3"
                  >
                    <NativeSelect
                      disableUnderline
                      className="py-2"
                      defaultValue="(GMT +05:45) Nepal Time - Kathmandu"
                      value={timeZone}
                      onChange={(e) => selectCountry(e)}
                      inputProps={{
                        name: "age",
                        id: "uncontrolled-native",
                      }}
                      classes={{ select: classes.select }}
                    >
                      {timeZones.map((ele) => (
                        <option
                          key={ele.label}
                          value={ele.timezone} //
                          // onChange={e => selectCountry(ele.country)}
                          style={{ padding: "8px" }}
                        >
                          <div className="timezone-option">
                            <div className="timezone-label">{ele.label}</div>
                            <div className="timezone-country">
                              {ele.country}
                            </div>
                          </div>
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </div> */}

<div className="mainSelectDateTime pb-5">
      <div className="dateCalnderTimeLabel py-3 bolder ps-3">
        Select time zone
      </div>
      <FormControl fullWidth className="selectdateCalnderTimeLabel border-0 ps-3">
        <Select
          className="py-2 text-start"
          value={selectedTimezone}
          onChange={(e) => selectCountry(e)}
          options={filteredOptions}
          onInputChange={(inputValue) => setSearchQuery(inputValue)}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.timezone}
          placeholder="Search..."
        />
      </FormControl>
    </div>

                <div className="mainSelectDateTimeSlot ">
                  <div className="dateCalnderTimeLabel py-3 bolder ps-3">
                    Select time slot
                  </div>
                  <div className="mainSlotInner">
                    {WorkingTimeSlots &&
                      WorkingTimeSlots.map((time, i) => {
                        return (
                          <div
                            className="dateCalnderTimeLabelinner py-3 bolder ps-3"
                            key={i}
                            onClick={() => handleTime(time)}
                          >
                            {time.format("h:mm A")}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Col>
            </Row>
        </Container>
      </section>
       
    </>
  );
}
