import React, { useEffect, useState } from "react";
import "./AppointmentPage.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Loading from "../Layout/loading/Loading";

export default function AppointmentPage() {
  let history = useHistory();
  const [consolerName, setConsolerName] = useState('')
  const [appointmentDate, setAppointmentDate] = useState('')
  const [appointmentTime, setAppointmentTime] = useState('')
  const [appointmentId, setAppointmentId] = useState('')
  const [isDel, setDel] = useState(false)
  const [loading, setLoading] = useState('')
  const getappoinment = async (id) => {
    setLoading(true);
    let res = await axios.post(
      process.env.REACT_APP_SERVER_URL + "/api/admin/appoinment/getappoinment",
      { id }
    );
    console.log(res);
    setLoading(false);
    if (res.data.statuscode == 200) {
      setDel(false)
      let data = res.data.appoinment;
      console.log("data:", data)
      // const dateObj = new Date(data.appointment_date);
      // const year = dateObj.getFullYear();
      // const month = dateObj.getMonth() + 1; // Months are zero-based, so we add 1
      // const day = dateObj.getDate();
      // const hours = dateObj.getHours();
      // const minutes = dateObj.getMinutes();
      setAppointmentDate(data.appointment_date)
      // setAppointmentTime(hours + ":" + minutes)
      setAppointmentId(data._id)
      setConsolerName(data?.consolerDetail?.consolerName)
    } else {
      setDel(true)
    }
  };

  const home = () => {
    history.push("/");
    window.scrollTo(0, 0);
  };
  const deleteAppointment = async (id) => {
    console.log(id)
    setLoading(true)
    setDel(true)
    let res = await axios.post(
      process.env.REACT_APP_SERVER_URL + "/api/admin/appoinment/deleteAppoinment",
      { id }
    );
    if(res.data.statuscode==200){
      setDel(true)
      setLoading(false)
    }
  }
  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const appoinment_id = searchParams.get('appoinment_id');
    getappoinment(appoinment_id)
  }, [])

  return (
    <section className=" pb-5 mb-5 mt-4"> {loading ? (
      <Loading />
    ) : ("")}
      {isDel ? <Container className="mt-5 pt-5">
        <Row>
          <Col className="col-lg-12 d-flex m-auto justify-content-center align-items-center">
            <Col className="col-lg-8">
              <div className="appointment-page rounded-3">
                <h1 className="appointment-heading"></h1>
                <div className="image-container">
                  <img height={400} width={400} src="https://img.freepik.com/free-vector/cancel-concept-illustration_114360-3026.jpg?w=826&t=st=1685095955~exp=1685096555~hmac=d3996c600cc17745e422d59d00937920e1abe6109a88aab0620d968c55b13771" />
                </div>
                <h3 className="appointCancelledText py-3">
                  Your Appointment Is Cancelled Successfully.
                </h3>
                <Button className="btnInfo btn btn-default rounded-2 my-3 mb-5" onClick={home}>Go To HomePage</Button>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
        :
        <Container>
          <div className="headerText">
            <h3>Confirmation</h3>
            <span className="text-center w-25 my-5">
              To see the available appointment times in your local time zone, select your time zone and then choose a date and time from the list provided below. <br></br> You can schedule appointments online or in-person.
            </span>
          </div>

          <Row>
            <Col className="col-lg-12 mt-5 d-flex m-auto justify-content-center">
              <table className="col-lg-8 customTable">
                <tr className="py-3 customRowTable">
                  <td className="customTDTable">Agent</td>
                  <td className="customTDTable">{consolerName}</td>
                </tr>
                <tr className="py-3">
                  <td className="customTDTable">Date & Time</td>
                  <td className="customTDTable">
                    {/* {" "}
                {props.Details.date
                  ? new Date(props.Details.date).toLocaleString("en-US", {
                      weekday: "short",
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })
                  : "-"}{" "}
                at{" "} */}
                    {appointmentDate ? appointmentDate : "-"}
                  </td>
                </tr>
                <tr className="py-3">
                  <td className="customTDTable">Booking ID</td>
                  <td className="customTDTable">{appointmentId}</td>
                </tr>
              </table>


            </Col>
            <Row className="d-flex justify-content-center">
              <Col className="col-lg-5 mt-4">
                <div className="">
                  <Button
                    className="btn btn-default btnInfo w-100 mt-4 py-3"
                    onClick={() => deleteAppointment(appointmentId)}
                  > CANCEL
                    APPOINTMENT
                  </Button>
                </div>
              </Col>
            </Row>
          </Row>
        </Container>}
    </section>
  );
}
