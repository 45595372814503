
const base_url = 'http://party.gvmtechnologies.com:5555';

//Google API email Authantication Start
// const CLIENT_ID = '70271022962-vclajnhvde7gr2ssj9tn2sv0oi6anu0k.apps.googleusercontent.com'
// const CLIENT_KEY = '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'
//Google API Ends

//Google Scope Start
const SCOPE="https://mail.google.com https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/contacts https://www.googleapis.com/auth/contacts.other.readonly"
//Google Scope End

// redirect url start
// const REDIRECT_URL="http://localhost:3000/email_account/AuthCallback"
// redirect url end

// redirect url start
const HomePage="http://localhost:3000/home"
const LoginPage="http://localhost:3000/login"
// redirect url end


export const CommonConstants = {
    BASE_URL: base_url,
    SCOPE:SCOPE,
    HomePage:HomePage,
    LoginPage:LoginPage,
    Title:' | Focusvisa', //Title of page
    // show_rows:[10,20,50,100],  
};