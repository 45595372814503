import React from "react";
import "./VisaPricingEstimatorPage.css";
import { Container, Row, Col, Button } from "react-bootstrap";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SKILLBG from "../../Assets/Images/careerIner.png";
import MigrationBG1 from "../../Assets/Images/migrationChild.png";
import bullet from "../../Assets/Images/beforeBullet.png";
import bookApp from "../../Assets/Images/calanderApp.png";
import chatApp from "../../Assets/Images/chatEnquire.png";

import { useHistory } from "react-router-dom";


export default function VisaPricingEstimatorPage() {

  let history = useHistory();

  const AppointementPage=()=> {
    history.push("/bookanappointment");
    window.scrollTo(0, 0)
  }


  const ContactUsPage=()=> {
    history.push("/ContactUs");
    window.scrollTo(0, 0)
  }
  return (
    <>
        <section className="bg-white">
        <Container>
          <Row className="py-5">
            <Col className="col-lg-12">
          
            
                <div className="imgBG pt-3 d-flex mt-3 responsivePadding">
                  <img src={SKILLBG} width={1300} className="img-fluid"/>
                </div>

                <div className="col-lg-12">
                  <div className="innerSecondSection  d-flex">
                    <h2 className="text-start responsivePadding mt-3">Introduction</h2>
                  </div>
                  <p className="textColorDefault normal  justifyText mt-2  responsivePadding">
                  Visa Pricing Estimator can vary depending on the type of visa you're applying for, the number of applicants, and additional services you may require. To accurately estimate your visa fees, visit the official Australian Department of Home Affairs website and use their Visa Pricing Estimator tool. This tool allows you to select the specific visa you're applying for, enter the necessary details, and obtain an estimate of the associated fees. Keep in mind that fees are subject to change and may be updated periodically by the Australian government.
                  <br ></br> <div className="mt-2"> Please check the Visa Pricing Estimator <a  href="https://immi.homeaffairs.gov.au/visas/visa-pricing-estimator" className="text-blue text-decoration-underline" target="_blank">Click Here</a></div>
                  </p>
              
               
                </div>

         



                {/* <div className="col-lg-12 d-flex m-auto justify-content-center mt-4">
                  <div className="col-lg-4">
                    <Button className="btnInfo w-100 ps-2 pe-0" onClick={AppointementPage} > <img src={bookApp} className="img-fluid pe-2"/> BOOK AN APPOINTEMENT</Button>
                    <Button className="mt-3 lightAlice border-0 textColorInfo w-100" onClick={ContactUsPage}><img src={chatApp} className="img-fluid pe-2"/>  ENQUIRE NOW</Button>
                  </div>
                </div> */}
       
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
