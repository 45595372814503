import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Counter from "./Counter";
import '../Milestone/Milestone.css'
export default function Milestone() {
  return (
    <div>
        <section className='BackgroundBody pagepadding'>
            <Container className=''>
                <Row>
                    <Col className='col-lg-12 col-md-12 col-sm-12 mb-5 '>
                        <h3><span className='bolder '>The milestones</span> <span className='normal'>We have accompolished</span></h3>
                    </Col>
                </Row>
                </Container>
                <Container>
                <Row>
                    <Col className='col-lg-3 col-md-3 col-sm-3 justiy-item-center my-3'>
                        <h1 className='Count bolder justiy-content-center h1set'><Counter  number={20} title="+" /></h1>
                        <h5 className='normal' >Years of<br/>Experience</h5>  
                    </Col>
                    <Col className='col-lg-3 col-md-3 col-sm-3 my-3'>
                        <h1 className='Count bolder justiy-content-center h1set'><Counter  number={2334} title="+" /></h1>

                        <h5 className='normal'>Satisfied clients</h5>
                    </Col>
                    <Col className='col-lg-3 col-md-3 col-sm-3 my-3'>
                        <h1 className='Count bolder justiy-content-center h1set'><Counter  number={3000} title="+" /></h1>
                        <h5 className='normal'>Immigration & Visa<br/>permits</h5>
                    </Col>
                    <Col className='col-lg-3 col-md-3 col-sm-3 my-3'>
                        <h1 className='Count bolder justiy-content-center h1set'><Counter  number={1800} title="+" /></h1>
                        <h5 className='normal'>Student Visa </h5>
                    </Col>
                </Row>
                
            </Container>
        </section>
      
    </div>
  )
}
    //   <div className="numbers">
        
    //     <Counter number={5175} title="Followers" />
    //     <Counter number={468} title="Following" />
    //   </div>