import React from 'react'
import Navbar from '../Components/Layout/NavBar/NavBar'
import Footer from '../Components/Layout/Footer/Footer'
import Services_Banner from '../Components/ServicesPage/Services_Banner/Services_Banner'
import Facilities from '../Components/ServicesPage/Facilities/Facilities'
import FooterVideo from '../Components/Layout/FooterVideo/FooterVideo'

export default function Services() {
  return (
    <>
      <Navbar Page={"Services"}/>
      <Services_Banner/>
      <Facilities/>
      <FooterVideo/>
      <Footer/>
    </>
  )
}
