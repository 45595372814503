import React from 'react'
import './MigrationProcessBanner.css'
import ServicesBanner from '../../../Assets/Images/insuranceBanner.jpg'



import { Card, Col, Container, Row } from 'react-bootstrap'

export default function VisaProcessBanner() {
  return (
    <section className='servicesbanner'>
      
      <img src={ServicesBanner} className='img-fluid banner_img'/>
      
      <Container fluid className='banner_container'>
        <h1 className='calcPoint'>MIGRATION PROCESS</h1>
        {/* <span className='innerServiceBannerText text-white bolder'>SERVICES · MIGRATION PROCESS</span> */}
      </Container>
    </section>
  )
}
