import React from 'react'
import Navbar from '../Components/Layout/NavBar/NavBar'
import Footer from '../Components/Layout/Footer/Footer'
import BookAnAppointment from '../Components/BookAnAppointmentPage/BookAnAppointmentPage'
import ExpancesInAustraliaBanner from '../Components/ExpancesInAustraliaPage/ExpancesInAustraliaBanner/ExpancesInAustraliaBanner'
import ExpancesInAustraliaPage from '../Components/ExpancesInAustraliaPage/ExpancesInAustraliaPage'
import FooterVideo from '../Components/Layout/FooterVideo/FooterVideo'

export default function ExpancesInAustralia() {
  return (
    <>
      <Navbar/>
      <ExpancesInAustraliaBanner/>
      <ExpancesInAustraliaPage/>
      <FooterVideo/>

      <Footer/>
    </>
  )
}
