import React,{ useState } from "react";
import "./MigrationProcessPage.css";
import { Container, Row, Col, Button } from "react-bootstrap";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import MigrationBG from "../../Assets/Images/migrationMain.jpeg";
import MigrationBG1 from "../../Assets/Images/migrationChild.png";
import bullet from "../../Assets/Images/beforeBullet.png";
import bookApp from "../../Assets/Images/calanderApp.png";
import chatApp from "../../Assets/Images/chatEnquire.png";

import { useHistory } from "react-router-dom";



export default function MigrationProcessPage() {

  let history = useHistory();
  const [multbanner, setmultbanner] = useState("MigrationVisa");


  const [expanded, setExpanded] = useState(false);


  const AppointementPage=()=> {
    history.push("/bookanappointment");
    window.scrollTo(0, 0)
  }

  const ContactUsPage=()=> {
    history.push("/ContactUs");
    window.scrollTo(0, 0)
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <>
        <section className="bg-white">
        <Container>
          <Row className="py-5 ">
            <Col className="col-lg-12 flexColumn d-flex align-items-start">
              <Col className="col-lg-3">
                <div>
                  <Accordion className="mt-3 rounded-1 customAccordion" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="normal text-start">The Skilled Visa​</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <a
                          onClick={() => setmultbanner("Subclass189")}
                        >
                          <p className="textColorInfo text-start normal">
                          The Skilled Independent Visa (Subclass 189)
                          </p>
                        </a>
                        <a
                          onClick={() => setmultbanner("Subclass190")}
                        >
                          <p className="textColorInfo text-start normal">
                          The Skilled Nominated Visa (Subclass 190)
                          </p>
                        </a>

                        <a
                          onClick={() => setmultbanner("Subclass491")}
                        >
                          <p className="textColorInfo text-start normal">
                          The Skilled Regional (Provisional) Visa (Subclass 491)
                          </p>
                        </a>

        
                      
                       
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className="mt-3 rounded-1 customAccordion" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content" id="panel2a-header"
                    >
                      <Typography className="normal text-start">The Permenant Residence Visa</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                      <a
                          onClick={() => setmultbanner("Subclass191")}
                        >
                        <p className="textColorInfo text-start normal">
                        The Permanent Residence (Skilled Regional) Visa (Subclass 191)
                        </p>
                        </a>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className="mt-3 rounded-1 customAccordion" expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content" id="panel3a-header"
                    >
                      <Typography className="normal text-start">The Business Visa</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                      <a
                          onClick={() => setmultbanner("Subclass888")}
                        >
                        <p className="textColorInfo text-start normal">
                        The Business Innovation and Investment (Permanent) Visa (subclass 888)
                        </p>
                        </a>
                        <a
                          onClick={() => setmultbanner("Subclass188")}
                        >
                        <p className="textColorInfo text-start normal">
                        The Business Innovation and Investment (Provisional) Visa (subclass 188)​
                        </p>
                        </a>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

            
                </div>
              </Col>
              <Col className="col-lg-9 ps-4 responsivePadding">

              {multbanner === "MigrationVisa" ? (
                     <div>
                     <div className="">
                       <div className="imgBG pt-3 d-flex ">
                         <img src={MigrationBG} className="w-100" />
                       </div>
   
                       <div className="col-lg-12">
                         <div className="innerSecondSection mt-5 d-flex  mb-3">
                           <h2 className="text-start">
                           The Migration Process
                           </h2>
                         </div>
                         <p className="textColorDefault normal  justifyText mt-4">
                         Australia employs various migration schemes to address the needs of its labour market and skilled worker requirements. The Department of Home Affairs sets an annual cap on the total number of migrants accepted each year, with this allocation distributed among the six states and two territories. Each state or territory follows its own migration policy, tailored to its specific needs.
                         </p>
                         <p className="textColorDefault normal  justifyText mt-4">
                         The Australian Capital Territory (ACT) has implemented its unique points-tested system, which takes into account various ACT-related activities for assessing potential skilled migrants. Meanwhile, other states adhere to the Home Affairs points-tested system for inviting and nominating applicants. This diverse approach across states and territories ensures that the specific needs and requirements of each region are met, contributing to a balanced and efficient migration process.
                         </p>
                       </div>


                       <div className="col-lg-12">
                         <div className="innerSecondSection mt-5 d-flex  mb-3">
                           <h2 className="text-start">
                           Canberra Matrix 
                           </h2>
                         </div>
                         <p className="textColorDefault normal  justifyText mt-4">
                         The Australian Capital Territory (ACT) has established its own migration system, called the Canberra Matrix, which employs a points-tested approach for selecting potential skilled migrants. This system is utilized by the ACT government to nominate candidates for the Australian Skilled Nominated (subclass 190) visa and Skilled Work Regional (Provisional) Visa (Subclass 491). The main goal of the Canberra Matrix is to assist the ACT government in identifying applicants with a strong likelihood of contributing to the region's workforce and economic growth. It's important to emphasize that the Canberra Matrix is not a visa application itself, but rather a pathway for candidates to receive an invitation to apply for a visa. The Canberra Matrix features two distinct points-tested systems for overseas residents and Canberra residents. Invitations are extended to applicants based on the highest-ranked candidates within their claimed points and specific occupation. This creates a competitive environment among candidates with the same nominated occupations.
                         </p>
                         <p className="textColorDefault normal  justifyText mt-4">
                         To check your eligibility and calculate your points on the Canberra matrix, please <a target="_blank" href="https://www.act.gov.au/migration/skilled-migrants/canberra-matrix/check-your-canberra-matrix-score-canberra-residents" className="text-decoration-underline text-blue">Click Here</a> 
                         </p>
                       </div>
   
                   
   
                       
   
                       <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                         <div className="col-lg-4">
                         <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                           <Button
                             className="mt-3 lightAlice border-0 textColorInfo w-100"
                             onClick={ContactUsPage}
                           >
                             <img src={chatApp} className="img-fluid pe-2" />{" "}
                             ENQUIRE NOW
                           </Button>
                         </div>
                       </div>
                     </div>
                   </div>
                ) : multbanner === "Subclass189" ? (
                  <div>
                  <div className="">
                    <div className="imgBG pt-3 d-flex ">
                      <img src={MigrationBG} className="w-100" />
                    </div>

                    <div className="col-lg-12">
                      <div className="innerSecondSection mt-5 d-flex  mb-3">
                        <h2 className="text-start">
                        The Skilled Independent Visa (Subclass 189)
                        </h2>
                      </div>
                      <p className="textColorDefault normal  justifyText mt-4">
                        The Skilled Independent Visa (Subclass 189) is a
                        points-tested permanent residency visa designed for
                        skilled workers who want to live and work in Australia
                        independently, without the need for sponsorship from
                        an employer, a state or territory, or a family member.
                        This visa allows successful applicants to settle in
                        Australia and enjoy the benefits of permanent
                        residency, such as access to healthcare, education,
                        and social security.
                      </p>
                    </div>

                    <div className="col-lg-12">
                      <div className="innerSecondSection mt-5 d-flex  mb-3 mb-3">
                        <h4 className="text-start">
                          The Skilled Independent Visa (Subclass 189) allows
                          successful applicants to:
                        </h4>
                      </div>

                      <ol className="">
                        <li>
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Live, work, and study in Australia as a permanent
                            resident.
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Enroll in Medicare, Australia's public healthcare
                            system.
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Sponsor eligible family members for permanent
                            residency.
                          </div>
                        </li>

                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Apply for Australian citizenship after meeting the
                            residency requirements.
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Travel to and from Australia for five years from
                            the date the visa is granted (after which they
                            will need to obtain a Resident Return Visa or
                            another suitable visa to re-enter Australia)
                          </div>
                        </li>
                      </ol>
                    </div>

                    <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex  mb-3 mb-3">
                          <h4 className="text-start">Requirements</h4>
                        </div>

                        <ol className="">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Points test: Applicants must score at least 65 points on the Points Test, which assesses factors such as age, English language proficiency, work experience, education, and other relevant qualifications. The Points Test is competitive, and higher scores increase the chances of receiving an invitation to apply for the visa. 
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Occupation: Applicants must nominate an occupation on the Medium and Long-term Strategic Skills List (MLTSSL), which is a list of in-demand occupations in Australia. They must also have a suitable skills assessment from a relevant assessing authority for their nominated occupation.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Age: Applicants must be under 45 years old at the time of receiving an invitation to apply for the visa.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                            English language proficiency: Applicants must demonstrate a competent level of English language proficiency through recognized tests such as IELTS, TOEFL, PTE, or CAE. 
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Health and character requirements: Applicants must meet specific health and character requirements, including undergoing a medical examination and obtaining police clearance certificates from countries they have lived in for more than 12 months.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Expression of Interest (EOI): Applicants must submit an Expression of Interest (EOI) through the SkillSelect online system, providing information about their skills, work experience, and other relevant qualifications. If their EOI is selected, they will receive an invitation to apply for the visa.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Invitation to apply: After receiving an invitation to apply, applicants have 60 days to submit their visa application and provide all the necessary supporting documents
                            </div>
                          </li>
                        </ol>
                      </div>

                    <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                      <div className="col-lg-4">
                      <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                        <Button
                          className="mt-3 lightAlice border-0 textColorInfo w-100"
                          onClick={ContactUsPage}
                        >
                          <img src={chatApp} className="img-fluid pe-2" />{" "}
                          ENQUIRE NOW
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                ) : multbanner === "Subclass190" ? (
                  <div>
                  <div className="">
                    <div className="imgBG pt-3 d-flex ">
                      <img src={MigrationBG} className="w-100" />
                    </div>

                    <div className="col-lg-12">
                      <div className="innerSecondSection mt-5 d-flex  mb-3">
                        <h2 className="text-start">
                        The Skilled Nominated Visa (Subclass 190)
                        </h2>
                      </div>
                      <p className="textColorDefault normal  justifyText mt-4">
                        The Skilled Nominated Visa (Subclass 190) is a
                        points-tested permanent residency visa for skilled
                        workers who have been nominated by an Australian state
                        or territory government. This visa allows successful
                        applicants to live, work, and study in Australia as
                        permanent residents, contributing their skills to the
                        local workforce and economy.
                      </p>
                    </div>

                    <div className="col-lg-12">
                      <div className="innerSecondSection mt-5 d-flex  mb-3 mb-3">
                        <h4 className="text-start">
                          The Skilled Nominated Visa (Subclass 190) allows
                          successful applicants to:
                        </h4>
                      </div>

                      <ol className="">
                        <li>
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Live, work, and study in Australia as a permanent
                            resident.
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Enrolll in Medicare, Australia's public healthcare
                            system.
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Sponsor eligible family members for permanent
                            residency.
                          </div>
                        </li>

                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Apply for Australian citizenship after meeting the
                            residency requirements.
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Travel to and from Australia for five years from
                            the date the visa is granted (after which they
                            will need to obtain a Resident Return Visa or
                            another suitable visa to re-enter Australia).
                          </div>
                        </li>

              
                      </ol>

                      <h4 className="bolder text-black  text-start mt-5">
                        Requirements
                      </h4>

                      <ol className=" mt-3">
                        <li>
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Points test: Applicants must score at least 65
                            points on the Points Test, which assesses factors
                            such as age, English language proficiency, work
                            experience, education, and other relevant
                            qualifications. The Points Test is competitive,
                            and higher scores increase the chances of
                            receiving an invitation to apply for a visa.
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            State or territory nomination: Applicants must be
                            nominated by an Australian state or territory
                            government. Each state or territory has its own
                            eligibility criteria, skills shortage lists, and
                            requirements for nomination.
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Occupation: Applicants must nominate an occupation
                            on the relevant skilled occupation list for the
                            state or territory that nominates them. They must
                            also have a suitable skills assessment from a
                            relevant assessing authority for their nominated
                            occupation.
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Age: Applicants must be under 45 years old at the
                            time of receiving an invitation to apply for the
                            visa.
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            English language proficiency: Applicants must
                            demonstrate a competent level of English language
                            proficiency through recognized tests such as
                            IELTS, TOEFL, PTE, or CAE.
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Health and character requirements: Applicants must
                            meet specific health and character requirements,
                            including undergoing a medical examination and
                            obtaining police clearance certificates from
                            countries they have lived in for more than 12
                            months.
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Expression of Interest (EOI): Applicants must
                            submit an Expression of Interest (EOI) through the
                            SkillSelect online system, providing information
                            about their skills, work experience, and other
                            relevant qualifications. If their EOI is selected
                            and they receive a nomination from a state or
                            territory, they will be invited to apply for the
                            visa.
                          </div>
                        </li>

                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Invitation to apply: After receiving an invitation
                            to apply, applicants have 60 days to submit their
                            visa application and provide all the necessary
                            supporting documents.
                          </div>
                        </li>
                      </ol>
                    </div>

                    <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                      <div className="col-lg-4">
                      <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                        <Button
                          className="mt-3 lightAlice border-0 textColorInfo w-100"
                          onClick={ContactUsPage}
                        >
                          <img src={chatApp} className="img-fluid pe-2" />{" "}
                          ENQUIRE NOW
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                ) : multbanner === "Subclass491" ? (
                  <div>
                  <div className="">
                    <div className="imgBG pt-3 d-flex ">
                      <img src={MigrationBG} className="w-100" />
                    </div>

                    <div className="col-lg-12">
                      <div className="innerSecondSection mt-5 d-flex  mb-3">
                        <h2 className="text-start">
                        The Skilled Regional (Provisional) Visa (Subclass 491)
                        </h2>
                      </div>
                      <p className="textColorDefault normal  justifyText mt-4">
                        The Skilled Regional (Provisional) Visa (Subclass 491)
                        is a points-tested provisional visa designed for
                        skilled workers who want to live and work in regional
                        Australia. Applicants must be nominated by an
                        Australian state or territory government or sponsored
                        by an eligible family member residing in a designated
                        regional area. This visa aims to promote economic
                        development in regional areas and address skills
                        shortages by attracting skilled migrants.
                      </p>

                      <p className="textColorDefault normal  justifyText mt-4">
                        Visa holders are required to live, work, and study
                        only in designated regional areas and notify the
                        Department of Home Affairs of any changes in their
                        address or other circumstances.
                      </p>
                    </div>

                    <div className="col-lg-12">
                      <div className="innerSecondSection mt-5 d-flex  mb-3 mb-3">
                        <h4 className="text-start">
                          The Skilled Regional (Provisional) Visa (Subclass
                          491) allows successful applicants to:
                        </h4>
                      </div>

                      <ol className="">
                        <li>
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Live, work, and study in a designated regional
                            area of Australia for up to five years.
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Travel to and from Australia while the visa is
                            valid.
                          </div>
                        </li>

                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Apply for the Skilled Regional (Permanent) Visa
                            (Subclass 191) after meeting specific
                            requirements, including living and working in a
                            regional area for at least three years and meeting
                            the minimum income requirement.
                          </div>
                        </li>
                      </ol>

                      <h4 className="bolder text-black  text-start mt-5">
                        Requirements
                      </h4>

                      <ol className=" mt-3">
                        <li>
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Points test: Applicants must score at least 65
                            points on the Points Test, which assesses factors
                            such as age, English language proficiency, work
                            experience, education, and other relevant
                            qualifications. The Points Test is competitive,
                            and higher scores increase the chances of
                            receiving an invitation to apply for a visa.
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            State or territory nomination: Applicants must be
                            nominated by an Australian state or territory
                            government. Each state or territory has its own
                            eligibility criteria, skills shortage lists, and
                            requirements for nomination.
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Occupation: Applicants must nominate an occupation
                            on the relevant skilled occupation list for the
                            state or territory that nominates them. They must
                            also have a suitable skills assessment from a
                            relevant assessing authority for their nominated
                            occupation.
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Age: Applicants must be under 45 years old at the
                            time of receiving an invitation to apply for the
                            visa.
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            English language proficiency: Applicants must
                            demonstrate a competent level of English language
                            proficiency through recognized tests such as
                            IELTS, TOEFL, PTE, or CAE.
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Health and character requirements: Applicants must
                            meet specific health and character requirements,
                            including undergoing a medical examination and
                            obtaining police clearance certificates from
                            countries they have lived in for more than 12
                            months.
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Expression of Interest (EOI): Applicants must
                            submit an Expression of Interest (EOI) through the
                            SkillSelect online system, providing information
                            about their skills, work experience, and other
                            relevant qualifications. If their EOI is selected
                            and they receive a nomination from a state or
                            territory, they will be invited to apply for the
                            visa.
                          </div>
                        </li>

                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                            Invitation to apply: After receiving an invitation
                            to apply, applicants have 60 days to submit their
                            visa application and provide all the necessary
                            supporting documents.
                          </div>
                        </li>
                      </ol>
                    </div>
                    <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                      <div className="col-lg-4">
                      <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                        <Button
                          className="mt-3 lightAlice border-0 textColorInfo w-100"
                          onClick={ContactUsPage}
                        >
                          <img src={chatApp} className="img-fluid pe-2" />{" "}
                          ENQUIRE NOW
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                ) : multbanner === "Subclass191" ? (
                  <div>
                  <div className="">
                    <div className="imgBG pt-3 d-flex ">
                      <img src={MigrationBG} className="w-100" />
                    </div>

                    <div className="col-lg-12">
                      <div className="innerSecondSection mt-5 d-flex ">
                        <h2 className="text-start">
                        The Permanent Residence (Skilled Regional) Visa (Subclass 191)
                        </h2>
                      </div>
                      <p className="normal textColorDefault  text-start mt-4">
                      The Permanent Residence (Skilled Regional) Visa (Subclass 191) is a permanent visa designed for skilled workers who have lived, worked, and held a valid regional provisional visa (either a Subclass 491 or Subclass 494 visa) in a designated regional area of Australia. The purpose of this visa is to encourage skilled migrants to settle and contribute to the economic development of regional Australia.
                      </p>
                  
                      <p className="normal textColorDefault  text-start mt-4">Once the Permanent Residence (Skilled Regional) Visa (Subclass 191) is granted, the visa holder can:</p>
                      
                      <ol className="">
                        <li>
                          <div className=" d-flex me-auto ps-2 justifyText">
                          Live, work, and study in Australia indefinitely. 
                                                    </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                          Access Medicare, Australia's public healthcare system. 
                          </div>
                        </li>

                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                          Apply for Australian citizenship after meeting the residency requirements. 
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className=" d-flex me-auto ps-2 justifyText">
                          Sponsor eligible relatives for permanent residency in Australia.
                          </div>
                        </li>
                      </ol>
            
                    </div>

                    <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex ">
                          <h4 className="text-start">Requirements</h4>
                        </div>

                        <ol className=" mt-3">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Eligibility: Applicants must hold a valid Subclass 491 or Subclass 494 visa at the time of application. 
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Regional residence and work: Applicants must have lived in a designated regional area of Australia for at least three years while holding a Subclass 491 or Subclass 494 visa. They must also have worked in a designated regional area for at least three years, earning a minimum income specified by the Department of Home Affairs. 
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Income Requirement: Applicants must demonstrate that they have met the minimum taxable income requirement for three years while holding a Subclass 491 or Subclass 494 visa. 
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Visa compliance: Applicants must have complied with the conditions of their Subclass 491 or Subclass 494 visa. 
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Health and character requirements: Applicants must meet specific health and character requirements, including undergoing a medical examination and obtaining police clearance certificates from countries they have lived in for more than 12 months.
                            </div>
                          </li>
                        </ol>
                        {/* <h4 className="bolder text-black  text-start mt-5">
                Requirements
                </h4>

                <ol className=" mt-3">
                  <li>
                    <div className=" d-flex me-auto ps-2 justifyText">
                    Relationship:- Applicants must be married or in a de facto relationship with an Australian citizen, permanent resident, or eligible New Zealand citizen. De facto relationships must have existed for at least 12 months before applying unless there are compelling reasons for waiving this requirement. 
                    </div>
                  </li>
                  <li className="mt-3">
                    <div className=" d-flex me-auto ps-2 justifyText">
                    Sponsorship:- The Australian partner must act as the visa sponsor and be willing to support the applicant and any dependent family members included in the application. 
                    </div>
                  </li>
                  <li className="mt-3">
                    <div className=" d-flex me-auto ps-2 justifyText">
                    Relationship evidence:- Applicants must provide evidence of a genuine and ongoing relationship, including financial, social, and emotional aspects. This can include joint bank accounts, photographs, shared living arrangements, and supporting statements from friends and family. 
                    </div>
                  </li>
                  <li className="mt-3">
                    <div className=" d-flex me-auto ps-2 justifyText">
                    Health and character requirements:- Applicants must meet specific health and character requirements, including undergoing a medical examination and obtaining police clearance certificates from countries they have lived in for more than 12 months.
                    </div>
                  </li>
                </ol>  */}
                      </div>

                    <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                      <div className="col-lg-4">
                      <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                        <Button className="mt-3 lightAlice border-0 textColorInfo w-100"  onClick={ContactUsPage} >
                          <img src={chatApp} className="img-fluid pe-2" />{" "}
                          ENQUIRE NOW
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                ) : multbanner === "Subclass888" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={MigrationBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex ">
                          <h2 className="text-start">
                          The Business Innovation and Investment (Permanent) Visa (subclass 888)
                          </h2>
                        </div>
                        <p className="normal textColorDefault  text-start mt-4">
                        The Business Innovation and Investment (Permanent) Visa (Subclass 888) is a permanent residency visa for individuals who have successfully met the requirements of their provisional Business Innovation and Investment (Provisional) Visa (Subclass 188). This visa allows successful applicants to continue owning and managing a business or investing in Australia as permanent residents.
                        </p>
                      </div>

                      <div className="col-lg-12">
                    
                      <div className="innerSecondSection mt-5 d-flex ">
                          <h4 className="text-start">
                          The Business Innovation and Investment (Permanent) Visa (Subclass 888) allows successful applicants to: 
                          </h4>
                        </div>

                        <ol className=" mt-3">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Live, work, and study in Australia as permanent residents.
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Enroll in Medicare, Australia's public healthcare system. 
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Sponsor eligible family members for permanent residency. 
                            </div>
                          </li>

                   
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Apply for Australian citizenship after meeting the residency requirements. 

                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Travel to and from Australia for five years from the date the visa is granted (after which they will need to obtain a Resident Return Visa or another suitable visa to re-enter Australia).

                            </div>
                          </li>

                      
                        </ol>
                        </div>
                        <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                        <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button className="mt-3 lightAlice border-0 textColorInfo w-100"  onClick={ContactUsPage} >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : multbanner === "Subclass188" ? (
                  <div>
                    <div className="">
                      <div className="imgBG pt-3 d-flex ">
                        <img src={MigrationBG} className="w-100" />
                      </div>

                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex ">
                          <h2 className="text-start">
                          The Business Innovation and Investment (Provisional) Visa (Subclass 188)
                          </h2>
                        </div>
                        <p className="normal textColorDefault  text-start mt-4">
                        The Business Innovation and Investment (Provisional) Visa (Subclass 188) is a temporary visa for entrepreneurs, investors, and business owners who want to establish, develop, or invest in a business in Australia. This visa allows successful applicants to own and manage a business, or make investments in Australia for a specified period before becoming eligible to apply for the permanent Business Innovation and Investment (Permanent) Visa (Subclass 888).
                        </p>
                        <p className="normal textColorDefault  text-start mt-4">
                        The duration of the Subclass 188 visa varies depending on the stream, generally ranging from 4 to 5 years, with a possible extension for certain streams.
                        </p>
                      </div>


                      <div className="col-lg-12">
                        <div className="innerSecondSection mt-5 d-flex ">
                          <h4 className="text-start">
                          The Business Innovation and Investment (Provisional) Visa (Subclass 188) allows successful applicants to: 
                          </h4>
                        </div>
                    
                        <ol className=" mt-2">
                          <li>
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Establish, develop, or invest in a business in Australia. 

                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Travel in and out of Australia while the visa is valid. 
                            </div>
                          </li>
                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Include their spouse or partner, and dependent children in the visa application.
                            </div>
                          </li>

                          <li className="mt-3">
                            <div className=" d-flex me-auto ps-2 justifyText">
                            Apply for the Business Innovation and Investment (Permanent) Visa (Subclass 888) after meeting specific requirements.

                            </div>
                          </li>

                      
                        </ol>
              
                      </div>

                      {/* <div className="col-lg-12">
                <div className="innerSecondSection mt-5 d-flex ">
                  <h4 className="text-start">Upon the grant of the Partner (Migrant) Visa (Subclass 100), the applicant can:</h4>
                </div>
                <p className="normal textColorDefault  text-start mt-4">
                Enter and live in Australia until a decision is made on their permanent Partner (Migrant) Visa (Subclass 100). 
                </p>
                <ol className="">
                  <li>
                    <div className="textColorGray d-flex me-auto ps-2 justifyText">
                    Work and study in Australia. 
                    </div>
                  </li>
                  <li className="mt-3">
                    <div className="textColorGray d-flex me-auto ps-2 justifyText">
                    Enroll in Medicare, Australia's public healthcare system.
                    </div>
                  </li>
                </ol> 
                <h4 className="bolder text-black  text-start mt-5">
                Requirements
                </h4>

                <ol className=" mt-3">
                  <li>
                    <div className=" d-flex me-auto ps-2 justifyText">
                    Relationship:- Applicants must be married or in a de facto relationship with an Australian citizen, permanent resident, or eligible New Zealand citizen. De facto relationships must have existed for at least 12 months before applying unless there are compelling reasons for waiving this requirement. 
                    </div>
                  </li>
                  <li className="mt-3">
                    <div className=" d-flex me-auto ps-2 justifyText">
                    Sponsorship:- The Australian partner must act as the visa sponsor and be willing to support the applicant and any dependent family members included in the application. 
                    </div>
                  </li>
                  <li className="mt-3">
                    <div className=" d-flex me-auto ps-2 justifyText">
                    Relationship evidence:- Applicants must provide evidence of a genuine and ongoing relationship, including financial, social, and emotional aspects. This can include joint bank accounts, photographs, shared living arrangements, and supporting statements from friends and family. 
                    </div>
                  </li>
                  <li className="mt-3">
                    <div className=" d-flex me-auto ps-2 justifyText">
                    Health and character requirements:- Applicants must meet specific health and character requirements, including undergoing a medical examination and obtaining police clearance certificates from countries they have lived in for more than 12 months.
                    </div>
                  </li>
                </ol> 
              </div>

              <div className="col-lg-12">
                <div className="innerSecondSection mt-5 d-flex ">
                  <h2 className="text-start">FAQ about family visa</h2>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <div className="col-lg-6">
                    <img
                      src={MigrationBG1}
                      className="img-fluid pt-3 d-flex "
                    />
                  </div>
                  <div className="col-lg-6">
                    <p className="textColorGray  text-start mt-2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since the 1500s,
                      when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book. It has
                      survived not only five centuries, but also the leap
                      into electronic typesetting, remaining essentially
                      unchanged
                    </p>
                    <p className="textColorGray  text-start mt-2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since the 1500s,
                      when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="innerHowMuchSection pt-3 d-flex  mt-4">
                  <Row>
                    <div className="d-flex align-items-center">
                      <img
                        src={bullet}
                        width="20"
                        height="20"
                        className="mb-2"
                      />
                      <h4 className="ms-3">
                        How Much This Journey Will Cost You?
                      </h4>
                    </div>
                    <p className="textColorGray text-start mt-2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                      industry. It has survived not only five centuries,
                      but also the leap into electronic typesetting,
                      remaining essentially unchanged.
                    </p>

                    <div className="d-flex align-items-center mt-4">
                      <img
                        src={bullet}
                        width="20"
                        height="20"
                        className="mb-2"
                      />
                      <h4 className="ms-3">
                        How to take time in the procesing of registration?
                      </h4>
                    </div>
                    <p className="textColorGray text-start mt-2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                      industry. It has survived not only five centuries,
                      but also the leap into electronic typesetting,
                      remaining essentially unchanged.
                    </p>
                  </Row>
                </div>
              </div> */}

                      <div className="col-lg-12 d-flex m-auto justify-content-center mt-5">
                        <div className="col-lg-4">
                        <Button
                            className="btnInfo w-100 d-flex justify-content-around"
                            onClick={AppointementPage}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <img
                                src={bookApp}
                                className="img-fluid pe-2"
                                alt="Book Appointment"
                              />
                              <span className="text-nowrap">
                                BOOK AN APPOINTMENT
                              </span>
                            </div>
                          </Button>
                          <Button className="mt-3 lightAlice border-0 textColorInfo w-100"  onClick={ContactUsPage} >
                            <img src={chatApp} className="img-fluid pe-2" />{" "}
                            ENQUIRE NOW
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) :              
                (
                  ""
                )}
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
