import React from "react";
import "./GlobalVisaProcessingTimePage.css";
import { Container, Row, Col, Button } from "react-bootstrap";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SKILLBG from "../../Assets/Images/careerIner.png";
import MigrationBG1 from "../../Assets/Images/migrationChild.png";
import bullet from "../../Assets/Images/beforeBullet.png";
import bookApp from "../../Assets/Images/calanderApp.png";
import chatApp from "../../Assets/Images/chatEnquire.png";

import { useHistory } from "react-router-dom";


export default function GlobalVisaProcessingTimePage() {

  let history = useHistory();

  const AppointementPage=()=> {
    history.push("/bookanappointment");
    window.scrollTo(0, 0)
  }


  const ContactUsPage=()=> {
    history.push("/ContactUs");
    window.scrollTo(0, 0)
  }
  return (
    <>
        <section className="bg-white">
        <Container>
          <Row className="py-5 responsivePadding">
            <Col className="col-lg-12">
          
            
                <div className="imgBG pt-3 d-flex  mt-4">
                  <img src={SKILLBG} className="w-100" />
                </div>

                <div className="col-lg-12">
                  <div className="innerSecondSection mt-5 d-flex ">
                    <h2 className="text-start">Introduction</h2>
                  </div>
                  <p className="textColorDefault normal  justifyText mt-4">
                  Global visa processing times can vary significantly depending on the type of visa, the country of application, and the specific circumstances of each applicant. Visa processing times are subject to change and can be influenced by factors such as application volume, the complexity of the case, the completeness of the submitted documentation, and the need for additional checks or information.
                  </p>
                  <p className="textColorDefault normal  justifyText mt-2">
                  The Australian Department of Home Affairs provides general guidelines on its website for the processing times of various visa subclasses. These guidelines are updated regularly and show the estimated time it takes to process a certain percentage of applications.
                <br ></br> <div className="mt-2"> Please check the global visa processing time <a  href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-processing-times/global-visa-processing-times" className="text-blue text-decoration-underline" target="_blank">Click Here</a></div>
                  </p>
                </div>

         



                {/* <div className="col-lg-12 d-flex m-auto justify-content-center mt-4">
                  <div className="col-lg-4">
                    <Button className="btnInfo w-100 ps-2 pe-0" onClick={AppointementPage} > <img src={bookApp} className="img-fluid pe-2"/> BOOK AN APPOINTEMENT</Button>
                    <Button className="mt-3 lightAlice border-0 textColorInfo w-100" onClick={ContactUsPage}><img src={chatApp} className="img-fluid pe-2"/>  ENQUIRE NOW</Button>
                  </div>
                </div> */}
       
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
