import React from 'react';
import Navbar from '../Components/Layout/NavBar/NavBar'
import Footer from '../Components/Layout/Footer/Footer'
import HomeBanner from '../Components/HomePage/HomeBanner/HomeBanner.js'
import Vision from '../../src/Components/HomePage/OurVision/Vision'
import Services from '../Components/HomePage/Services/Services'
import MissionObjective from './../Components/HomePage/MissionObjective/MissionObjective';
// import Milestone from '../Components/HomePage/Milestone/Milestone';
import News from '../Components/HomePage/News/News';
import FAQ from '../Components/HomePage/FAQ/FAQ';
import CompanySponser from '../Components/HomePage/CompanySponser/CompanySponser';
import CustomerReview from '../Components/HomePage/Review/CustomerReview'
import WhyChooseUs from '../Components/HomePage/WhyChooseUs/WhyChooseUs';

export default function Home({ children }) {
    return (
        <>
            <Navbar Page={"Home"}></Navbar>
            <HomeBanner></HomeBanner>
            <Vision></Vision>
            <MissionObjective></MissionObjective>
            <Services></Services>
            <WhyChooseUs></WhyChooseUs>
            {/* <Milestone></Milestone> */}
            <News></News>
            <CustomerReview></CustomerReview>
            <FAQ></FAQ>
            {/* <CompanySponser></CompanySponser> */}
            <Footer></Footer>
        </>
    )
}

