import React from "react";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import "./MissionObjective.css";
import Mission from "../../../Assets/Images/mission1.png";
import Carrer from "../../../Assets/Images/career1.png";

import { Image } from "react-bootstrap";
import { Container } from "react-bootstrap";

export default function MissionObjective() {
  return (
    <>
      <section>
        <Row className="m-0 flexColumn">
          <Col className="col-lg-6 col-md-12 p-0">
            <div className="handshake">
              <Container className="py-5">
                <Row className="d-flex justify-content-center py-5 ">
                  <Col className="col-lg-8">
                    <div className="icon-risk">
                      <Image className="" src={Mission}></Image>
                    </div>
                    <div className="">
                      <h2 className="text-white mt-4">Mission</h2>
                      <center><hr className=" hrline text-white"></hr></center>
                      <p className="text-white normal justifyText">Our mission is to provide unparalleled, personalized solutions in education, visa, and migration services, enabling our clients to pursue their dreams, achieve their goals, and experience life beyond borders. We are committed to delivering exceptional customer service, maintaining the highest ethical standards, and fostering long-lasting partnerships with leading institutions and organizations worldwide.</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
          <Col className="col-lg-6 col-md-12 p-0">
            <div className="risk">
              <Container className="py-5">
                <Row className="d-flex justify-content-center py-5">
                  <Col className="col-lg-8">
                    <div className="icon-risk">
                      <Image className="" src={Carrer}></Image>
                    </div>
                    <div className="">
                      <h2 className="text-white mt-4">Objective</h2>
                      <center><hr className="w-auto hrline text-center text-white"></hr></center>
                      <p className="text-white normal justifyText">To offer comprehensive, tailored services that cater to the unique needs and goals of each client, ensuring a seamless and stress-free journey towards their desired destination.To expand our network of partnerships with prestigious educational institutions, government agencies, and industry organizations, providing our clients with a vast array of opportunities to choose from. </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>

      </section>
    </>
  );
}
