import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
// import './vision.css'
import HumanVision from '../../../Assets/Images/humancard2.jpeg'

export default function Vision() {
  return (
    <div className='bg-white py-3 vision'>
      <Container>
        <Row className='d-flex align-item-center flexColumn mt-5'>
            <Col className='col-lg-6 col-md-12  py-4 pt-0 text-start'>
                <div className="normaldivpadding capitalize textColorInfo  ">
                    <h5 className='textColorInfo h1After responsiveFontLargeMedium'>Our Vision</h5>
                </div>
                {/* <h2>
                    <p className='text-start normal'>Dream big.</p>
                    <span className='text-start bolder'>Be daring. Not generic.</span>
                </h2> */}
                <br/>
                <p className='justifyText medium text-gray pt-0 mt-0'>
                At Focus Visa and Education Services, our vision is to become the world's most trusted and sought-after provider of education, visa, and migration services, transforming lives by bridging cultural divides and creating a global community of empowered individuals.
                </p>
            </Col>
            <Col className='col-lg-6 col-md-12 d-flex justify-content-end py-5 pt-0 visionimg'>
              <Image src={HumanVision} className='py-1 img-fluid'></Image>
            </Col>
        </Row>
      </Container>
    </div>
  )
}
