import React from "react";
import "./LifeInAustraliaPage.css";
import { Container, Row, Col, Button } from "react-bootstrap";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import AusBg from "../../Assets/Images/australiaBG.png";
import climateBG from "../../Assets/Images/climate.png";
import climateBG1 from "../../Assets/Images/climateBG2.png";
// import climateBG2 from "../../Assets/Images/bg3.svg";

import MigrationBG1 from "../../Assets/Images/migrationChild.png";
import bullet from "../../Assets/Images/beforeBullet.png";
import bookApp from "../../Assets/Images/calanderApp.png";
import chatApp from "../../Assets/Images/chatEnquire.png";

import { useHistory } from "react-router-dom";


export default function LifeInAustraliaPage() {
  let history = useHistory();

  const AppointementPage=()=> {
    history.push("/bookanappointment");
    window.scrollTo(0, 0)
  }

  const ContactUsPage=()=> {
    history.push("/ContactUs");
    window.scrollTo(0, 0)
  }
  return (
    <>
      <section className="bg-white mainCursor">
        <Container>
          <Row className="py-5 pt-0 responsivePadding">
            <Col className="col-lg-12 responsivePadding">
              <div className="first">
                <div className="imgBG d-flex ">
                  {/* <img src={AusBg} className="w-100" /> */}
                </div>

                <div className="col-lg-12">
                  <h4 className="mb-3 bolder">Introduction</h4>
                    <p className="  justifyText bolder">
                    Australia, known as the "Land Down Under," is a diverse and fascinating country that attracts people from all over the world. With its unique blend of cultures, beautiful landscapes, and high quality of life, it's no wonder that Australia is a popular destination for international students, workers, and those seeking to become permanent residents. In this blog, we'll explore life in Australia for different groups of people, touching on lifestyle, jobs, health, education, and more. So, whether you're an Australian citizen, a visitor, or considering a move to this great country, read on to learn more about what life in Australia has to offer.

                  </p>
          
                

                  <div className="col-lg-12">
                  <div className="innerSecondSection mt-4 d-flex ">
                    <h2 className="text-start">Lifestyle in Australia</h2>
                  </div>

                  <p className="  justifyText mt-4 bolder">
                  Australia is known for its laid-back atmosphere and work-life balance. Australians enjoy a high standard of living, with a focus on outdoor activities, sports, and socializing. This is evident in the numerous parks, beaches, and sporting facilities found throughout the country.
                  </p>
                    
                  <ol className="mt-2">
                        <li>
                          <div className=" d-flex me-auto ps-2 justifyText normal">
                          International Students: For international students, the Australian lifestyle can be both exciting and challenging. Many find that they need to adapt to new cultural norms and social customs while navigating their studies. Fortunately, Australia is a multicultural country that embraces diversity, making it easier for students to fit in and make friends.

                          </div>
                        </li>
                        <li className="mt-2">
                          <div className=" d-flex me-auto ps-2 justifyText normal">
                          International Workers: Similarly, international workers may need to adjust to the Australian work culture, which often values a healthy balance between work and personal life. Australians tend to work hard but also place importance on leisure time, allowing for a more relaxed approach to work when compared to some other countries.
                          </div>
                        </li>
                        <li className="mt-2">
                          <div className=" d-flex me-auto ps-2 justifyText normal">
                          Australian Permanent Residents: As permanent residents, individuals enjoy many of the same benefits as Australian citizens, including access to healthcare, education, and social services. Permanent residents can fully immerse themselves in the Australian lifestyle, enjoying the best of both their home country and their adopted home.
                          </div>
                        </li>

                        <li className="mt-2">
                          <div className=" d-flex me-auto ps-2 justifyText normal">
                          Australian Citizens:Australian citizens have the opportunity to enjoy the full range of experiences that the country has to offer. From engaging in outdoor activities to participating in community events and enjoying the arts, there is no shortage of things to do and places to explore in Australia.

                          </div>
                        </li>
                     

                      </ol>
                  </div>

                </div>

                <div className="col-lg-12">
                   
                  <div className="innerSecondSection mt-4 d-flex ">
                    <h2 className="text-start">Jobs in Australia</h2>
                  </div>
                  <p className="  justifyText mt-4 bolder">
                  The Australian job market is robust, offering opportunities across a wide range of industries. While some sectors are more competitive than others, there is generally a demand for skilled workers in fields such as healthcare, engineering, and technology.

                  </p>


                  <ol className=" mt-2">
                        <li>
                          <div className=" d-flex me-auto ps-2 justifyText normal">
                          International Students: International students are allowed to work up to 48 hours per fortnight during the academic semester and unlimited hours during holidays. This provides an opportunity to gain work experience and supplement their income. Many students find part-time jobs in retail, hospitality, or tutoring.


                          </div>
                        </li>
                        <li className="mt-2">
                          <div className=" d-flex me-auto ps-2 justifyText normal">
                          International Workers: Skilled workers from overseas are often in high demand, particularly in industries experiencing a skills shortage. The Australian government has a list of eligible skilled occupations that can help guide potential workers in their job search. Some popular industries for international workers include healthcare, engineering, and technology.
                          </div>
                        </li>
                        <li className="mt-2">
                          <div className=" d-flex me-auto ps-2 justifyText normal">
                          Australian Permanent Residents: Permanent residents have access to the same job opportunities as Australian citizens. This means they can pursue careers in any industry and enjoy the same workplace rights and protections.

                          </div>
                        </li>
                        <li className="mt-2">
                          <div className=" d-flex me-auto ps-2 justifyText normal">
                          Australian Citizens: Australian citizens enjoy a strong job market with a wide range of opportunities including government sector jobs. The country has a low unemployment rate and a strong economy, making it an attractive place to work and grow a career.

                          </div>
                        </li>

                      </ol>
                </div>

                <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Health in Australia</h2>
                   </div>
                   <p className="  justifyText mt-4 bolder">
                   Australia has a world-class healthcare system, with both public and private options available. The public system, known as Medicare, provides free or subsidized healthcare for Australian citizens and permanent residents. Other types of residents have access to the healthcare system through private insurance which covers similar kinds of facilities and expenses. However, the cover and out-of-pocket expenses will depend upon your chosen private health insurance provider. 

                   </p>
 
 
                   <ol className=" mt-2">
                         <li>
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Public Hospitals: Public hospitals in Australia are funded by the government and provide a range of services, including emergency care, inpatient and outpatient treatment, and elective surgery. Treatment in public hospitals is generally free for Australian citizens and permanent residents, as it is covered by Medicare. However, waiting times for elective surgeries can be longer in the public system compared to private hospitals.

                           </div>
                         </li>
                         <li className="mt-2">
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Private Healthcare: In addition to the public healthcare system, many Australians choose to take out private health insurance. This coverage can provide access to private hospitals, shorter waiting times for elective surgeries, and additional services that may not be covered by Medicare, such as dental care, physiotherapy, and optometry. Private health insurance premiums vary depending on the level of coverage and the insurance provider.

                           </div>
                         </li>
                         <li className="mt-2">
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           International Students: International students are required to have Overseas Student Health Cover (OSHC) while studying in Australia. This ensures that they have access to medical care while in the country. OSHC covers a range of medical services, such as doctor visits, hospital stays, and prescription medications.

 
                           </div>
                         </li>

                         <li className="mt-2">
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           International Workers: International workers may be eligible for Medicare if they hold certain types of visas and meet specific requirements. Otherwise, they should obtain private health insurance to ensure access to medical care while in Australia.


 
                           </div>
                         </li>

                         <li >
                           <div className=" d-flex me-auto mt-2 normal  ps-2 justifyText">
                           Health Services for Permanent Residents and Citizens: Australia has a robust healthcare system that offers quality medical services for its permanent residents and citizens. The system includes both public and private healthcare providers, ensuring that all Australians have access to the care they need.
                           </div>

                         </li>
                         <li className="mt-2">
                           <div className=" d-flex me-auto mt-2 ps-2  normal justifyText">
                            Medicare: Medicare is Australia's universal healthcare system, providing access to a wide range of medical services and treatments for permanent residents and citizens. It covers many essential healthcare services, such as visits to general practitioners (GPs), specialists, diagnostic tests, and some hospital treatments. Medicare is funded through a combination of general tax revenue and a Medicare levy, which is a percentage of an individual's taxable income.
                            </div>
                         </li>
                            <li className="mt-2">
                            <div className=" d-flex me-auto ps-2 mt-2  normal justifyText">
                            Mental Health Support: Mental health is an essential aspect of overall well-being, and Australia offers various services and support for those experiencing mental health issues. Organizations such as Beyond Blue, Headspace, and Lifeline provide resources, counselling, and crisis support for people in need

                            </div>
                            </li>
 
                       </ol>
                 </div>

                 <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Education in Australia</h2>
                   </div>
                   <p className="  justifyText mt-4 bolder">
                   Australia boasts a high-quality education system, with numerous top-ranked universities and institutions offering a wide range of courses and programs. The country is a popular destination for international students due to its quality of education and the welcoming environment.

                   </p>
 
 
                   <ol className=" mt-2">
                         <li>
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Primary and Secondary Education: Primary and secondary education in Australia is compulsory, with children starting school at age 5 or 6. Education is provided through a mix of public, private, and religious schools. The curriculum is designed to be comprehensive, focusing on core subjects such as English, mathematics, science, and humanities.


                           </div>
                         </li>
                         <li className="mt-2">
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Higher Education: Australian universities and institutions offer various undergraduate and postgraduate courses, vocational education and training (VET) programs, and English language courses. International students can choose from a wide range of disciplines, from business and engineering to arts and humanities.


                           </div>
                         </li>
                      
 
                       </ol>
                 </div>

                 <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Cost of Living in Australia</h2>
                   </div>
                   <p className="  justifyText mt-4 bolder">
                   While Australia offers a high quality of life, the cost of living can be relatively high, particularly in major cities such as Sydney and Melbourne. Expenses such as housing, food, transportation, and entertainment should be considered when planning a move to Australia.

                   </p>
 
 
                   <ol className=" mt-2">
                         <li>
                           <div className="d-flex me-auto ps-2 justifyText normal">
                           Housing: Housing costs vary depending on the location and type of accommodation. On average, renting a one-bedroom apartment in a city centre can cost around AUD 1,800 to 2,500 per month. In suburban areas, the cost may be slightly lower. The cost will be based on the location and the facility available in the building.

                           </div>
                         </li>
                         <li className="mt-2">
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Food: Grocery prices in Australia can be higher than in some other countries due to the country's remoteness and transportation costs. However, many fresh fruits, vegetables, and meats are produced locally, which can help keep costs down. Eating out in Australia is diverse and can range from affordable fast food to high-end dining experiences.

                           </div>
                         </li>
                         <li>
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Transportation: Australia has a comprehensive public transportation system, including buses, trains, and trams. The cost of public transport varies by city but is generally affordable. Many Australians also own a car, as it provides greater flexibility, especially in suburban and rural areas.


                           </div>
                         </li>
            

                      
 
                       </ol>
                 </div>

                 <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Cultural Experiences</h2>
                   </div>
                   <p className="  justifyText mt-4 bolder">
                   Australia is home to a rich tapestry of cultural experiences, from Indigenous art and traditions to the influence of immigrants from around the world. This diversity is reflected in the country's cuisine, festivals, and arts scene.

                   </p>
 
 
                   <ol className="mt-2">
                         <li>
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Festivals: Australia hosts numerous festivals throughout the year, celebrating music, film, food, and cultural diversity. Some popular events include the Nepal Festivals Sydney, Nepal Festivals Melbourne,  Sydney Festival, Melbourne International Comedy Festival, Adelaide Fringe, and the National Multicultural Festival in Canberra

                           </div>
                         </li>
                         <li className="mt-2">
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Arts Scene: Australia has a thriving arts scene, with galleries, museums, and performance venues showcasing local and international talent. Major cities such as Sydney, Melbourne, and Brisbane are home to iconic institutions like the Sydney Opera House, the National Gallery of Victoria, and the Queensland Art Gallery and Gallery of Modern Art.


                           </div>
                         </li>
                         <li className="mt-2">
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Sports: Australians are passionate about sports, and the country is home to various professional leagues and international sporting events. Popular sports include cricket, Australian Rules Football, rugby, soccer, and tennis. The Australian Open, held in Melbourne, is one of the four Grand Slam tennis tournaments and attracts top players from around the world.



                           </div>
                         </li>
                         
            

                      
 
                       </ol>
                 </div>
                 <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Outdoor Activities and Natural Attractions</h2>
                   </div>
                   <p className="  justifyText mt-4 bolder">
                   Australia is famous for its breathtaking natural attractions and outdoor activities. From the Great Barrier Reef to the Outback, there is no shortage of stunning landscapes to explore.
                   </p>
 
 
                   <ol className=" mt-2">
                         <li>
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Beaches: With over 25,000 kilometres of coastline, Australia boasts some of the world's most beautiful beaches. Popular beach destinations include Bondi Beach in Sydney, Surfers Paradise on the Gold Coast, and St. Kilda Beach in Melbourne.

                           </div>
                         </li>
                         <li className="mt-2">
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           National Parks: Australia is home to more than 500 national parks, showcasing diverse ecosystems, wildlife, and geological features. Notable parks include the UNESCO World Heritage-listed Kakadu National Park, the Blue Mountains National Park, and the Great Sandy National Park, home to the iconic Fraser Island.


                           </div>
                         </li>
                         <li className="mt-2">
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Outdoor Activities: Australians love the outdoors, and there are countless activities to enjoy, such as hiking, camping, surfing, and snorkelling. The Great Ocean Road, a scenic coastal route in Victoria, offers stunning views and excellent opportunities for outdoor adventures.
                           </div>
                         </li>
                         
            

                      
 
                       </ol>
                 </div>


                 <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Social Etiquette and Customs</h2>
                   </div>
                   <p className="  justifyText mt-4 bolder">
                   Understanding social etiquette and customs can help newcomers feel more comfortable in their new environment.
                   </p>
 
 
                   <ol className=" mt-2">
                         <li>
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Manners and Greetings: Australians are generally friendly and informal. A handshake is a common greeting, and it's customary to make eye contact and smile when meeting someone for the first time. Australians often address each other by their first names, even in professional settings.                           </div>
                         </li>
                         <li className="mt-2">
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Communication Style: Australians are known for their direct communication style, which can sometimes be perceived as blunt. However, this directness is typically well-intentioned and not meant to be rude. Australian humour, often characterized by sarcasm and self-deprecation, can also take some getting used to for newcomers.


                           </div>
                         </li>
                         <li className="mt-2">
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Tipping: Tipping is not as common in Australia as it is in some other countries. Service workers generally receive a fair wage, so tipping is not expected. However, it is appreciated for exceptional service.
                           </div>
                         </li>
                         
            

                      
 
                       </ol>
                 </div>

                 <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Climate</h2>
                   </div>
                   <p className="  justifyText mt-4 bolder">
                   Australia's climate is diverse, ranging from tropical in the north to temperate in the south. Weather can vary greatly between regions, so it's essential to research the local climate when planning a move.
                   </p>
 
 
                   <ol className=" mt-2">
                         <li>
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Seasons: Australia's seasons are opposite to those in the Northern Hemisphere, with summer occurring from December to February and winter from June to August.
                           </div>
                         </li>
                         <li className="mt-2">
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Weather Extremes: Australia is prone to weather extremes, such as droughts, bushfires, floods, and cyclones. It's essential to be prepared for these events and to follow any safety advice provided by local authorities.


                           </div>
                         </li>
                     
                         
            

                      
 
                       </ol>
                 </div>


                 <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Cultural Diversity and Dynamism</h2>
                   </div>
                   <p className="  justifyText mt-4 bolder">
                   Australia is a cultural melting pot, with a rich history of immigration and multiculturalism. This diversity is one of the country's greatest strengths, contributing to the dynamism and vibrancy of Australian society.
                   </p>
 
 
                   <ol className=" mt-2">
                         <li>
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Indigenous Cultures: The Aboriginal and Torres Strait Islander peoples are the traditional custodians of the land, with a history that dates back over 60,000 years. Their cultures, languages, and spiritual beliefs are an important part of Australia's national identity. Efforts to preserve and promote Indigenous cultures include support for Indigenous arts, cultural events, and language preservation programs.

                           </div>
                         </li>
                         <li className="mt-2">
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Multiculturalism: Australia's modern society has been shaped by waves of immigration from Europe, Asia, Africa, and the Middle East. Today, nearly half of all Australians were either born overseas or have at least one parent who was. This multiculturalism is celebrated through various events and programs, such as Harmony Day and the annual Multicultural Festival.


                           </div>
                         </li>

                         <li className="mt-2">
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Languages: While English is the official language of Australia, over 300 languages are spoken across the country, reflecting its diverse population. Some of the most commonly spoken languages other than English include Mandarin, Arabic, Cantonese, Vietnamese, and Greek.


                           </div>
                         </li>
                     
                         
            

                      
 
                       </ol>
                 </div>

                 <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Community Life</h2>
                   </div>
                   <p className="  justifyText mt-4 bolder">
                   A strong sense of community is an integral part of Australian society. From local sports clubs to neighbourhood associations, Australians are known for their willingness to come together and support each other.                   </p>
 
 
                   <ol className=" mt-2">
                         <li>
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Volunteering: Volunteering is a popular way for Australians to give back to their communities. Many people volunteer with local organizations, such as schools, hospitals, and non-profit groups, to help make a difference in the lives of others.

                           </div>
                         </li>
                         <li className="mt-2">
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Local Events:  Community events, such as markets, fairs, and festivals, are a great way for people to connect and celebrate local culture. These events often showcase local produce, crafts, and entertainment, fostering a sense of pride and belonging among residents.


                           </div>
                         </li>

                         <li className="mt-2">
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Neighborhood Support:  Australians are generally friendly and supportive of their neighbours. It's common for people to lend a helping hand or offer assistance in times of need, whether it's through informal support networks or organized community groups.


                           </div>
                         </li>

                         
                     
                         
            

                      
 
                       </ol>
                 </div>

                 <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Work-Life Balance</h2>
                   </div>
                   <p className="  justifyText mt-4 bolder">
                   Australians place a high value on work-life balance, recognizing the importance of finding time for leisure and personal pursuits. This focus on balance contributes to the overall well-being and satisfaction of Australian society.
                </p>
 
                   <ol className=" mt-2">
                         <li>
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Flexible Work Arrangements: Many Australian workplaces offer flexible work arrangements, such as part-time work, job sharing, or working from home. These options allow employees to better balance their professional and personal lives, leading to increased job satisfaction and productivity.

                           </div>
                         </li>
                         <li className="mt-2">
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Annual Leave: Full-time employees in Australia are entitled to a minimum of four weeks of paid annual leave, allowing for much-needed rest and relaxation. Many Australians use this time to travel, spend time with family, or engage in hobbies and personal interests.


                           </div>
                         </li>

                         <li className="mt-2">
                           <div className=" d-flex me-auto ps-2 justifyText normal">
                           Parental Leave: Australia offers paid parental leave for eligible employees, ensuring that new parents can take time off work to care for their newborn or adopted child. This support helps to promote a healthy work-life balance and allows families to bond during this important life stage.


                           </div>
                         </li>

                         
                     
                         
            

                      
 
                       </ol>
                 </div>

                 <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Public Safety</h2>
                   </div>
                   <p className="  justifyText mt-2 normal">
                   Australia is generally considered a safe country, with low crime rates and a strong commitment to public safety. However, it's important to take personal safety precautions and be aware of any potential risks or hazards.                </p>
                 </div>

                 <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Politics and Government</h2>
                   </div>
                   <p className="  justifyText mt-2 normal">
                   Understanding Australia's political system and government structure can be helpful in getting to know the country. Australia has a parliamentary democracy with a federal system, which means power is divided between the national government and six states and two territories.</p>
                 </div>

                 <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Environmental Issues and Sustainability</h2>
                   </div>
                   <p className="  justifyText mt-2 normal">
                   Australia is home to unique ecosystems and wildlife, but it also faces various environmental challenges, such as climate change, habitat loss, and water scarcity. The country has made efforts to address these issues through conservation initiatives, renewable energy investments, and waste reduction strategies.
                   </p>
                   
                 </div>

                 <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Opportunities for Personal and Professional Government</h2>
                   </div>
                   <p className="  justifyText mt-2 normal">
                   Australia offers numerous opportunities for personal and professional growth. For those looking to advance their careers, there are various networking events, conferences, and professional development courses available. Additionally, Australia's diverse cultural landscape provides countless opportunities to learn new languages, explore different cuisines, and engage with people from all over the world.
                  </p>
                  </div>

                  <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Access to Technology and Connectivity</h2>
                   </div>
                   <p className="  justifyText mt-2 normal">
                   Australia is well-connected in terms of technology, with widespread access to the internet and mobile networks. This connectivity allows for seamless communication, both within Australia and with the rest of the world, and supports the growth of tech-related industries and opportunities.
                  </p>
                  </div>

                  <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Job Availability and Types</h2>
                   </div>
                   <p className="  justifyText mt-2 normal">
                   Australia has a diverse job market, offering various opportunities across a range of industries. Some of the key sectors driving the Australian economy include:
                  </p>

                  <p className="  justifyText mt-2 normal">
                  These are just a reference to the common representative of the Australian industry. 
                  </p>

                  {/* <p className="  justifyText mt-4 bolder"> */}
                  <ul className="ausBullet   justifyText mt-4 bolder">
                    <li>
                    Mining and resources
                    </li>
                    <li>
                    Agriculture and agribusiness
                    </li>
                    <li>
                    Healthcare and social assistance
                    </li>
                    <li>
                    Education and training
                    </li>
                    <li>
                    Tourism and hospitality
                    </li>
                    <li>
                    Financial Services
                    </li>
                    <li>
                    Information technology and telecommunications
                    </li>
                    <li>
                    Retail and consumer services
                    </li>
                    <li>
                    Manufacturing and construction
                    </li>
                  </ul>
                  </div>  

                  <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Minimum Income and Wage Standards</h2>
                   </div>
                   <p className="  justifyText mt-2 normal">
                   Australia has a national minimum wage, which is reviewed and adjusted annually by the Fair Work Commission. As of July 2022, the national minimum wage was AUD 21.38 per hour or AUD 812..60 per 38-hour week (before tax). However, it is important to note that wages may be higher in some industries or for specific roles, and these rates may change over time.
                  </p>
                  </div> 

                  <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Public Holidays</h2>
                   </div>
                   <p className="  justifyText mt-2 normal">
                   Australia has a number of public holidays throughout the year, with some observed nationwide and others specific to each state or territory. These holidays offer opportunities for rest, relaxation, and celebration. Some of the key public holidays include Australia Day, Anzac Day, and Christmas Day, Ester holiday, King's Birthday. Please note that public holidays may differ state-wise. 
                  </p>
                  </div> 

                  <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Australian Cuisine</h2>
                   </div>
                   <p className="  justifyText mt-2 normal">
                   Australian cuisine is diverse and influenced by the country's multicultural heritage. While there are many traditional dishes such as meat pies, lamingtons, and Vegemite on toast, Australia is also known for its excellent fusion cuisine, world-class restaurants, and fresh, high-quality ingredients.
                  </p>
                  </div>

                  <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start">Pets and Animal Welfare</h2>
                   </div>
                   <p className="  justifyText mt-2 normal">
                   Australians are known for their love of animals, with many households having pets like dogs, cats, and birds. Australia has strict animal welfare laws and regulations, as well as numerous animal shelters and rescue organizations that work to protect and care for animals in need.
                  </p>
                  </div>

                 
                  <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start"> Australian Fashion</h2>
                   </div>
                   <p className="  justifyText mt-2 normal">
                   Australia has a thriving fashion industry, with both local and international designers showcasing their collections at events such as Australian Fashion Week. Australian fashion is known for its relaxed, laid-back style, as well as its innovative and creative designs.
                  </p>
                  </div>   

                  <div className="col-lg-12">
                   
                   <div className="innerSecondSection mt-4 d-flex ">
                     <h2 className="text-start"> Travel Opportunities</h2>
                   </div>
                   <p className="  justifyText mt-2 normal">
                   Australia's vast size and diverse landscapes offer a wealth of travel opportunities, both within the country and to nearby destinations in the Asia-Pacific region. From exploring the Outback to visiting the tropical rainforests of Queensland or hopping over to New Zealand, there are endless adventures to be had.                  </p>
                  </div>  
              </div>

        

     
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
