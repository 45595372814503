import React from 'react'
import './BookAnAppointmentBanner.css'
import Appointement from '../../../Assets/Images/appointementBanner.jpg'



import { Card, Col, Container, Row } from 'react-bootstrap'

export default function BookAnAppointmentBanner() {
  return (
    <section className='servicesbanner overflow-hidden'>
      
      <img src={Appointement} className='img-fluidx banner_img'/>
      
      <Container fluid className='banner_container booktext'>
        <h1>BOOK AN APPOINTEMENT</h1>
      </Container>
    </section>
  )
}
