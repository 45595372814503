import React from "react";
import "./CareerConsultationPage.css";
import { Container, Row, Col, Button } from "react-bootstrap";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SKILLBG from "../../Assets/Images/careerIner.png";
import MigrationBG1 from "../../Assets/Images/migrationChild.png";
import bullet from "../../Assets/Images/beforeBullet.png";
import bookApp from "../../Assets/Images/calanderApp.png";
import chatApp from "../../Assets/Images/chatEnquire.png";

import { useHistory } from "react-router-dom";


export default function CareerConsultationPage() {

  let history = useHistory();

  const AppointementPage=()=> {
    history.push("/bookanappointment");
    window.scrollTo(0, 0)
  }


  const ContactUsPage=()=> {
    history.push("/ContactUs");
    window.scrollTo(0, 0)
  }
  return (
    <>
        <section className="bg-white">
        <Container>
          <Row className="py-5">
            <Col className="col-lg-12">
          
            
                <div className="imgBG pt-3 d-flex  mt-4">
                  <img src={SKILLBG} className="w-100 img-fluid responsivePadding" />
                </div>

                <div className="col-lg-12">
                  <div className="innerSecondSection mt-4 d-flex responsivePadding">
                    <h2 className="text-start">Your trusted career partner</h2>
                  </div>
                  <p className="textColorDefault normal  justifyText mt-2 responsivePadding">
                  Here at focus visa, we are dedicated to provide you the best possible pathway to make your dream true. Our experts provides you the current prospect of the job market, educational needs, entrepreneurship skills, assistance in resume writing, help you setting up your social media profile and getting appropriate skills and knowledge about working in the leading institution. Here at focus visa, we are dedicated to providing you with the best possible pathway to make your dream come true. Our experts give you the current prospect of the job market, educational needs, entrepreneurship skills, and assistance in resume writing, help you set up your social media profile and get appropriate skills and knowledge about working in the leading institution.   
                  </p>
         
               
                </div>

         

                <div className="col-lg-12">
                  <div className="innerHowMuchSection pt-3 d-flex  mt-1 ">
                  
                    <Row className="responsivePadding">
                    <div className="innerSecondSection mt-2 d-flex mb-2 responsivePadding">
                    <h4 className="text-start"> For Individuals</h4>
                  </div>

                  {/* <p className="justifyText textColorDefault">Life cover, or Life Insurance, covers death or Terminal Illness to help protect your family’s future.</p> */}

                   
                      <div className="d-flex align-items-center responsivePadding">
                      <img src={bullet}  width="10" height="10" className="mb-2 pb-1"/>
                      <p className="ms-3  textColorDefault">Resume Writing Services</p>
                      </div>

                      <div className="d-flex align-items-center responsivePadding">
                      <img src={bullet}  width="10" height="10" className="mb-2 pb-1"/>
                      <p className="ms-3  textColorDefault">LinkedIn Profile Writing Service</p>
                      </div>

            

                      
    
                    </Row>

                  
                    
                  </div>
                  
                </div>

                <div className="col-lg-12">
                  <div className="innerHowMuchSection pt-3 d-flex  mt-1">
                  
                    <Row className="responsivePadding">
                    <div className="innerSecondSection mt-2 d-flex mb-2">
                    <h4 className="text-start"> For Organisations</h4>
                  </div>

                  <p className="justifyText textColorDefault">Life cover, or Life Insurance, covers death or Terminal Illness to help protect your family’s future.</p>

                   
                      <div className="d-flex align-items-center">
                      <img src={bullet}  width="10" height="10" className="mb-2 pb-1"/>
                      <p className="ms-3  textColorDefault">Outplacement Services</p>
                      </div>

                      <div className="d-flex align-items-center">
                      <img src={bullet}  width="10" height="10" className="mb-2 pb-1"/>
                      <p className="ms-3  textColorDefault">Psychometric Testing Services</p>
                      </div>

                   
                      
    
                    </Row>

                  
                    
                  </div>
               
                </div>


                {/* <div className="col-lg-12 d-flex m-auto justify-content-center mt-4">
                  <div className="col-lg-4">
                    <Button className="btnInfo w-100 ps-2 pe-0" onClick={AppointementPage} > <img src={bookApp} className="img-fluid pe-2"/> BOOK AN APPOINTEMENT</Button>
                    <Button className="mt-3 lightAlice border-0 textColorInfo w-100" onClick={ContactUsPage}><img src={chatApp} className="img-fluid pe-2"/>  ENQUIRE NOW</Button>
                  </div>
                </div> */}
       
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
