import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import { inputLabelClasses, } from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import doubleTick from '../../../Assets/Images/doubletick.png'
import date from '../../../Assets/Images/calanderApp.png'
import axios from "axios";
import Loading from '../../Layout/loading/Loading'

import { makeStyles } from "@mui/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const useStyles = makeStyles((theme) => ({
  phoneInputContainer: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    borderRadius: "4px",
    border: "1px solid #D2D2D2",
    "&:hover": {
      borderColor: "#2B2B2B",
    },
    "&.Mui-focused": {
      borderColor: "#2B2B2B",
      boxShadow: "0 0 0 2px rgba(43, 43, 43, 0.2)",
    },
  },
  phoneInput: {
    width: "100%",
    height: "40px",
    fontSize: "14px",
    padding: "25px 50px !important",
    fontWeight: 500,
    lineHeight: 1.2,
    fontSize: "15px !important",
    borderTop: 0 + '!important',
    borderBottom: 2 + '!important',
    borderBottom: "1px solid #8f9194 !important",
    borderRight: 0 + '!important',
    borderLeft: 0 + '!important',
    borderRadius: 0 + "important",
    color: "#3263b1 !important",
    backgroundColor: "#f7faff !important",
    border: "none",
    outline: "none",
    "&::placeholder": {
      color: "#9B9B9B",
      opacity: 1,
    },
  },
  countrySelect: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    background: "#f7faff !important",
    borderTop: 0 + '!important',
    borderBottom: "1px solid #8f9194 !important",
    borderRight: 0 + '!important',
    borderLeft: 0 + '!important',
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f7faff !important",
    },
  },
}));


export default function BookAnAppointmentSteps3(props) {
  const [VisaType, setVisaType] = React.useState("");
  const [Name, setName] = useState('');
  const [MobileNo, setMobileNo] = useState('');
  const [Email, setEmail] = useState('');
  const [State, setState] = useState('');
  const [NewState, setNewState] = useState('');
  const [Notes, setNotes] = useState('');
  const [appintmentType, setappintmentType] = useState('');
  const [loading, setloading] = React.useState(false);
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [newStateError, setnewStateError] = useState('');
  const [TypeVisaError, setTypeVisaError] = useState('');
  const [AppointmentError, setAppointmentError] = useState('');

  const classes = useStyles();
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const handlePhoneNumberChange = (value) => {
  //   setPhoneNumber(value);
  // };

  const handleNameChange = (event) => {
    if (event.target.value.length === 0) {
      setNameError('Full name is required');
    } else {
      setNameError('');
    }
    setName(event.target.value);
  };
  const handleMobileChange = (value) => {
    if (value.length === 0) {
      setMobileError('Mobile No. is required');
    } else {
      setMobileError('');
    }
    setMobileNo(value);
  };
  const handleEmailChange = (event) => {
    if (event.target.value.length === 0) {
      setEmailError('Email is required');
    } else {
      setEmailError('');
    }
    setEmail(event.target.value);
  };
  const handleStateChange = (event) => {
    if (event.target.value.length === 0) {
      setnewStateError('State is required');
    } else {
      setnewStateError('');
    }
    setNewState(event.target.value);
  };

  const handleTypeVisaChange = (event) => {
    if (event.target.value.length === 0) {
      setTypeVisaError('Visa type is required');
    } else {
      setTypeVisaError('');
    }
    setVisaType(event.target.value);
  };
  const handleAppointmentChange = (event) => {
    if (event.target.value.length === 0) {
      setAppointmentError('Appointment type is required');
    } else {
      setAppointmentError('');
    }
    setappintmentType(event.target.value);
  };
  const submit = () => {
    let error = false
    if (Name.length == 0) {
      setNameError('Full name is required');
      error = true
    } else {
      setNameError('');
    }
    if (MobileNo.length == 0) {
      setMobileError('Mobile No. is required');
      error = true
    } else {
      setMobileError('');
    }
    var regex =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    if (Email.length == 0) {
      setEmailError('Email is required');
      error = true
    } else if (!regex.test(Email)) {
      setEmailError('Invalid email address');
      error = true
    } else {
      setEmailError('');
    }
    if (VisaType.length == 0) {
      setTypeVisaError('Visa type is required');
      error = true
    } else {
      setTypeVisaError('');
    }
    if (NewState.length == 0) {
      setnewStateError('State is required');
      error = true
    } else {
      setnewStateError('');
    }
    if (appintmentType.length == 0) {
      setAppointmentError('Appointment type is required');
      error = true
    } else {
      setAppointmentError('');
    }
    if (!error) {
      setloading(true)
      let mobile = '+' + MobileNo
      axios.post(`${process.env.REACT_APP_SERVER_URL}/api/admin/appoinment/createappoinment`, {
        consolerId: props.allData.cosoler_id,
        fullName: Name,
        mobileNo: mobile,
        email: Email,
        visaType: VisaType,
        stateApply: NewState,
        state: State,
        appoinmentType: appintmentType,
        notes: Notes,
        timeZone: JSON.stringify(props.allData.calenderDetails.timeZone),//object me aaega//
        appointment_date: props.A_Date//allData.calenderDetails.date
      }).then((res) => {
        setloading(false)
        if (res.data.statuscode == 200) {
          props.ThirdStepData(res.data.data)
          props.handleNext()
        }
      })
    }
  };
  useEffect(() => {
  }, [])


  return (
    <section className="formbox h-100 pb-5 mb-5 mt-4">
      <Container>
        {loading ? <Loading /> : ""}
        <div className="headerText">
          <h3>Fill Your Details</h3>
          <span className="text-center w-25 my-5">
            To see the available appointment times in your local time zone, select your time zone and then choose a date and time from the list provided below. <br></br> You can schedule appointments online or in-person.

          </span>
        </div>

        <Row>
          <Col className="col-lg-12 mt-5">
            <Form>
              <Row className="d-flex flex-column align-items-center">
                <div style={{ width: 500 }} className="formphone pb-4 ps-0 pe-0 me-0 ms-0">
                  <TextField
                    className=""
                    style={{ width: 500 }}
                    label="Full Name"
                    value={Name}
                    onChange={handleNameChange}

                    variant="filled"
                    color="info"
                    InputLabelProps={{
                      sx: {
                        color: "#3363B1",
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: "#3363B1",
                        },
                      },
                    }}
                  />
                  {nameError && (
                    <FormHelperText style={{ marginLeft: "15px" }} error={true}>{nameError}</FormHelperText>
                  )}
                </div>

                <div style={{ width: 500 }} className="formphone pb-4 ps-0 pe-0 me-0 ms-0">
                  <PhoneInput
                    country={'us'}
                    value={MobileNo}
                    enableSearch={true}
                    disableSearchIcon={true}
                    onChange={handleMobileChange}
                    inputClass={classes.phoneInput}
                    buttonClass={classes.countrySelect}
                    dropdownClass={classes.dropdown}
                    containerStyle={{ width: '100%' }}
                    specialLabel={''}
                    placeholder={'Enter phone number'}
                    flagStyle={{ backgroundColor: 'blue', borderRadius: '4px' }}
                  />
                  {mobileError && (
                    <FormHelperText style={{ marginLeft: "15px" }} error={true}>{mobileError}</FormHelperText>
                  )}
                </div>

                {/* <TextField
                  className="mb-4"
                  style={{ width: 500 }}
                  label="Mobile No."
                  value={MobileNo}
                  onChange={handleMobileChange}
                  error={!!mobileError}
                  helperText={mobileError}
                  variant="filled"
                  color="info"
                  InputLabelProps={{
                    sx: {
                      color: "#3363B1",
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "#3363B1",
                      },
                    },
                  }}
                /> */}

                <div style={{ width: 500 }} className="formphone pb-4  ps-0 pe-0 me-0 ms-0">

                  <TextField
                    className=""
                    style={{ width: 500 }}
                    label="Email"
                    value={Email}
                    onChange={handleEmailChange}

                    variant="filled"
                    color="info"
                    InputLabelProps={{
                      sx: {
                        color: "#3363B1",
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: "#3363B1",
                        },
                      },
                    }}
                  />
                  {emailError && (
                    <FormHelperText style={{ marginLeft: "15px" }} error={true}>{emailError}</FormHelperText>
                  )}
                </div>
                <TextField
                  className="mb-4"
                  style={{ width: 500 }}
                  label="State (Optional)"
                  value={State}
                  onChange={(e) => setState(e.target.value)}
                  variant="filled"
                  color="info"
                  InputLabelProps={{
                    sx: {
                      color: "#3363B1",
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "#3363B1",
                      },
                    },
                  }}
                />

                <FormControl
                  variant="filled"
                  className="mb-4"
                  style={{ width: 500 }}
                >
                  <InputLabel id="demo-simple-select-filled-label" className="textColorInfo font-weight-normal">
                    Types Of Visa
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={VisaType}
                    onChange={handleTypeVisaChange}

                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'student visa'}>Student Visa</MenuItem>
                    <MenuItem value={'working and skilled visa'}>Working and Skilled Visa </MenuItem>
                    <MenuItem value={'family and partner visa'}>Family and Partner Visa</MenuItem>
                    <MenuItem value={'visitor visa'}>Visitor Visa </MenuItem>
                    <MenuItem value={'other visa'}>Other Visas</MenuItem>
                  </Select>
                  {TypeVisaError && (
                    <FormHelperText style={{ marginLeft: "15px" }} error={true}>{TypeVisaError}</FormHelperText>
                  )}
                </FormControl>


                <div style={{ width: 500 }} className="formphone pb-4  ps-0 pe-0 me-0 ms-0">
                  <TextField
                    className=""
                    style={{ width: 500 }}
                    label="State For You Want To Apply"
                    value={NewState}
                    onChange={handleStateChange}
                    variant="filled"
                    color="info"
                    InputLabelProps={{
                      sx: {
                        color: "#3363B1",
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: "#3363B1",
                        },
                      },
                    }}
                  />
                  {newStateError && (
                    <FormHelperText style={{ marginLeft: "15px" }} error={true}>{newStateError}</FormHelperText>
                  )}
                </div>
                <FormControl
                  variant="filled"
                  className="mb-4"
                  style={{ width: 500 }}
                >
                  <InputLabel id="demo-simple-select-filled-label" className="textColorInfo font-weight-normal">
                    Type Of Appointment
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={appintmentType}
                    onChange={handleAppointmentChange}

                  >
                    <MenuItem value="" className="text-start">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'online'}>Online</MenuItem>
                    <MenuItem value={'offline'}>Offline</MenuItem>
                  </Select>
                  {AppointmentError && (
                    <FormHelperText style={{ marginLeft: "15px" }} error={true}>{AppointmentError}</FormHelperText>
                  )}
                </FormControl>

                <TextField
                  multiline
                  rows={6}
                  cols={50}
                  className="mb-4"
                  style={{ width: 500 }}
                  value={Notes}
                  onChange={(e) => setNotes(e.target.value)}
                  label="Additional Notes (Optional)"
                  variant="filled"
                  color="info"
                  InputLabelProps={{
                    sx: {
                      color: "#3363B1",
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "#3363B1",
                      },
                    },
                  }}
                />
              </Row>
              <Row>
                <Col className="m-auto ">
                  <div className="">
                    <Button className="btn btn-default btnInfo w-100 mt-4 py-3" onClick={submit}>
                      <img src={doubleTick} className="img-fluid pe-2" />  SUBMIT
                    </Button>

                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
