import React, { useState } from "react";
import { Card, Button, Container, Row } from "react-bootstrap";
import PointsCalculator from "../../../Pages/PointsCalculator";
import PointsCalculatorBanner from "../PointsCalculatorBanner/PointsCalculatorBanner";
import News1 from "../../../Assets/Images/gsmCalc.png";
import News2 from "../../../Assets/Images/buissnesscalc.png";
import News3 from "../../../Assets/Images/matrixcalc.png";
import News4 from "../../../Assets/Images/canberra.png";
import { FaCalculator } from "react-icons/fa";
import { useHistory } from "react-router-dom";


const CardWithBackground = ({ background, title, text, onClick }) => {
  const [expanded, setExpanded] = useState(false);
  const cardStyle = {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: expanded ? "auto" : "200px",
    cursor: "pointer",
  };

  const toggleExpand = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  const handleCardClick = () => {
    onClick();
  };

  return (
    <Card
      style={cardStyle}
      className="border-0 rounded-0"
      onClick={handleCardClick}
    >
      <Card.Body className="pb-5 rounded-0 px-4">
        <div className="d-flex justify-content-center align-items-end mb-3">
          <FaCalculator size={200} className="text-light me-4" />
          <div>
            <Card.Title className="text-light justifyText pointer calcTitle">
              {title}
            </Card.Title>
            <Card.Text
              style={{
                maxHeight: expanded ? "none" : "100px",
                overflow: "hidden",
              }}
              className="text-white justifyText"
            >
              {text}
            </Card.Text>
          </div>
        </div>
        {text.length > 100 && (
          <Button
            variant="primary"
            size="sm"
            onClick={toggleExpand}
            className="btnInfo border-0 border-4"
          >
            {expanded ? "Read Less" : "Read More"}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};

const PointsCalculatorBannerMessage = () => {
  let history = useHistory();

  const calc1 = () => {
    window.open("https://immi.homeaffairs.gov.au/help-support/tools/points-calculator");
    window.scrollTo(0, 0);
  };

  const calc2 = () => {
    window.open("https://immi.homeaffairs.gov.au/help-support/tools/points-calculator");
    window.scrollTo(0, 0);
  };

  const calc3 = () => {
    window.open("https://www.act.gov.au/migration/skilled-migrants/canberra-matrix/check-your-canberra-matrix-score-canberra-residents");
    window.scrollTo(0, 0);
  }

  const calc4 = () => {
    window.open("https://www.act.gov.au/migration/skilled-migrants/canberra-matrix/check-your-canberra-matrix-score-overseas-applicants");
    window.scrollTo(0, 0);
  };

  return (
    <Container className="my-5 py-5">
      <Row className="px-2">
        <div className=" col-lg-6 mb-3 ">
          <CardWithBackground
            background={News1}
            title="GMS Points Calculator"
            onClick={calc1}
            text="General Skilled Migration (GSM) points calculator helps you estimate points for skilled visas, including Subclass 189, 190, and 491. Points are crucial for eligibility and are based on factors like age, language proficiency, work experience, and education. Calculate your points to determine your chances for Australian skilled visas."
          />
        </div>
        <div className=" col-lg-6 mb-3 ">
          <CardWithBackground
            background={News2}
            title="Business Visa Points Calculator"
            onClick={calc2}
            text="Business Visa Points Calculator helps estimate points for Australian business and investment visas, such as Subclass 188. Points are crucial for eligibility and are based on factors like business experience, assets, and innovation. Calculate your points to assess your prospects for Australian business visas."
          />
        </div>
      </Row>
      <Row className="px-2">
        <div className=" col-lg-6 mb-3 ">
          <CardWithBackground
            background={News3}
            onClick={calc3}
            title="Canberra Matrix (Canberra Residents)"
            text="Canberra Matrix points calculator for Canberra Residents helps Canberra residents calculate their points for ACT nomination under the Subclass 190 and 491 visas. Points are based on factors like residence duration, language proficiency, employment, and community involvement. Use our service to assess your prospects for ACT nomination."
          />
        </div>
        <div className=" col-lg-6 mb-3 ">
          <CardWithBackground
            background={News4}
            onClick={calc4}
            title="Canberra Matrix (Overseas Residents)"
            text="Canberra Matrix points calculator for overseas residents assists overseas residents in calculating their points for ACT nomination under the Subclass 190 and 491 visas. Points are based on factors like language proficiency, work experience, and education. Utilize our service to evaluate your potential for ACT nomination as an overseas resident."
          />
        </div>
      </Row>
    </Container>
  );
};

export default PointsCalculatorBannerMessage;
