import React from "react";
import "./InsurancePage.css";
import { Container, Row, Col, Button } from "react-bootstrap";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SKILLBG from "../../Assets/Images/insuanceInner.png";
import MigrationBG1 from "../../Assets/Images/migrationChild.png";
import bullet from "../../Assets/Images/beforeBullet.png";
import bookApp from "../../Assets/Images/calanderApp.png";
import chatApp from "../../Assets/Images/chatEnquire.png";

import { useHistory } from "react-router-dom";


export default function InsurancePage() {

  let history = useHistory();

  const AppointementPage=()=> {
    history.push("/bookanappointment");
    window.scrollTo(0, 0)
  }


  const ContactUsPage=()=> {
    history.push("/ContactUs");
    window.scrollTo(0, 0)
  }
  return (
    <>
        <section className="bg-white">
        <Container>
          <Row className="py-5 responsivePadding">
            <Col className="col-lg-12">
          
            
                <div className="imgBG pt-3 d-flex  mt-4">
                  <img src={SKILLBG} className="w-100" />
                </div>

                <div className="col-lg-12">

                  <h4 className="text-start bolder  mt-4">Introduction</h4>
               
                  <p className="black  medium justifyText mt-4">
                  Our Life Insurance pays a lump sum in the event of your
                        death or if you’re diagnosed with a Terminal Illness3,
                        which can help support your family when you’re no longer
                        around to provide for them. 
                  </p>
         
                </div>

         


                

                {/* <div className="col-lg-12 d-flex m-auto justify-content-center mt-4">
                  <div className="col-lg-4">
                    <Button className="btnInfo w-100 ps-2 pe-0" onClick={AppointementPage} > <img src={bookApp} className="img-fluid pe-2"/> BOOK AN APPOINTEMENT</Button>
                    <Button className="mt-3 lightAlice border-0 textColorInfo w-100" onClick={ContactUsPage}><img src={chatApp} className="img-fluid pe-2"/>  ENQUIRE NOW</Button>
                  </div>
                </div> */}
       
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
