import React, { useState } from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./Services.css";
import EducationIcon from "../../../Assets/Images/Applyforvisaicon.png";
import GraduateIcon from "../../../Assets/Images/graduateCap.png";
import MigrationIcon from "../../../Assets/Images/migration.png";
import CalculatorIcon from "../../../Assets/Images/PointsCalculatorIcon.png";
import SkillAssesmentIcon from "../../../Assets/Images/skill.png";
import InsuranceIcon from "../../../Assets/Images/insurance.png";
import VisitorIcon from "../../../Assets/Images/visitor.png";
import SearchOccupationIcon from "../../../Assets/Images/SearchOccupationIcon.png";

function CardComponent({ icon, title, description, onClick }) {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Card className="cardhover text-start text-primary p-4 h-100">
      <Card.Header className="bg-transparent border-0">
        <Image
          src={icon}
          className="mb-4 d-flex me-auto"
          height="50"
          width="50"
        ></Image>

        <Card.Title
          className="text-start textColorInfo textTitle pointer"
          onClick={onClick}
        >
          <b>{title}</b>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Card.Text className="justifyText">
          {showMore ? description : description.substring(0, 100) + "..."}
        </Card.Text>
        <Button
          onClick={toggleShowMore}
          variant="primary"
          className="readBtn border-0"
        >
          {showMore ? "Read Less" : "Read More"}
        </Button>
      </Card.Body>
    </Card>
  );
}

function Services() {
  let history = useHistory();

  const handleVisaProcess = () => {
    history.push("/visaprocess");
    window.scrollTo(0, 0);
  };

  const handleMigrationProcess = () => {
    history.push("/migrationprocess");
    window.scrollTo(0, 0);
  };

  const handleSkillAssessment = () => {
    history.push("/skillassessment");
    window.scrollTo(0, 0);
  };

  const Insurance = () => {
    history.push("/insurance");
    window.scrollTo(0, 0);
  };

  const services = [
    {
      icon: EducationIcon ,
      title: "Student Visa (Subclass 500)",
      description:
        "Focus Visa and Education Services is dedicated to helping you navigate the Australian Student Visa (Subclass 500) process with ease. Our personalized services include guidance on course selection, visa eligibility, and application assistance, tailored to your unique needs and aspirations. Our team of experts works tirelessly to ensure you have a smooth transition to your chosen educational institution in Australia, empowering you to unlock your potential and achieve your dreams through world-class education. Trust Focus Visa and Education Services to bring your Aussie student adventure to life.",
      onClick: handleVisaProcess,
    },
    {
      icon: GraduateIcon ,
      title: "Temporary Graduate Visa (Subclass 485)",
      description:
        "Rely on Focus Visa and Education Services to expertly guide you through the application process for the Subclass 485 visa. Our personalized approach ensures that you choose the right stream, whether it's the Graduate Work Stream or the Post-Study Work Stream, based on your qualifications and goals. Trust us to simplify your journey towards living, working, and gaining valuable experience in Australia, as we turn your Aussie dreams into reality.",
      onClick: handleVisaProcess,
    },
    {
      icon: MigrationIcon ,
      title: "Migration",
      description:
        "Experience a seamless migration journey to Australia with our top-tier migration services, designed to turn your Aussie dreams into reality. Our team of highly professional experts is committed to guiding you every step of the way, from visa application to settlement. With personalized solutions and unparalleled expertise, we simplify the complex immigration process, ensuring a smooth transition as you embark on your new life down under. Trust us to make your Australian dream come true!",
      onClick: handleMigrationProcess,
    },
    {
      icon:  SkillAssesmentIcon ,
      title: "Skill Assessment",
      description:
        " At Focus Visa and Education Services, we offer comprehensive skill assessment support to help you navigate this vital aspect of the migration process. Our team of experts works closely with you to evaluate your qualifications and work experience, ensuring they align with the standards of your chosen occupation in Australia. By providing personalized guidance and assistance, we simplify the skill assessment process, maximizing your chances of a successful migration outcome. Trust our skill assessment service to bring you closer to your Australian career goals.",
      onClick: handleSkillAssessment,
    },

    {
      icon: InsuranceIcon ,
      title: "Insurance",
      description:
        "Trust Focus Visa and Education Services for tailored support in obtaining Overseas Student Health Cover (OSHC) and Overseas Visitors Health Cover (OVHC), ensuring your insurance meets Australian requirements. We simplify the process, securing the right coverage for your Australian adventure.",
      onClick: Insurance,
    },

    {
      icon:  VisitorIcon ,
      title: "Visitor Visa",
      description:
        "Focus Visa and Education Services offers expert guidance in applying for Australian visitor visas, ensuring a smooth and stress-free process. Our personalized approach helps you navigate eligibility requirements, documentation, and application procedures, maximizing your chances of a successful outcome. Trust our visitor visa support to make your Australian travel dreams a reality.",
      onClick: handleVisaProcess,
    },
  ];

  return (
    <div className="body bg-white pb-5 servicesection">
      <Container className=" pagepadding pb-5">
        <center>
          <div className="normaldivpadding capitalize justify-content-center textColorInfo">
            <h5 className="h1AfterService responsiveFontLargeMedium">Services</h5>
          </div>
          <h4 className="text-gray">Unlock a world of possibilities for your education and migration aspirations <br></br> with our diverse range of comprehensive solutions designed to make your dreams a reality.</h4>
        </center>
        <Row>
          {services.map((service, index) => (
            <Col key={index} xs={12} sm={6} md={4} className="cardbody mb-4 mt-5">
              <CardComponent
                icon={service?.icon}
                title={service?.title}
                description={service?.description}
                onClick={service?.onClick}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Services;
