import React from 'react'
import Navbar from '../Components/Layout/NavBar/NavBar'
import Footer from '../Components/Layout/Footer/Footer'
import BookAnAppointment from '../Components/BookAnAppointmentPage/BookAnAppointmentPage'
import SkillAssessmentsBanner from '../Components/SkillAssessmentsPage/SkillAssessmentsBanner/SkillAssessmentsBanner'
import SkillAssessmentsPage from '../Components/SkillAssessmentsPage/SkillAssessmentsPage'
import FooterVideo from '../Components/Layout/FooterVideo/FooterVideo'
import InsuranceBanner from '../Components/InsurancePage/InsuranceBanner/InsuranceBanner'
import InsurancePage from '../Components/InsurancePage/InsurancePage'
import CareerConsultationBanner from '../Components/CareerConsultationPage/CareerConsultationBanner/CareerConsultationBanner'
import CareerConsultationPage from '../Components/CareerConsultationPage/CareerConsultationPage'
export default function CareerConsultation() {
  return (
    <>
      <Navbar/>
      <CareerConsultationBanner/>
      <CareerConsultationPage/>
      <FooterVideo/>
      <Footer/>
    </>
  )
}
