import React, { useEffect } from "react";
import { Col, Row, Image } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import Logo from "../../../Assets/Images/Logo.png";
import { FaBars } from "react-icons/fa"; // Import the desired React Icon component
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory } from "react-router-dom";

import "./NavBar.css";
import facebook from "../../../Assets/Images/Footer-Facebook-icon.png";
import tweeter from "../../../Assets/Images/Footer-Twitter-icon.png";
import google from "../../../Assets/Images/Footer-Google+-icon.png";
import legalremit from "../../../Assets/Images/Footer-lr-icon.png";
import tiktok from "../../../Assets/Images/Footer-Tiktok-icon.png";
import insta from "../../../Assets/Images/instagram.png";
import youtube from "../../../Assets/Images/Youtube.png";
import linkedin from "../../../Assets/Images/Linked In.png";

export default function NavBar({ Page }) {
  let history = useHistory();
  const [page, setPage] = React.useState("Home");
  useEffect(() => {
    setPage(Page);
    window.scrollTo(0, 0);
  }, []);

  const home = () => {
    history.push("/");
    window.scrollTo(0, 0);
  };

  const openFacebook = () => {
    window.open("https://www.facebook.com/FocusVisaEducation");
  };

  const login = () => {
    history.push("/login");
    window.scrollTo(0, 0);
  };

  const Home_page = () => {
    setPage("Home");
    history.push("/");
    window.scrollTo(0, 0);
  };

  const AppointementPage = () => {
    history.push("/bookanappointment");
    window.scrollTo(0, 0);
  };

  const ServicePage = () => {
    setPage("Services");
    history.push("/services");
    window.scrollTo(0, 0);
  };

  const AboutUsPage = () => {
    setPage("AboutUs");
    history.push("/aboutus");
    window.scrollTo(0, 0);
  };

  const ContactUsPage = () => {
    setPage("ContactUs");
    history.push("/ContactUs");
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Navbar className="BackgroundInfo p-0" id="topnavrow" expand="lg">
        <Container>
          <Navbar.Brand className=" m-0 me-auto">
            <span
              className="text-white p-0 m-0 smallHeading pointer"
              onClick={Home_page}
            >
              Welcome to Focus Visa
            </span>
          </Navbar.Brand>

          <Navbar.Collapse id="responsive-navbar-nav ">
            <Nav className="ms-auto align-items-center d-flex m-0 p-0">
              <div className="me-4 pointer">
                <a>
                  <Image src={facebook} onClick={openFacebook}></Image>
                </a>
              </div>
              <div className="me-4">
                <div>
                  <Image src={tweeter}></Image>
                </div>
              </div>
              <div className="me-4 ">
                <div>
                  <Image src={tiktok}></Image>
                </div>
              </div>
              <div className="me-4">
                <div>
                  <Image src={insta}></Image>
                </div>
              </div>
              <div className="me-4">
                <div>
                  <Image src={youtube}></Image>
                </div>
              </div>
              <div className="me-4">
                <div>
                  <Image src={linkedin}></Image>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Navbar
        className="p-0"
        sticky="top"
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="dark"
      >
        <Container>
          <Navbar.Brand onClick={home} className="pointer">
            <img src={Logo} className="Logo img-fluid" alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <FaBars size={20} style={{ color: "white" }} />
            {/* Use the desired React Icon component */}
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto align-items-center">
              <div
                className={
                  page === "Home"
                    ? "navLink activeLink pointer"
                    : "navLink pointer"
                }
              >
                <Nav.Link
                  onClick={Home_page}
                  className={
                    page === "Home" ? "activeLink pointer" : "pointer"
                  }
                >
                  Home
                </Nav.Link>
              </div>

              <div
                className={
                  page === "Services"
                    ? "navLink activeLink pointer"
                    : "navLink pointer"
                }
              >
                <Nav.Link
                  className={
                    page === "Services" ? "activeLink pointer" : "pointer"
                  }
                  onClick={ServicePage}
                >
                  Services
                </Nav.Link>
              </div>
              <div
                className={
                  page === "AboutUs"
                    ? "navLink activeLink pointer"
                    : "navLink pointer"
                }
              >
                <Nav.Link
                  className={
                    page === "AboutUs" ? "activeLink pointer" : "pointer"
                  }
                  onClick={AboutUsPage}
                >
                  About Us
                </Nav.Link>
              </div>
              <div
                className={
                  page === "ContactUs"
                    ? "navLink activeLink pointer"
                    : "navLink pointer"
                }
              >
                <Nav.Link
                  className={
                    page === "ContactUs" ? "activeLink pointer" : "pointer"
                  }
                  onClick={ContactUsPage}
                >
                  Contact Us
                </Nav.Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
