import React from 'react'
import './PointsCalculatorBanner.css'
import AboutusBanner from '../../../Assets/Images/pointsCalcBanner.png'



import { Card, Col, Container, Row } from 'react-bootstrap'

export default function PointsCalculatorBanner() {
  return (
    <section className='servicesbanner'>
      
      <img src={AboutusBanner} className='img-fluid banner_img'/>
      
      <Container fluid className='banner_container'>
        <h1 className='calcPoint'>POINTS CALCULATOR </h1>
      </Container>
    </section>
  )
}
