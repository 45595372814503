import React, { useState, useEffect } from "react";
import { Container, Row, Card, Col, Image } from "react-bootstrap";
import vision from "../../../Assets/Images/vision.jpg";
import Mission from "../../../Assets/Images/Mission.jpg";
import objective from "../../../Assets/Images/objective.png";
import News1 from "../../../Assets/Images/lifeInAustralia.png";
import News2 from "../../../Assets/Images/au2.png";
import News3 from "../../../Assets/Images/blogsInner.png";
import { useHistory } from "react-router-dom";
import "./BlogsMessage.css";
import axios from "axios";

const apiKey = "623686037cc046d9a78384bafee97a78";

const BlogsMessage = () => {
  let history = useHistory();

  const [newsArticles, setNewsArticles] = useState([]);

  const BlogsDetailsPage = () => {
    history.push("/blogsdetails");
    window.scrollTo(0, 0);
  };

  const BlogsDetailsPageExpense = () => {
    history.push("/blogsdetailsexpense");
    window.scrollTo(0, 0);
  };

  const fetchNews = async () => {
    const response = await axios.get(
      `https://newsapi.org/v2/everything?q=visa+Australia&apiKey=${apiKey}`
    );
    setNewsArticles(response.data.articles);
  };

  
  useEffect(() => {
    fetchNews();
  }, []);
  return (
    <div className="bg-white">
      <Container className="pb-5 pt-5">
        {/* <Row>
        <div>
      {newsArticles.map((article, index) => (
        <div key={index}>
          <h2>{article.title}</h2>
          <p>{article.description}</p>
          <a href={article.url} target="_blank" rel="noopener noreferrer">Read More</a>
        </div>
      ))}
    </div>

        </Row> */}
        <Row className="responsivePadding">
          <Col className="col-lg-4">
            <Card className="news" onClick={BlogsDetailsPage}>
              <Image src={News1}></Image>
              <div className="innerCardBlogs">
                <span className="innerClipColor text-start px-1 pb-2 mt-3 d-flex">
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="far fa-comments innerClipColor pe-2" />{" "}
                      Comments
                    </small>
                  </small>
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="far fa-user innerClipColor pe-2" /> Admin
                    </small>
                  </small>
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="fa fa-calendar  innerClipColor pe-1" /> 12
                      Apr 2023
                    </small>
                  </small>
                </span>
                <span></span>
                <Card.Title className="bolder text-start px-3 py-3 pb-0">
                  <h4>Life in Australia</h4>
                </Card.Title>
                <Card.Text className="textColorDefault text-start px-3 pb-3">
                  <hr></hr>
                  <small className="normal pb-2">
                    Australia, known as the "Land Down Under," is a diverse and
                    fascinating country that attracts people from all over the
                    world. With its unique blend of cultures, beautiful
                    landscapes, and high quality of life.{" "}
                  </small>
                </Card.Text>
              </div>
            </Card>
          </Col>
          <Col className="col-lg-4">
            <Card className="news" onClick={BlogsDetailsPageExpense}>
              <Image src={News2}></Image>
              <div className="innerCardBlogs">
                <span className="innerClipColor text-start px-1 pb-2 mt-3 d-flex">
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="far fa-comments innerClipColor pe-2" />{" "}
                      Comments
                    </small>
                  </small>
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="far fa-user innerClipColor pe-2" /> Admin
                    </small>
                  </small>
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="fa fa-calendar  innerClipColor pe-1" /> 12
                      Apr 2023
                    </small>
                  </small>
                </span>
                <span></span>
                <Card.Title className="bolder text-start px-3 py-3 pb-0">
                  <h4>Living Expanses in Australia</h4>
                </Card.Title>
                <Card.Text className="textColorDefault text-start px-3 pb-3">
                  <hr></hr>
                  <small className="normal pb-2">
                    Australia is a diverse and vibrant country that offers a
                    wide range of experiences for its residents. However, living
                    expenses can vary significantly depending on the state you
                    choose to call home.
                  </small>
                </Card.Text>
              </div>
            </Card>
          </Col>
          {/* <Col className="col-lg-4">
            <Card className="news">
              <Image src={News1}></Image>
              <div className="innerCardBlogs">
                <span className="innerClipColor text-start px-1 pb-2 mt-3 d-flex">
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="far fa-comments innerClipColor pe-2" />{" "}
                      Comments
                    </small>
                  </small>
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="far fa-user innerClipColor pe-2" /> Admin
                    </small>
                  </small>
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="fa fa-calendar  innerClipColor pe-1" /> 12
                      Apr 2023
                    </small>
                  </small>
                </span>
                <span></span>
                <Card.Title className="bolder text-start px-3 py-3 pb-0">
                  <h4>Why Indian Students Choose To Study Abroad?</h4>
                </Card.Title>
                <Card.Text className="textColorDefault text-start px-3 pb-3">
                  <hr></hr>
                  <small className="normal pb-2">
                    Many young Indian students today wish to pursue their
                    studies in universities abroad.
                  </small>
                </Card.Text>
              </div>
            </Card>
          </Col> */}
        </Row>

        {/* <Row>
          <Col className="col-lg-4">
            <Card className="news" onClick={BlogsDetailsPage}>
              <Image src={News1}></Image>
              <div className="innerCardBlogs">
                <span className="innerClipColor text-start px-1 pb-2 mt-3 d-flex">
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="far fa-comments innerClipColor pe-2" />{" "}
                      Comments
                    </small>
                  </small>
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="far fa-user innerClipColor pe-2" /> Admin
                    </small>
                  </small>
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="fa fa-calendar  innerClipColor pe-1" /> 12
                      Apr 2023
                    </small>
                  </small>
                </span>
                <span></span>
                <Card.Title className="bolder text-start px-3 py-3 pb-0">
                  <h4>Why Indian Students Choose To Study Abroad?</h4>
                </Card.Title>
                <Card.Text className="textColorDefault text-start px-3 pb-3">
                  <hr></hr>
                  <small className="normal pb-2">
                    Many young Indian students today wish to pursue their
                    studies in universities abroad.
                  </small>
                </Card.Text>
              </div>
            </Card>
          </Col>
          <Col className="col-lg-4">
            <Card className="news" onClick={BlogsDetailsPage}>
              <Image src={News1}></Image>
              <div className="innerCardBlogs">
                <span className="innerClipColor text-start px-1 pb-2 mt-3 d-flex">
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="far fa-comments innerClipColor pe-2" />{" "}
                      Comments
                    </small>
                  </small>
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="far fa-user innerClipColor pe-2" /> Admin
                    </small>
                  </small>
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="fa fa-calendar  innerClipColor pe-1" /> 12
                      Apr 2023
                    </small>
                  </small>
                </span>
                <span></span>
                <Card.Title className="bolder text-start px-3 py-3 pb-0">
                  <h4>Why Indian Students Choose To Study Abroad?</h4>
                </Card.Title>
                <Card.Text className="textColorDefault text-start px-3 pb-3">
                  <hr></hr>
                  <small className="normal pb-2">
                    Many young Indian students today wish to pursue their
                    studies in universities abroad.
                  </small>
                </Card.Text>
              </div>
            </Card>
          </Col>
          <Col className="col-lg-4">
            <Card className="news" onClick={BlogsDetailsPage}>
              <Image src={News1}></Image>
              <div className="innerCardBlogs">
                <span className="innerClipColor text-start px-1 pb-2 mt-3 d-flex">
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="far fa-comments innerClipColor pe-2" />{" "}
                      Comments
                    </small>
                  </small>
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="far fa-user innerClipColor pe-2" /> Admin
                    </small>
                  </small>
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="fa fa-calendar  innerClipColor pe-1" /> 12
                      Apr 2023
                    </small>
                  </small>
                </span>
                <span></span>
                <Card.Title className="bolder text-start px-3 py-3 pb-0">
                  <h4>Why Indian Students Choose To Study Abroad?</h4>
                </Card.Title>
                <Card.Text className="textColorDefault text-start px-3 pb-3">
                  <hr></hr>
                  <small className="normal pb-2">
                    Many young Indian students today wish to pursue their
                    studies in universities abroad.
                  </small>
                </Card.Text>
              </div>
            </Card>
          </Col>
        </Row> */}

        {/* <Row>
          <Col className="col-lg-4">
            <Card className="news" onClick={BlogsDetailsPage}>
              <Image src={News1}></Image>
              <div className="innerCardBlogs">
                <span className="innerClipColor text-start px-1 pb-2 mt-3 d-flex">
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="far fa-comments innerClipColor pe-2" />{" "}
                      Comments
                    </small>
                  </small>
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="far fa-user innerClipColor pe-2" /> Admin
                    </small>
                  </small>
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="fa fa-calendar  innerClipColor pe-1" /> 12
                      Apr 2023
                    </small>
                  </small>
                </span>
                <span></span>
                <Card.Title className="bolder text-start px-3 py-3 pb-0">
                  <h4>Why Indian Students Choose To Study Abroad?</h4>
                </Card.Title>
                <Card.Text className="textColorDefault text-start px-3 pb-3">
                  <hr></hr>
                  <small className="normal pb-2">
                    Many young Indian students today wish to pursue their
                    studies in universities abroad.
                  </small>
                </Card.Text>
              </div>
            </Card>
          </Col>
          <Col className="col-lg-4">
            <Card className="news" onClick={BlogsDetailsPage}>
              <Image src={News1}></Image>
              <div className="innerCardBlogs">
                <span className="innerClipColor text-start px-1 pb-2 mt-3 d-flex">
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="far fa-comments innerClipColor pe-2" />{" "}
                      Comments
                    </small>
                  </small>
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="far fa-user innerClipColor pe-2" /> Admin
                    </small>
                  </small>
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="fa fa-calendar  innerClipColor pe-1" /> 12
                      Apr 2023
                    </small>
                  </small>
                </span>
                <span></span>
                <Card.Title className="bolder text-start px-3 py-3 pb-0">
                  <h4>Why Indian Students Choose To Study Abroad?</h4>
                </Card.Title>
                <Card.Text className="textColorDefault text-start px-3 pb-3">
                  <hr></hr>
                  <small className="normal pb-2">
                    Many young Indian students today wish to pursue their
                    studies in universities abroad.
                  </small>
                </Card.Text>
              </div>
            </Card>
          </Col>
          <Col className="col-lg-4">
            <Card className="news" onClick={BlogsDetailsPage}>
              <Image src={News1}></Image>
              <div className="innerCardBlogs">
                <span className="innerClipColor text-start px-1 pb-2 mt-3 d-flex">
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="far fa-comments innerClipColor pe-2" />{" "}
                      Comments
                    </small>
                  </small>
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="far fa-user innerClipColor pe-2" /> Admin
                    </small>
                  </small>
                  <small className="px-1">
                    <small className="  ps-2 innerClipColor">
                      <i className="fa fa-calendar  innerClipColor pe-1" /> 12
                      Apr 2023
                    </small>
                  </small>
                </span>
                <span></span>
                <Card.Title className="bolder text-start px-3 py-3 pb-0">
                  <h4>Why Indian Students Choose To Study Abroad?</h4>
                </Card.Title>
                <Card.Text className="textColorDefault text-start px-3 pb-3">
                  <hr></hr>
                  <small className="normal pb-2">
                    Many young Indian students today wish to pursue their
                    studies in universities abroad.
                  </small>
                </Card.Text>
              </div>
            </Card>
          </Col>
        </Row> */}
      </Container>
    </div>
  );
};

export default BlogsMessage;
