import React from 'react'
import './LifeInAustraliaBanner.css'
import ServicesBanner from '../../../Assets/Images/insuranceBanner.jpg'



import { Card, Col, Container, Row } from 'react-bootstrap'

export default function LifeInAustraliaBanner() {
  return (
    <section className='servicesbanner'>
      
      <img src={ServicesBanner} className='img-fluid banner_img'/>
      
      <Container fluid className='banner_container'>
        <h1>LIFE IN AUSTRALIA</h1>
        {/* <span className='text-white bolder'>SERVICES ·  Life in Australia</span> */}
      </Container>
    </section>
  )
}
